/**
 * 模块名称: 还款申请-批量还款
 * @author lids@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from '@/api'
import { Link } from 'react-router-dom'
import {
	Descriptions,
  Spin,
  Table,
  InputNumber,
  message,
  Tag,
  Tooltip,
  Icon
} from 'antd'
import { parseSearch } from '@/utils'
import BtnGroup from '@/components/BtnGroup'

const dItem = Descriptions.item
const { Column } = Table
const { CheckableTag } = Tag
let orderType = ''
let ordersJson = []

const Payback = (props) => {

  const { history, location } = props

  const [base, setBase] = useState({})//基础信息
  const [client, setClient] = useState('')//客户信息
  const [available, setAvailable] = useState('')//账户信息
  const [orderInfo, setOrderInfo] = useState({})//订单信息
  const [contractInfo, setContractInfo] = useState('')//账户信息
  const [row, setRow] = useState({})
	const [attachdata, setAttachdata] = useState([])//订单扩展资料字段信息
	const [orderExInfo, setOrderExInfo] = useState({})//订单资料
	const [colInfo, setColInfo] = useState({})//收款信息
	const [vendorInfo, setVendorInfo] = useState({})//厂商ID信息
	const [discountInfo, setDiscountInfo] = useState({})//折扣信息
  const [pageIds, setPageIds] = useState('')
  const [loading, setLoading] = useState(true)
  const [colactualprice, setColactualprice] = useState('')//实收
  const [colmarketprice, setColmarketprice] = useState('')//还款市场价
  const [colprofit, setColprofit] = useState('')//个人利润
  const [colcost, setColcost] = useState('')//成本
  const [colrefund, setColrefund] = useState('')//调整项
  const [integral, setIntegral] = useState(0)//积分
  const [projectIntegral, setProjectIntegral] = useState(0)//积分（以项目为维度从接口中获得）
  const [orderNumber, setOrderNumber] = useState([])
  const [currentId, setCurrentId] = useState(null)
  const [colmarketpriceTotal, setColmarketpriceTotal] = useState(0)//还款市场价总额
  const [colpredictpriceTotal, setColpredictpriceTotal] = useState(0)//预计收款总额
	const [performance, setPerformance] = useState([])//分成人信息
  const [ifOldOrder, setIfOldOrder] = useState(false)
  const [visitedIds, setVisitedIds] = useState([])//记录查询过的id，如果查询过一次，就不要再set了

	useEffect(() => {
    //判断是直销还是代理商页面跳转
    if (location.pathname && location.pathname.indexOf('/orderAgent') > -1) {
      orderType = 1
    } else {
      orderType = 0
    }
    ordersJson = []
		if(location.search){
      // console.log(parseSearch(location.search))
      if(!parseSearch(location.search).ids && !parseSearch(location.search).vendor){
        history.goBack()
        return
      }
      setPageIds(parseSearch(location.search).ids)
      onFortuneOrderBeforePayBackApplie(parseSearch(location.search).ids, parseSearch(location.search).vendor)
		}else{
      history.goBack()
		}
	}, [])
	//获取详情
	const onFortuneOrderBeforePayBackApplie = (ids, vendor) => {
    let params = {ids: ids, type: orderType, vendor}
    if(parseSearch(location.search).show)params.show = parseSearch(location.search).show
		api.onFortuneOrderBeforePayBackApplie(params).then(res => {
			if(JSON.stringify(res) !== "[]"){
        res.available && setAvailable(res.available)
        res.integral && setProjectIntegral(res.integral)

        if(res.order.length){//多个订单
          const n = res.order.reduce((prev, curr) => {
            return [...prev, +curr.colmarketprice]
          }, [])
          setColmarketpriceTotal(n.reduce((prev, curr) => prev + curr))
          const m = res.order.reduce((prev, curr) => {
            return [...prev, +curr.colpredictprice]
          }, [])
          setColpredictpriceTotal(m.reduce((prev, curr) => prev + curr))
          let on = [...res.order].map(item => ({...item, checked: false, taped: false}))
          ordersJson = [...res.order].map(item => {
            return {
              id: item.id, 
              orderNumber: item.number, 
              colactualprice: item.colactualprice, 
              colcost: item.colcost, 
              colprofit: item.colprofit, 
              colrefund: item.colrefund, 
              integral: item.integral
            }
          })
          on[0].checked = true
          on[0].taped = true
          setOrderNumber(on)//默认请求第0个
          setCurrentId(on[0].id)
          getOrderDetail(on[0].id, vendor)
        }
			}
			setLoading(false)
		}).catch(() => setLoading(false))
  }
  //处理单个订单数据
  const dealItemData = (data, id) => {
    if(data.row){
      const row = data.row
      setRow(row)
      data.vendor && setVendorInfo(data.vendor)
      if(!visitedIds.includes(id)){
        // console.log(+row.colcost)
        visitedIds.push(id)
        setVisitedIds(visitedIds)
        setColrefund(+row.colrefund)
        setColcost(+row.colcost)
        setColprofit(+row.colprofit)
      }
      if((+row.colcost == 0 && +row.colrefund == 0 && +row.colprofit == 0) || (row.colcost == '' && row.colrefund == '' && row.colprofit == '')){
        setIfOldOrder(true)
      }else{
        setIfOldOrder(false)
      }
    }
    if(data.colInfo){
      setColmarketprice(+data.colInfo.colmarketprice)
      setColactualprice(+data.colInfo.colpredictprice)
    }
    data.base && setBase(data.base)
    data.orderInfo && setOrderInfo(data.orderInfo)
    data.client && setClient(data.client)
    data.contractInfo && setContractInfo(data.contractInfo)
    data.performance && setPerformance(data.performance)
    if(data.orderExInfo){
      setOrderExInfo(data.orderExInfo)
      if(data.orderExInfo.attachdata && data.orderExInfo.attachdata.length){
        data.orderExInfo.attachdata.forEach((item, index, self) => {
          self[index] = {
            name: Object.keys(item)[0],
            value: Object.values(item)[0]
          }
        })
        setAttachdata(data.orderExInfo.attachdata)
      }
    }
    data.colInfo && setColInfo(data.colInfo)
    data.discountInfo && setDiscountInfo(data.discountInfo)
  }
  //获取单个订单详情
  const getOrderDetail = (id, vendor) => {
    let params = {ids: id, type: orderType, vendor}
    setLoading(true)
    api.onFortuneOrderBeforePayBackApplie(params).then(res => {
      if(res.order)dealItemData(Object.assign({}, res.order), id)
			setLoading(false)
    }).catch(() => setLoading(false))
  }
  //还款
  const submitPayBack = () => {
    checkValidate(() => {
      ordersJson = ordersJson.map(item => {
        if(item.id == currentId){
          item.colactualprice = colactualprice
          item.colrefund = colrefund
          item.colcost = colcost
          item.colprofit = colprofit
          item.integral = orderType == 0 ? 0 : integral
          return item
        }
        return item
      })
      let errNumbers = {}, errArr = []
      ordersJson.forEach(item => {
        errNumbers[item.orderNumber] = true
        if(item.colprofit === '' || item.colcost === '' || item.colrefund === ''){
          errNumbers[item.orderNumber] = false
        }
      })
      let oArr = JSON.parse(JSON.stringify(ordersJson))
      for(let i = 0, len = oArr.length; i < len; i++){
        delete oArr[i].orderNumber
      }

      let params = {
        ids: pageIds,
        colmarketprice: colmarketpriceTotal,
        isAllIn: 2,
        batchOrderData: JSON.stringify(oArr)
      }
      let tapedArr = []
      orderNumber.forEach(item => {
        if(!item.taped){
          tapedArr.push(item.id)
        }
      })
      if(tapedArr.length) return message.warning('您有未查看的订单')
      const num = ordersJson.reduce((prev, curr) => {
        return [...prev, +curr.colactualprice]
      }, [])
      params.colactualprice = num.reduce((prev, curr) => prev + curr)
      if(params.colactualprice !== colpredictpriceTotal){
        return message.warning(`实收必须与预计收款总和相等，当前预计收款总和为${colpredictpriceTotal}元 实收总和为${params.colactualprice}元`)
      }
      for(let [key, value] of Object.entries(errNumbers)){
        if(!value)errArr.push(key)
      }
      // console.log(params, errArr, oArr, ordersJson)
      !errArr.length ? api.onPayBackApplie(params).then(() => {
        message.success('提交成功')
        history.goBack()
      }) : message.warning(`抱歉，需将所有还款订单流水填写完整后才能提交！`)
    })
  }
  
  //切换时校验
  const checkOrder = (foo) => {
    checkValidate(() => foo && foo())
  }
  const getCheckedId = (data) => {
    if(data.id == currentId)return//点击为当前订单，return
    checkOrder(() => {
      ordersJson.forEach(item => {
        if(item.id == data.id){
          // console.log(item.colcost)
          setColactualprice(item.colactualprice)
          setColrefund(item.colrefund)
          setColcost(item.colcost)
          setColprofit(item.colprofit)
          setIntegral(item.integral)
        }
      })
      ordersJson = ordersJson.map(item => {
        if(item.id == currentId){
          item.colactualprice = colactualprice
          item.colrefund = colrefund
          item.colcost = colcost
          item.colprofit = colprofit
          item.integral = integral
          return item
        }
        return item
      })
      setCurrentId(data.id)
      let d = [...orderNumber]
      for(let i = 0; i<d.length; i++){
        d[i].checked = false
        if(d[i].id == data.id){
          d[i].checked = true
          d[i].taped = true
        }
      }
      setOrderNumber(d)
      getOrderDetail(data.id, vendorInfo.vendor)
    })
  }
  //前端填写校验
  const checkValidate = callback => {
    if(ifOldOrder){
      if(colactualprice === '')return message.warning('请填写实收金额')
      if(colcost === '' || colcost < 0) return message.warning('请输入正确的成本')
      if(colrefund === '' || colrefund < 0) return message.warning('请输入正确的调整项')
      if(colprofit === '') return message.warning('请输入正确的个人利润')
      if(colmarketprice > colInfo.colmarketprice)return message.warning('还款市场价不能大于原订单市场价' + colInfo.colmarketprice)
      
      if(orderType == 1){
        if(integral === '' || integral < 0 || integral === null) return message.warning('请输入正确的积分')
        if(integral > projectIntegral) return message.warning('积分不能超过可用积分总额')
        if((colprofit + colcost + +colrefund).toFixed(4) !== (+colactualprice + +integral).toFixed(4)){
          return message.warning('调整项 + 成本 + 个人利润 应等于 实收 + 积分')
        }
      }else{
        if((colprofit + colcost + +colrefund).toFixed(4) !== (+colactualprice).toFixed(4)){
          return message.warning('调整项 + 成本 + 个人利润 应等于 实收')
        }
      }
    }   
    callback()
  }
	//刷新余额
	const refreshAcount = () => {
		api.getOrderRefreshBalance({
			type: orderType,
      relationId: orderType == 0 ? row.clientId : row.agentId,
      companyId: contractInfo.accountId ? contractInfo.accountId : row.companyId
		}).then(data => {
			data.available && setAvailable(data.available)
		})
	}

	return (
		<Spin spinning={loading}>
			<div className="add-orderDirect-detail">
        <Descriptions title='还款申请-批量信用收款' layout="vertical" className="pro-detail-title" column={5} style={{borderBottom: "none"}}>
          <dItem label="客户名称">{vendorInfo.client}</dItem>
          <dItem label="OAID">{vendorInfo.clientId}</dItem>
          {base.orderType && !Array.isArray(base.orderType) && <dItem label="类型">{base.orderType.name}</dItem>}
          <dItem label="厂商ID">{vendorInfo.vendor}</dItem>
          {orderType == 0 && <dItem label="所属销售">{vendorInfo.relSalerName}</dItem>}
          {orderType == 0 && <dItem label="所属客服">{vendorInfo.relServicerName}</dItem>}
          {orderType == 1 && <dItem label="所属渠道开发">{vendorInfo.relAgentStaffName}</dItem>}
        </Descriptions>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">还款订单</div>
          <Descriptions layout="horizontal" className="pro-detail-title" column={3} style={{borderBottom: "none"}}>
            {
              orderNumber.map(item => 
              <dItem label="订单编号" key={item.id}><CheckableTag color="blue" checked={item.checked} onChange={() => getCheckedId(item)}>
                {!item.taped ? <Tooltip title="该订单还未查看"><Icon type="exclamation-circle" style={{color: '#ff4d4f', fontSize: 13}} fill="currentColor" /> {item.number}</Tooltip> : item.number}</CheckableTag>
              </dItem>)
            }
          </Descriptions>
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">还款</div>
            <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              <dItem label="可用余额">
                <div className="dItem-txt-box">
                ￥ {available && available.balance}
                <Link target="_blank" to={`/fund/moneyClaim/detail?clientId=${orderType == 0 ? vendorInfo.clientId : vendorInfo.agentId}&clientType=${orderType}`}> 认款</Link>
								<span onClick={refreshAcount} style={{color: '#1890ff', cursor: 'pointer', marginLeft: '5px'}}>刷新余额</span>
                </div>
              </dItem>
              {orderType == 1 && <dItem label="可用积分">
                <div className="dItem-txt-box">
                ￥ {projectIntegral && projectIntegral}
                <Link target="_blank" to={`/agent/integralManage/add/0?relationId=${vendorInfo.agentId}&bizType=0`}> 充积分</Link>
                </div>
              </dItem>}
              </Descriptions>
              <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              <dItem className="radio-in-label" label={<>还款市场价（总和：{colmarketpriceTotal.toFixed(2)}）</>}>
                <div>{colInfo.colmarketprice}</div>
              </dItem>
              <dItem
                label={<><span className="required-label">*</span> 实收</>}
              >
                <InputNumber
                  disabled={!ifOldOrder}
                  step={0.01}
                  min={0}
                  style={{width: '210px'}}
                  placeholder={'请输入'}
                  value={colactualprice}
                  onChange={val => {
                    setColactualprice(val)
                    orderType == 1 && setIntegral(colInfo.colpredictprice - val)
                  }}
								/>
                {ifOldOrder && <div>正确的格式为：10.00</div>}
              </dItem>
              {orderType == 1 && <dItem label={<><span className="required-label">*</span> 积分</>}>
                <InputNumber
                  disabled={!ifOldOrder}
                  step={0.01}
                  min={0}
                  style={{width: '210px'}}
                  placeholder="请输入"
                  value={integral}
                  onChange={val => {
                    setIntegral(val)
                    setColactualprice(colInfo.colpredictprice - val)
                  }}
								/>
                {ifOldOrder && <div>正确的格式为：10.00</div>}
              </dItem>}
              {colactualprice && colInfo.colmarketprice && <dItem label="折扣">{(colactualprice / +colInfo.colmarketprice).toFixed(4)}</dItem>}
              </Descriptions>
              <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              <dItem label={<><span className="required-label">*</span> 成本</>}>
                <InputNumber
                  disabled={!ifOldOrder}
                  step={0.01}
                  // min={0}
                  style={{width: '210px'}}
                  placeholder="请输入"
                  value={colcost}
                  onChange={val => setColcost(val)}
								/>
                {ifOldOrder && <div>正确的格式为：10.00</div>}
              </dItem>
              <dItem label={<><span className="required-label">*</span> 调整项</>}>
                <InputNumber
                  disabled={!ifOldOrder}
                  step={0.01}
                  min={0}
                  style={{ width: '210px' }}
                  placeholder="请输入"
                  value={colrefund}
                  onChange={val => setColrefund(val)}
                />
                {ifOldOrder && <div>正确的格式为：10.00</div>}
							</dItem>
              <dItem label={<><span className="required-label">*</span> 个人利润</>}>
                <InputNumber
                  disabled={!ifOldOrder}
                  step={0.01}
                  // min={0}
                  style={{width: '210px'}}
                  placeholder="请输入"
                  value={colprofit}
                  onChange={val => setColprofit(val)}
								/>
                {ifOldOrder && <div>正确的格式为：10.00</div>}
              </dItem>
            </Descriptions>
            <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              <dItem label="提单人">{colInfo.userName}</dItem>
              <dItem label="直接主管">{colInfo.userLeader}</dItem>
              <dItem label="部门">{colInfo.userDepart}</dItem>
            </Descriptions>
            {performance.length > 0 && <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none", marginBottom: "-30px"}}>
              <dItem label="分成人"></dItem>
              <dItem label="分成人业绩"></dItem>
              <dItem label="分成人直接主管"></dItem>
              <dItem label="分成人部门"></dItem>
            </Descriptions>}
            {performance.map((item, index) => (<Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{borderBottom: "none", marginBottom: "-10px"}}>
              <dItem label="">{item.stfName}</dItem>
              <dItem label="">{item.performance}</dItem>
              <dItem label="">{item.superior}</dItem>
              <dItem label="">{item.department}</dItem>
            </Descriptions>))}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单收款信息-{base.coltype}</div>
					{colInfo ? <><Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="市场价">{colInfo.colmarketprice}</dItem>
						<dItem label="预计收款">{colInfo.colpredictprice}</dItem>
						<dItem label="折扣">{colInfo.colrebate}</dItem>
						<dItem label="个人利润">{colInfo.colprofit}</dItem>
						<dItem label="成本">{colInfo.colcost}</dItem>
						<dItem label="调整项">{colInfo.colrefund}</dItem>
          </Descriptions>
          <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="提单人">{colInfo.userName}</dItem>
						<dItem label="直接主管">{colInfo.userLeader}</dItem>
						<dItem label="部门">{colInfo.userDepart}</dItem>
					</Descriptions></> : '-'}
          {performance.length > 0 && <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none", marginBottom: "-30px"}}>
            <dItem label="分成人"></dItem>
            <dItem label="分成人业绩"></dItem>
            <dItem label="分成人直接主管"></dItem>
            <dItem label="分成人部门"></dItem>
          </Descriptions>}
          {performance.map((item, index) => (<Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{borderBottom: "none", marginBottom: "-10px"}}>
            <dItem label="">{item.stfName}</dItem>
            <dItem label="">{item.performance}</dItem>
            <dItem label="">{item.superior}</dItem>
            <dItem label="">{item.department}</dItem>
          </Descriptions>))}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单信息</div>
					{orderInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="项目">{orderInfo.projectName}</dItem>
						<dItem label="产品">{orderInfo.productName}</dItem>
						<dItem label="业务类型">{orderInfo.businessName}</dItem>
						<dItem label="业绩类型">{orderInfo.figtype}</dItem>
						{orderType == 0 && <dItem label="是否退转订单">{+row.isodftrun ? '是' : '否'}</dItem>}
						{orderType == 0 && <dItem label="是否框架订单">{row.isodfkjia ? '是' : '否'}</dItem>}
						{orderType == 0 && <dItem label="销售主项">{row.majorminor == 1 ? '是' : '否'}</dItem>}
					</Descriptions> : '-'}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单资料</div>
					{orderExInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="公司名称">{orderExInfo.companyName}</dItem>
						<dItem label="一级行业">{orderExInfo.industry1Name}</dItem>
						<dItem label="二级行业">{orderExInfo.industry2Name}</dItem>
						<dItem label="供应商">{orderExInfo.supplierName}</dItem>
            {
              attachdata.map(((item, index) => <dItem label={item.name} key={+index+4}>{item.value}</dItem>))
            }
					</Descriptions> : '-'}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">折扣信息</div>
					{discountInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="客户名称">
              <div>{discountInfo.clientName}</div>
              <div>ID: {client.id}</div>
            </dItem>
            <dItem label="项目">{discountInfo.projectName}</dItem>
            <dItem label="产品">{discountInfo.productName}</dItem>
            <dItem label="一级行业">{discountInfo.industry1}</dItem>
            <dItem label="二级行业">{discountInfo.industry2}</dItem>
            <dItem label="折扣">{discountInfo.colrebate}</dItem>
            <dItem label="供应商">
              <div>{discountInfo.supplier}</div>
              <div>ID: {orderExInfo.supplier}</div>
            </dItem>
            <dItem label="折扣类型">{discountInfo.discountType}</dItem>
					</Descriptions> : '-'}
        </div>
				<div className="blank-line"></div>
				<div className="ask-info" style={{marginBottom: 0, marginTop: '10px'}}>
					<BtnGroup onCancel={() => {history.go(-1)}} onConfirm={submitPayBack} confirmName = '提交' right="to-right" loading={false} />
				</div>
			</div>
		</Spin>
	)
}

export default Payback