/**
 * 模块名称: 退单申请
 * @author lids@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
	Descriptions,
  Spin,
  InputNumber,
  message,
  Tag,
  Icon,
  Tooltip,
  Input
} from 'antd'
import BtnGroup from '@/components/BtnGroup'
import { toNumberPoint } from '@/utils'

const dItem = Descriptions.item
const { CheckableTag } = Tag
let ordersJson = []
let orderType = ''

const Refund = (props) => {
  const { history, location } = props
  const [row, setRow] = useState({})
  const [base, setBase] = useState({})//基础信息
  const [orderNumber, setOrderNumber] = useState([])
	const [orderInfo, setOrderInfo] = useState({})//订单信息
	const [attachdata, setAttachdata] = useState([])//订单扩展资料字段信息
	const [orderExInfo, setOrderExInfo] = useState({})//订单资料
	const [colInfo, setColInfo] = useState({})//收款信息
	const [performance, setPerformance] = useState([])//分成人信息
  const [loading, setLoading] = useState(true)
  const [colprofit, setColprofit] = useState('')//个人利润
  const [integral, setIntegral] = useState('')//积分
  const [colcost, setColcost] = useState('')//成本
  const [colrefund, setColrefund] = useState('')//调整项
  const [remark, setRemark] = useState('') // 备注
  const [vendor, setVendor] = useState(null)
  const [currentId, setCurrenId] = useState(null)
  const [systemRefund, setSystemRefund] = useState('')
  const [clientRefund, setClientRefund] = useState('')
  const [firstVisitPage, setFirstVisitPage] = useState(true)
  const [chargebackMarketPrice, setChargebackMarketPrice] = useState('')
  const [visitedIds, setVisitedIds] = useState([])//记录查询过的id，如果查询过一次，就不要再set了
  const [ifOldOrder, setIfOldOrder] = useState(false)//判断是否是之前老的订单，老的订单调整项、成本、个人利润都是0（是因为没填写）
  const [saveLoading, setSaveLoading] = useState(false)

	useEffect(() => {
    //判断是直销还是代理商页面跳转
    if (location.pathname && location.pathname.indexOf('/orderAgent') > -1) {
      orderType = 1
    } else {
      orderType = 0
    }
    ordersJson = []
    if(location.state){
      if(location.state.vendor){
        setVendor(location.state.vendor)
      }else{
        history.goBack()
      }
      if(location.state.ids){
        getBackList()
        getSingleDetail(location.state.ids.split(',')[0])
      }else{
        history.goBack()
      }
    }
	}, [])
	//获取详情（实际是获取了订单列表，然后再根据每个订单列表查询单个订单详情）
	const getBackList = () => {
    const tabInfo = location.state.backList.map(item => ({...item, checked: false, taped: false}))
    const json = {
      colprofit: '', 
      colcost: '', 
      colrefund: '', 
      clientRefund: '', 
      systemRefund: '',
      chargebackMarketPrice: '',
      colpredictprice: '',
      colmarketprice: ''
    }
    ordersJson = location.state.backList.map(item => {
      return orderType == 0 ? {
        order_id: item.id, 
        orderNumber: item.number, 
        ...json
      } : {
        order_id: item.id, 
        orderNumber: item.number, 
        integral: item.colstatus === "未收款" ? 0 : '',
        ...json
      }
    })
    tabInfo[0].checked = true
    tabInfo[0].taped = true
    setOrderNumber(tabInfo)//默认请求第0个
    setCurrenId(tabInfo[0].id)
  }
  //获取单个订单的详情
  const getSingleDetail = (id, cb) => {
    const params = { id, type: orderType, audit: 0}
		setLoading(true)
    api.getFortuneOrderRefundOrderDetail(params, true).then(res => {
      if(JSON.stringify(res) !== "[]"){
        const { base, row, colInfo, orderInfo, orderExInfo, performance } = res
        if(row){
          const stRefund = JSON.parse(location.state.systemRefund)
          const cbPrice = location.state.chargebackMarketPrice
          const { 
            colactualprice, 
            colrefund, 
            colcost, 
            colprofit, 
            integral, 
            colpredictprice, 
            colmarketprice, 
            colstatus,
          } = row
          const ratio = ((+Object.values(stRefund[0])[0]) / colmarketprice)

          //colstatus 1表示收款 2表示未收款
          if(row.colstatus == 1){
            if(!visitedIds.includes(id) && ordersJson.length == 1){
              setVisitedIds(visitedIds.push(id))
              setClientRefund((colactualprice * ratio).toFixed(2))
              setColrefund((colrefund * ratio).toFixed(2))
              setColcost((colcost * ratio).toFixed(2))
              setColprofit((colprofit * ratio).toFixed(2))
              orderType == 1 && setIntegral((integral * ratio).toFixed(0))
            }
            //判断老数据
            // if(row.colrefund == 0 && row.colcost == 0 && row.colprofit == 0){
            //   setIfOldOrder(true)
            // }else{
            //   setIfOldOrder(false)
            // }
          }
          if(colInfo){
            ordersJson.forEach((item, index) => {
              if(item.order_id == id){
                item.colpredictprice = colpredictprice
                item.colmarketprice = colmarketprice
                item.colstatus = colstatus
                item.systemRefund = +Object.values(stRefund[index])[0]
                item.chargebackMarketPrice = +cbPrice[index]
                if(base.coltypeId == 2){//2表示预计收款
                  item.clientRefund = (colpredictprice * (+Object.values(stRefund[index])[0] / colmarketprice)).toFixed(2)
                }
                if (item.colstatus == 2) {
                  item.colcost = (colcost * (+Object.values(stRefund[index])[0] / colmarketprice)).toFixed(2)
                  item.colrefund = (colrefund * (+Object.values(stRefund[index])[0] / colmarketprice)).toFixed(2)
                  item.colprofit = ((+item.clientRefund - +item.colcost).toFixed(2) - +item.colrefund).toFixed(2)
                }
              }
            })
            
            if(firstVisitPage){
              setFirstVisitPage(false)
              // ordersJson.length == 1 || ordersJson[0].colstatus == 2
              if(ordersJson[0].colstatus == 2) {
                setClientRefund((+ordersJson[0].colpredictprice * (+Object.values(stRefund[0])[0] / +ordersJson[0].colmarketprice)).toFixed(2))
              }
              setSystemRefund(+Object.values(stRefund[0])[0]) 
              setChargebackMarketPrice(cbPrice[0])
            }
          }
        }

        if(orderExInfo){
          const { attachdata } = orderExInfo
          if(attachdata && attachdata.length){
            attachdata.forEach((item, index, self) => {
              self[index] = {
                name: Object.keys(item)[0],
                value: Object.values(item)[0]
              }
            })
            setAttachdata(attachdata)
          }
        }
        
        row && setRow(row)
        base && setBase(base)
        orderInfo && setOrderInfo(orderInfo)
        orderExInfo && setOrderExInfo(orderExInfo)
        colInfo && setColInfo(colInfo)
        performance && setPerformance(performance)
      } else {
        setBase({})
        setOrderInfo({})
        setOrderExInfo({})
        setColInfo({})
        setPerformance([])
      }
      cb && cb()
			setLoading(false)
    }).catch(() => setLoading(false))
  }
  
  //退单
  const submitPayBack = () => {
    checkValidate(() => {
      ordersJson.forEach(item => {
        if(item.order_id == currentId && item.colstatus == 1){
          item.colprofit = colprofit
          if(orderType == 1) item.integral = integral
          item.colcost = colcost
          item.colrefund = colrefund
          item.clientRefund = clientRefund
          item.systemRefund = systemRefund
          item.chargebackMarketPrice = chargebackMarketPrice
        }
      })
      let errNumbers = {}, errArr = []
      if(row.colstatus == 1){
        ordersJson.forEach(item => {
          errNumbers[item.orderNumber] = true
          if(item.colstatus == 1 && (item.colprofit === '' || item.colcost === '' || item.colrefund === '')){
            errNumbers[item.orderNumber] = false
          }
          if(item.integral === '' && orderType == 1){
            errNumbers[item.orderNumber] = false
          }
        })
      }
      let oArr = JSON.parse(JSON.stringify(ordersJson))
      for(let i = 0, len = oArr.length; i < len; i++){
        delete oArr[i].orderNumber
        delete oArr[i].colpredictprice
        delete oArr[i].colmarketprice
        delete oArr[i].colstatus
        for(let item in oArr[i]){
          if(oArr[i][item] == ''){
            oArr[i][item] = 0
          }
        }
      }
      let tapedArr = []
      orderNumber.forEach(item => {
        if(!item.taped){
          tapedArr.push(item.id)
        }
      })
      if(tapedArr.length) return message.warning('您有未查看的订单')

      const params = {
        vendor: vendor,
        orders: JSON.stringify(oArr),
        type: orderType,
        remark
      }
      for(let [key, value] of Object.entries(errNumbers)){
        if(!value)errArr.push(key)
      }

      if (!errArr.length) {
        setSaveLoading(true)
        api.getFortuneOrderRefundApplie(params).then(() => {
          message.success('提交成功')
          history.goBack()
          setSaveLoading(false)
        }).catch(() => setSaveLoading(false))
      } else {
        message.warning(`订单号${errArr.join(',')} 信息填写有误，请检查`)
      }
      // !errArr.length? api.getFortuneOrderRefundApplie(params).then(() => {
      //   message.success('提交成功')
      //   history.goBack()
      // }) : message.warning(`订单号${errArr.join(',')} 信息填写有误，请检查`)
    })
  }

  const onChangeOrder = (data) => {
    if(data.id == currentId)return//点击为当前订单，return
    checkValidate(() => {
      // 切换前记录收款订单表单值
      ordersJson.forEach(item => {
        if (item.order_id == currentId && item.colstatus == 1) { 
          item.colprofit = colprofit
          orderType == 1 && (item.integral = integral)
          item.colcost = colcost
          item.colrefund = colrefund
          item.clientRefund = clientRefund
          item.systemRefund = systemRefund
          item.chargebackMarketPrice = chargebackMarketPrice
        }
      })

      getSingleDetail(data.id, () => {
        // 切换后填写订单表单值
        ordersJson.forEach(item => {
          if(item.order_id == data.id){ 
            setClientRefund(item.clientRefund)
            setColprofit(item.colprofit)
            orderType == 1 && setIntegral(item.integral)
            setColcost(item.colcost)
            setColrefund(item.colrefund)
            setSystemRefund(item.systemRefund)
            setChargebackMarketPrice(item.chargebackMarketPrice)
          }
        })

        setCurrenId(data.id)
        let d = [...orderNumber]
        for(let i = 0; i<d.length; i++){
          d[i].checked = false
          if(d[i].id == data.id){
            d[i].checked = true
            d[i].taped = true
          }
        }

        setOrderNumber(d)
      })
    })
  }

  //前端填写校验
  const checkValidate = callback => { 
    if(row.colstatus == 1){
      if(orderType == 1 && (integral === '' || integral < 0)) return message.warning('请输入正确的积分')
      if(colcost === '' || colcost < 0) return message.warning('请输入正确的成本')
      if(colrefund === '' || colrefund < 0) return message.warning('请输入正确的调整项')
      if(colprofit === '') return message.warning('请输入正确的退个人利润')
      let txt = row.colstatus == 1 ? '实退客户金额' : '实退预计收款'
      if(row.type == 0){
        if((+colprofit + +colcost + +colrefund).toFixed(2) !== (+clientRefund).toFixed(2))return message.warning('退个人利润 + 成本 + 调整项 应等于 ' + txt)
      }else{
        if((+colprofit + +colcost + +colrefund).toFixed(2) !== (+clientRefund + +integral).toFixed(2))return message.warning('退个人利润 + 成本 + 调整项 应等于 ' + txt + ' + 积分')
      }
    }
    callback()
  }

	return (
		<Spin spinning={loading}>
			<div className="add-orderDirect-detail">
        <Descriptions title={`退单申请-${base.coltype}`} layout="vertical" className="pro-detail-title" column={5} style={{borderBottom: "none"}}>
          {base.orderType && !Array.isArray(base.orderType) && <dItem label="类型">{base.orderType.name}</dItem>}
        </Descriptions>
        {orderNumber.length > 0 && <>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">退款订单</div>
          <Descriptions layout="horizontal" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
            {
              orderNumber.map(item => 
              <dItem label="退单编号" key={item.id}><CheckableTag color="blue" checked={item.checked} onChange={() => onChangeOrder(item)}>
                {!item.taped ? <Tooltip title="该订单还未查看"><Icon type="exclamation-circle" style={{color: '#ff4d4f', fontSize: 14}} fill="currentColor" /> {item.number}</Tooltip> : item.number}</CheckableTag>
              </dItem>)
            }
          </Descriptions>
        </div>
        </>}
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">退单信息</div>
            <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              <dItem label="系统实退金额">{(+systemRefund).toFixed(2)}</dItem>
              {base.coltypeId == 2 && <dItem label="实退预计收款">{clientRefund}</dItem>}
              {
                row.colstatus == 1 ? <dItem label={<><span className="required-label">*</span> 实退客户金额</>}>
                  <InputNumber 
                    step={0.01}
                    placeholder="请输入实退客户金额" 
                    style={{width: '171px'}}
                    value={clientRefund} 
                    onChange={val => {
                      setClientRefund(val)
                    }}
                  />
                </dItem> :
                <dItem label="实退信用额度">{(+systemRefund).toFixed(2)}</dItem>
              }           
              {orderType == 1 && row.colstatus == 1 && <dItem label={<><span className="required-label">*</span> 退积分</>}>
              <InputNumber
                // disabled={ifOldOrder == false}
                step={1}
                min={0}
                style={{width: '171px'}}
                placeholder="请输入"
                value={integral}
                onChange={val => {
                  const reg = /^\d+$/
                  if (val && !reg.test(val)) {
                    // message.error('积分请输入0或正整数')
                    setIntegral(0)
                  } else {
                    setIntegral(+val)
                  }
                }}
              />
              <div>正确的格式为：0或正整数</div>
            </dItem>}
              {/* <dItem label="折扣">{colInfo.colrebate}</dItem> */}
            <dItem label="折扣">{
              (orderType == 1 && row.colstatus == 1) ? toNumberPoint((+clientRefund + +integral) / systemRefund) : colInfo.colrebate }
            </dItem>
              </Descriptions>
              <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              {row.colstatus == 1 && <dItem label={<><span className="required-label">*</span> 退成本</>}>
                <InputNumber
                  // disabled={ifOldOrder == false}
                  step={0.01}
                  min={0}
                  style={{width: '171px'}}
                  placeholder="请输入"
                  value={colcost}
                  onChange={val => setColcost(val)}
								/>
                <div>正确的格式为：10.00</div>
              </dItem>}
              {row.colstatus == 1 && <dItem label={<><span className="required-label">*</span> 退调整项</>}>
                <InputNumber
                  // disabled={ifOldOrder == false}
                  step={0.01}
                  min={0}
                  style={{width: '171px'}}
                  placeholder="请输入"
                  value={colrefund}
                  onChange={val => setColrefund(val)}
								/>
                <div>正确的格式为：10.00</div>
              </dItem>}
              {row.colstatus == 1 && <dItem label={<><span className="required-label">*</span> 退个人利润</>}>
                <InputNumber
                  // disabled={ifOldOrder == false}
                  step={0.01}
                  // min={0}
                  style={{width: '171px'}}
                  placeholder="请输入"
                  value={colprofit}
                  onChange={val => setColprofit(val)}
								/>
                <div>正确的格式为：10.00</div>
              </dItem>}
            </Descriptions>
            <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              <dItem label="提单人">{colInfo.userName}</dItem>
              <dItem label="直接主管">{colInfo.userLeader}</dItem>
              <dItem label="部门">{colInfo.userDepart}</dItem>
            </Descriptions>
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单收款信息-{base.coltype}</div>
					{colInfo ? <>
            <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              <dItem label="市场价">{colInfo.colmarketprice}</dItem>
              <dItem label="实收">{colInfo.colactualprice}</dItem>
              <dItem label="预计收款">{colInfo.colpredictprice}</dItem>
              <dItem label="折扣">{colInfo.colrebate}</dItem>
            </Descriptions>
            <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              <dItem label="成本">{colInfo.colcost}</dItem>
              <dItem label="调整项">{colInfo.colrefund}</dItem>
              <dItem label="个人利润">{colInfo.colprofit}</dItem>
              {colInfo.integral && <dItem label="积分">{colInfo.integral}</dItem>}
            </Descriptions>
            <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              <dItem label="提单人">{colInfo.userName}</dItem>
              <dItem label="直接主管">{colInfo.userLeader}</dItem>
              <dItem label="部门">{colInfo.userDepart}</dItem>
            </Descriptions>
            {performance.length > 0 && <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none", marginBottom: "-30px"}}>
              <dItem label="分成人"></dItem>
              <dItem label="分成人业绩"></dItem>
              <dItem label="分成人直接主管"></dItem>
              <dItem label="分成人部门"></dItem>
            </Descriptions>}
            {performance.map((item, index) => (<Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{borderBottom: "none", marginBottom: "-10px"}}>
              <dItem label="">{item.stfName}</dItem>
              <dItem label="">{item.performance}</dItem>
              <dItem label="">{item.superior}</dItem>
              <dItem label="">{item.department}</dItem>
            </Descriptions>))}
          </> : '-'}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单信息</div>
					{orderInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="项目">{orderInfo.projectName}</dItem>
						<dItem label="产品">{orderInfo.productName}</dItem>
						<dItem label="业务类型">{orderInfo.businessName}</dItem>
						<dItem label="业绩类型">{orderInfo.figtype}</dItem>
						{/* {orderType == 1 && <dItem label="是否撤资重开订单">{row.isodfdivest ? '是' : '否'}</dItem>} */}
						{orderType == 0 && <dItem label="是否退转订单">{+row.isodftrun ? '是' : '否'}</dItem>}
						{orderType == 0 && <dItem label="是否框架订单">{row.isodfkjia ? '是' : '否'}</dItem>}
						{orderType == 0 && <dItem label="销售主项">{row.majorminor == 1 ? '是' : '否'}</dItem>}
					</Descriptions> : '-'}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单资料</div>
					{orderExInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="公司名称">{orderExInfo.companyName}</dItem>
						<dItem label="一级行业">{orderExInfo.industry1Name}</dItem>
						<dItem label="二级行业">{orderExInfo.industry2Name}</dItem>
						<dItem label="供应商">{orderExInfo.supplierName}</dItem>
            {
              attachdata.map(((item, index) => <dItem label={item.name} key={+index + 4}>{item.value}</dItem>))
            }
					</Descriptions> : '-'}
        </div>
				<div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">退单备注</div>
          <Input.TextArea 
            placeholder="请输入备注" 
            maxLength={3000} 
            value={remark} 
						style={{minWidth: '900px', minHeight: '5em'}}
            onChange={e => {
              setRemark(e.target.value)
          }} />
        </div>
				<div className="blank-line"></div>
        <div style={{height: 30, backgroundColor: 'white'}}></div> 
				<div className="ask-info submit-btn-box">
					<BtnGroup onCancel={() => {history.go(-1)}} onConfirm={submitPayBack} confirmName = '提交' right="to-right" loading={saveLoading} />
				</div>
			</div>
		</Spin>
	)
}

export default Refund