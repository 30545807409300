/**
 * 模块名称: 公共 -> 可退订单列表
 * @author lids@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
	Input,
	InputNumber,
	message,
	Button,
	Table
} from 'antd'
import BtnGroup from '@/components/BtnGroup'

const { Column } = Table

const RefundList = (props) => {
	// console.log(props)
	const { setVisible, type, setDrawerLoading, companyId, history } = props
	const [refundList, setRefundList] = useState([])
	const [chargebackmoney, setChargebackmoney] = useState(null)
	const [vendor, setVendor] = useState('')
	const [systemRefund, setSystemRefund] = useState([])
	const [backIds, setBackIds] = useState()
	const [backList, setBackList] = useState()
	const [cbmPrice, setCbmPrice] = useState([])
	const [colmarketprice, setColmarketprice] = useState([])
	const [hasAuditOrder, setHasAuditOrder] = useState(false)

	useEffect(() => {
		
	}, [])

	const getFortuneOrderRefundList = () => {
		if(vendor === ''){
			return message.error('厂商ID不能为空')
		}
		setDrawerLoading(true)
		let json = {
			type,
			companyId,
			vendor
		}
		let params = chargebackmoney == null ? json : {
			...json,
			chargebackmoney
		}
		api.getFortuneOrderRefundList(params).then(res => {
			const list = res.list
			setRefundList(list)
			setHasAuditOrder(false)
			const avaList = []
			//当系统实退金额为数字且大于0的时候可以进行退单
			//但是如果存在审批中、驳回中的的订单 不可以退单
			//如果存在可消耗余额不足的订单，只能退有可消耗的订单
			list.forEach(item => {
				if(typeof +item.backMoney === 'number' && !isNaN(+item.backMoney)){
					if(+item.backMoney > 0) avaList.push(item)
					// console.log(item.backMoney  + '是数字')
				}else{
					// console.log(item.backMoney + '不是数字')
					if((item.backMoney + '').indexOf('正在审批') > -1){
						setHasAuditOrder(true)
					}
				}
			})
			const srList = avaList.reduce((prev, curr) => {
				let json = {}
				if(res.backids && res.backids.includes(curr.id)){
					json[curr.id] = curr.backMoney
				}
				return [...prev, json]
			}, [])
			setSystemRefund(srList.filter(item => JSON.stringify(item) !== '{}'))

			const ckpList = avaList.map(item => {
				if(res.backids && res.backids.includes(item.id)){
					return item.colmarketprice
				}
			})
			setColmarketprice(ckpList.filter(item => item))

			const cmpList = avaList.map(item => {
				if(res.backids && res.backids.includes(item.id)){
					return item.chargebackMarketprice
				}
			})
			setCbmPrice(cmpList.filter(item => item))

			setDrawerLoading(false)
			if(res.backids){
				setBackIds(res.backids)
				const bList = avaList.filter(item => res.backids.includes(item.id))
				setBackList(bList)
			}else{
				JSON.stringify(res.backids) == '[]' && setBackIds([])
				setBackList([])
			}
		}).catch(() => {
			setDrawerLoading(false)
		})
	}

	const getVender = e => {
		setVendor(e.target.value)
	}

	const getChargebackmoney = e => {
		setChargebackmoney(e)
	}

	const onSearch = () => {
		if(chargebackmoney == 0) return message.warning('金额不能小于0')
		getFortuneOrderRefundList()
	}

	const onGoRefundApply = () => {
		if(hasAuditOrder){
			return message.warning('抱歉，该客户退单申请正在审批中，请检查审批中订单模块，是否有“审批中”或“被驳回”的退单！')
		}
		if(!backIds.length){
			return message.warning('当前厂商系统实退金额下没有订单可退')
		}
		history.push(`/${type == 0 ? 'orderDirect' : 'orderAgent'}/refundApply`, { 
			ids: backIds.join(','), //可退订单id
			backList, //可退订单列表
			clientType: type, //0 为直销 1 为渠道
			auditType: 0, 
			vendor, //厂商ID
			systemRefund: JSON.stringify(systemRefund), //系统实退金额
			chargebackMarketPrice: cbmPrice, //可退市场价
			colmarketprice //市场价
		})
	}

	return (
    <>
			<div className="order-refundList-containier">
				<div className="searchBox">
					<div className="searchById">
						<div className="search-label">厂商ID查找：</div>
						<Input placeholder="请输入厂商ID" onChange={getVender} /> 
					</div>
					<Button className="searchByMon" type="primary" onClick={onSearch}>搜索</Button></div>
				<div className="searchBox">
					<div className="searchById">
						<div className="search-label">厂商系统实退金额：</div>
						<InputNumber placeholder="请输入金额" min={0} step={0.01} onChange={getChargebackmoney} onBlur={onSearch} />
					</div>
					{/* <Button className="searchByMon" type="primary" onClick={onSearch}>确定</Button> */}
				</div>
				<div>
					<Table
						dataSource={refundList}
						rowKey="id"  
						className="today-info"
						scroll={{x: 1400}}
						pagination={false}
					>
						{/* <Column title="订单ID" dataIndex="id" /> */}
						<Column title="订单编号" dataIndex="number" fixed={'left'} width={160} />
						<Column title="项目" dataIndex="projectName" />
						<Column title="产品" dataIndex="productName" />
						<Column title="业务类型" dataIndex="businessName" />
						<Column title="收款类型" dataIndex="coltype" />
						<Column title="提单公司" dataIndex="supplierCompanyName" />
						<Column title="市场价" dataIndex="colmarketprice" fixed={'right'} width={120} />
						<Column title="可退市场价" dataIndex="chargebackMarketprice" fixed={'right'} width={120} />
						<Column title="系统实退金额" dataIndex="backMoney" fixed={'right'} width={120} />
					</Table>
				</div>
				{refundList.length > 0 && backIds && <BtnGroup 
					onCancel={() => setVisible(false)} 
					onConfirm={onGoRefundApply} 
					loading={false} 
					style={{marginTop: '20px'}} 
				/>}
			</div>
		</>
	)
}
export default RefundList