/**
 * 模块名称: 退单详情
 * @author lids@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
	Steps,
	Descriptions,
  Spin,
  Button,
  message,
  Tag,
  Modal,
  Icon,
  Input,
  Tooltip,
  Drawer
} from 'antd'
import { parseSearch, inputInputNumFn, debounce, toNumberPoint, onUrge } from '@/utils'
import CryptoJS from 'crypto-js'
import ConsumptionList from './ConsumptionList'

const { Step } = Steps
const dItem = Descriptions.item
const { CheckableTag } = Tag
let areaTxt = '', result = ''
let orderType = ''

const Refund = (props) => {

  const { history, location } = props
  
  const [detail, setDetail] = useState({})
  const [row, setRow] = useState({})
  const [base, setBase] = useState({})//基础信息
	const [auditProgress, setAuditProgress] = useState([])//审核进程
	const [aduitLog, setAduitLog] = useState([])//审核日志
  const [orderNumber, setOrderNumber] = useState([])
	const [orderInfo, setOrderInfo] = useState({})//订单信息
	const [attachdata, setAttachdata] = useState([])//订单扩展资料字段信息
	const [orderExInfo, setOrderExInfo] = useState({})//订单资料
	const [colInfo, setColInfo] = useState({})//收款信息
	const [performance, setPerformance] = useState([])//分成人信息
	const [refundPerformance, setRefundPerformance] = useState([])//退单分成人信息
  const [pageIds, setPageIds] = useState('')
  const [loading, setLoading] = useState(true)
	const [resPlaceholder, setResPlaceholder] = useState('请输入至少1个字符')
  const [modalVisible, setModalVisible] = useState(false)
  const [currentId, setCurrentId] = useState(null)
  const [systemRefund, setSystemRefund] = useState('')
  const [refund, setRefund] = useState('')
  const [clientRefund, setClientRefund] = useState('')
  const [vendor, setVendor] = useState([])
  const [orginOrder, setOrginOrder] = useState([])
  const [isCaiwu, setIsCaiwu] = useState(0)
  const [consumDedut, setConsumDedut] = useState('')
  const [consumDedutJson, setConsumDedutJson] = useState({})
  const [reviewLoading, setReviewLoading] = useState(false) // 审批确定loading
  const [accDcorderInfo, setAccDcorderInfo] = useState({})//订单消耗信息
  const [useinfoVisible, setUseinfoVisible] = useState(false)
  const [useinfoLoading, setUseinfoLoading] = useState(false)
  const [adrId, setAdrId] = useState('')

  //操作权限
  const [auditAuth, setAuditAuth] = useState(false)//设置按钮组是否展示
  const [hasChargerPower, setHasChargerPower] = useState(false)//设置驳回和通过按钮是否展示
  const [replenish, setReplenish] = useState(false)//设置补充资料按钮是否展示
  const [returnUp, setReturnUp] = useState(false)//设置转上级按钮是否展示
  const [rejectMsgRequire, setRejectMsgRequire] = useState(false)//设置审批意见驳回必填
  const [urge, setUrge] = useState(false)
	const [businessNo, setBusinessNo] = useState('')

	useEffect(() => {
    // console.log(location.state)
    //判断是直销还是代理商页面跳转
    if (location.pathname && location.pathname.indexOf('/orderAgent') > -1) {
      orderType = 1
    } else {
      orderType = 0
    }
    //如果从首页跳过来
    if(parseSearch(location.search).id){
      let plaintext = parseSearch(location.search).sign
      let plaintextTwo = CryptoJS.MD5(parseSearch(location.search).id + 'ad629fddf8b8756d2e72e96ae035a5a4' + parseSearch(location.search).time).toString()

      if (plaintext == plaintextTwo && +new Date() - +parseSearch(location.search).time <= 21600000) {
        api.getOrderRefundApplieInfo({id: parseSearch(location.search).id}).then(data => {
          setPageIds(data.batchIds)
          getFortuneOrderBeforeRefundApplie(data.batchIds)
          getSingleDetail(data.batchIds.split(',')[0])
        })
      } else {
        message.error('没有本页访问权限')
        let timer = setTimeout(() => {
          history.go(-2)
          clearTimeout(timer)
        }, 1000)
      }
    }else{
      if(location.state && location.state.ids){
        setPageIds(location.state.ids)
        getFortuneOrderBeforeRefundApplie(location.state.ids)
        getSingleDetail(location.state.ids.split(',')[0])
      }else if (parseSearch(location.search).ids) {
        let plaintext = parseSearch(location.search).sign
        let plaintextTwo = CryptoJS.MD5(parseSearch(location.search).ids + 'ad629fddf8b8756d2e72e96ae035a5a4' + parseSearch(location.search).time).toString()

        if (plaintext == plaintextTwo && +new Date() - +parseSearch(location.search).time <= 21600000) {
          setPageIds(parseSearch(location.search).ids)
          getFortuneOrderBeforeRefundApplie(parseSearch(location.search).ids)
          getSingleDetail(parseSearch(location.search).ids.split(',')[0])
        } else {
          message.error('没有本页访问权限')
          let timer = setTimeout(() => {
            history.go(-2)
            clearTimeout(timer)
          }, 1000)
        }
       } else {
        history.goBack()
      }
    }
	}, [])
	//获取详情（实际是获取了订单列表，然后再根据每个订单列表查询单个订单详情）
	const getFortuneOrderBeforeRefundApplie = (ids) => {
    let params = {ids: ids, type: orderType}
		api.getFortuneOrderBeforeRefundApplie(params).then(res => {
			if (parseSearch(location.search).msgId) {
				api.setMessageReaded({ ids: [parseSearch(location.search).msgId] })
      }
      let on = [...res.orderNumber].map(item => ({...item, checked: false, taped: false}))
      on[0].checked = true
      on[0].taped = true
      setOrderNumber(on)//默认请求第0个
      setCurrentId(on[0].id)
			setLoading(false)
		}).catch(() => setLoading(false))
  }
  //获取单个订单的详情
  const getSingleDetail = (id) => {
    let params = { id, type: orderType, audit: 1}
		setLoading(true)
    api.getFortuneOrderRefundOrderDetail(params, true).then(res => {
      setDetail(res)
      if(JSON.stringify(res) !== "[]"){
        let data = Object.assign({}, res)
        data.base && setBase(data.base)
        data.row && setRow(data.row)
        setIsCaiwu(data.isCaiwu)
        data.vendor && setVendor(data.vendor)
        data.orginOrder && setOrginOrder(data.orginOrder)
        if(data.row){
          setRow(data.row)
          if(data.row.batchData){
            let batData = JSON.parse(data.row.batchData)
            setSystemRefund(batData.systemRefund)
            setRefund(batData.refund)
            data.colInfo && setClientRefund((+batData.systemRefund * +data.colInfo.colrebate).toFixed(2))
          }
        }
        data.orderInfo && setOrderInfo(data.orderInfo)
        if(data.orderExInfo){
          setOrderExInfo(data.orderExInfo)
          if(data.orderExInfo.attachdata && data.orderExInfo.attachdata.length){
            data.orderExInfo.attachdata.forEach((item, index, self) => {
              self[index] = {
                name: Object.keys(item)[0],
                value: Object.values(item)[0]
              }
            })
            setAttachdata(data.orderExInfo.attachdata)
          }
        }
        data.colInfo && setColInfo(data.colInfo)
        data.performance && setPerformance(data.performance)
        data.refundPerformance && setRefundPerformance(data.refundPerformance)
        data.accDcorderInfo && setAccDcorderInfo(data.accDcorderInfo)

        if(data.auditProgress){
          let auditProgress = data.auditProgress
          auditProgress.forEach((item, index, self) => {
            if(item.persons && item.persons.length){
              self[index].persons = item.persons.join('，')
            }
          })
          setAuditProgress(auditProgress)
        }
        data.aduitLog && setAduitLog(data.aduitLog)
        if(data.buttonAuthList && JSON.stringify(data.buttonAuthList) !== '[]'){
          setAuditAuth(true)
          setHasChargerPower(data.buttonAuthList.hasChargerPower)
          setReplenish(data.buttonAuthList.replenish)
					setReturnUp(data.buttonAuthList.return_up)
					setRejectMsgRequire(data.buttonAuthList.reject_msg_require)
          setUrge(data.buttonAuthList.urge)
					setBusinessNo(data.buttonAuthList.businessNo)
        }else{
          setAuditAuth(false)
          setHasChargerPower(false)
          setReplenish(false)
					setReturnUp(false)
					setRejectMsgRequire(false)
          setUrge(false)
					setBusinessNo('')
        }
      }else{
        setBase({})
        setOrderInfo({})
        setAuditProgress([])
        setAduitLog([])
        setOrderExInfo({})
        setColInfo({})
        setPerformance([])
        setRefundPerformance([])
        setAccDcorderInfo({})
      }
			setLoading(false)
    }).catch(() => setLoading(false))
  }

  const getCheckedId = (data) => {
    if(data.id == currentId)return//点击为当前订单，return
    setCurrentId(data.id)
    let d = [...orderNumber]
    for(let i = 0; i<d.length; i++){
      d[i].checked = false
      if(d[i].id == data.id){
        d[i].checked = true
        d[i].taped = true
      }
    }
    setConsumDedut(consumDedutJson[data.id])
    setOrderNumber(d)
    getSingleDetail(data.id)
  }

	//点击4个审批的按钮
	const onAudit = (no) => {
		areaTxt = ''
		result = no
		if (no == 0) {//eslint-disable-line
			setResPlaceholder('请输入审批意见')
			setModalVisible(true)
		} else if (no == 1) {//eslint-disable-line
			//审批意见是否为必填项改由审批流控制 2020年2月22日 17:19:14
		  setResPlaceholder('请输入审批意见')
			setModalVisible(true)
		} else {
			api.onFortuneOrderFlowAduit({
				id: currentId,
				type: result,
				remark: areaTxt
			}).then(() => {
				//todo 转上级或者补充资料 返回列表页
				let timer = setTimeout(() => {
					getSingleDetail(currentId)
					clearTimeout(timer)
				}, 1000)
			})
		}
	}
	const oprateHandleOk = () => {
		if (areaTxt.length < 1 && result == 1 && rejectMsgRequire) {//eslint-disable-line
			message.warning('请输入至少1个字符')
			return
		}
		let params = {
			id: currentId,
			type: result,
			remark: areaTxt
    }
    if(isCaiwu){
      params.consumDedut = JSON.stringify(consumDedutJson) == '{}' ? '' : consumDedutJson
    }
    if(result == 0){
      if(orderNumber.some(item => !item.taped))return message.warning('请将所有带红色图标的订单依次浏览后，才能点击通过按钮！')
    }
    setReviewLoading(true)
		api.onFortuneOrderFlowAduit(params).then(() => {
			message.success('审批成功')
      setModalVisible(false)
      setReviewLoading(false)
      let timer = setTimeout(() => {
        getSingleDetail(currentId)
        clearTimeout(timer)
      }, 1000)
		}).catch(() => {
      setReviewLoading(false)
    })
	}
	//取消弹窗
	const oprateHandleCancel = () => {
		setModalVisible(false)
	}
	//同意的样式
	const agreeNode = () => {
		return result === 0 ? <>
			同意 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
		</> : <>
				不同意 <Icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96" />
			</>
	}
	//审批意见
	const getAreaTxt = (e) => {
		areaTxt = e.target.value.trim()
	}

  //退回列表页
  const onGoBack = () => {
    //跳转直销审批中列表页
    if (location.state && location.state.type == 'unfinishedzhi') {
      history.push({pathname:'/orderDirect/auditList',state: { goBack: 1}})
    }
    //跳转直销已完成列表页
    if (location.state && location.state.type == 'finishzhi') {
      history.push({pathname:'/orderDirect/finishedList',state: { goBack: 1}})
    }
    //跳转直销所有列表页
    if (location.state && location.state.type == 'allzhi') {
      history.push({pathname:'/orderDirect/allDirectList',state: { goBack: 1}})
    }
    //跳转代理商审批中的列表页
    if (location.state && location.state.type == 'unfinishedqu') {
      history.push({pathname:'/orderAgent/auditList',state: { goBack: 1}})
    }
    //跳转代理商已完成的列表页
    if (location.state && location.state.type == 'finishqu') {
      history.push({pathname:'/orderAgent/finishedList',state: { goBack: 1}})
    }
    //跳转代理商所有列表页
    if (location.state && location.state.type == 'allqu') {
      history.push({pathname:'/orderAgent/allAgentList',state: { goBack: 1}})
    }
  }

  const onConsumptionList = () => {
    setUseinfoVisible(true)
    setAdrId(accDcorderInfo.adr_id)
  }

	return (
		<Spin spinning={loading}>
      {
        location.state && location.state.type && <Button onClick={onGoBack} type="primary" style={{
          position: 'absolute',
          right: '0',
          top: '0px'
        }}>返回上一页</Button>
      }
			<div className="add-orderDirect-detail">
        <Descriptions title="退单详情" layout="vertical" className="pro-detail-title" column={5} style={{borderBottom: "none"}}>
          {row.agent && row.type == 1 && <dItem label="代理商名称">{row.agent}</dItem>}
          {row.agentId && row.type == 1 && <dItem label="QID">{row.agentId}</dItem>}
          {base.orderType && !Array.isArray(base.orderType) && <dItem label="类型">{base.orderType.name}</dItem>}
          {vendor.vendor && <dItem label="厂商ID">{vendor.vendor}</dItem>}
          {vendor.vendor && row.type == 0 && <dItem label="所属销售">{vendor.relSalerName}</dItem>}
          {vendor.vendor && row.type == 0 && <dItem label="所属客服">{vendor.relServicerName}</dItem>}
          {vendor.vendor && row.type == 1 && <dItem label="代理商所属开发">{vendor.relAgentStaffName}</dItem>}
          {vendor.vendor && row.type == 1 && <dItem label="提单人">{colInfo.userName}</dItem>}
        </Descriptions>
        {auditProgress.length > 0 && <>
        <div className="blank-line"></div>
				<div className="step-wrap">
        <div className="record-list-title">当前审批进度&nbsp;
						{auditAuth && urge && <Button type='primary' size="small" onClick={() => onUrge('urgeOrder', businessNo)}>催一催</Button>}
					</div>
          <Steps current={detail.current}>
          {
            auditProgress.map((item, index, self) => {
							let status = 'wait'
              if (item.time && item.time.length > 1) {
                status = 'process'
              }
              // if (item.nodeStatus == 2) {
              //   status = 'finish'
              // }
              return <Step status={status} title={item.nodeName} key={index} description={
                <>
                  {item.persons && <div title={item.persons}>{item.persons}</div>}
                  <div title={item.statusName}>{item.statusName}</div>
                  <div title={item.time}>{item.time}</div>
                </>
              } />
            })
          }
          </Steps>					
				</div>
        </>}
        {orderNumber.length > 0 && <>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">退款订单</div>
          <Descriptions layout="horizontal" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
            {
              orderNumber.map(item => 
              <dItem label="退单编号" key={item.id}><CheckableTag color="blue" checked={item.checked} onChange={() => getCheckedId(item)}>
                {!item.taped ? <Tooltip title="该订单还未查看"><Icon type="exclamation-circle" style={{color: '#ff4d4f', fontSize: 14}} fill="currentColor" /> {item.number}</Tooltip> : item.number}</CheckableTag>
              </dItem>)
            }
          </Descriptions>
        </div>
        </>}
        <div className="blank-line"></div> 
        <div className="ask-info">
          <div className="record-list-title">退单信息</div>
            <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              <dItem label="系统实退金额">-{(+systemRefund).toFixed(2)}</dItem>
              <dItem label={row.colstatus == 1 ? "实退客户金额" : "实退预计收款"}>-{refund}</dItem>
              {row.colstatus == 2 && <dItem label="实退信用额度">-{(+systemRefund).toFixed(2)}</dItem>}
              {orderType == 1 && row.colstatus == 1 && <dItem label="退积分">-{+row.integral}</dItem>}
              <dItem label="折扣">{colInfo.colrebate}</dItem>
              {/* <dItem label="折扣">{(row.colstatus == 1 && row.type == 1)? toNumberPoint((+refund + +row.integral) / systemRefund) : colInfo.colrebate}</dItem> */}
            </Descriptions>
            {row.colstatus == 1 && <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              <dItem label="成本">-{(+row.colcost).toFixed(2)}</dItem>
              <dItem label="调整项">-{(+row.colrefund).toFixed(2)}</dItem>
              <dItem label="个人利润">{(-row.colprofit).toFixed(2)}</dItem>
              {isCaiwu ? <dItem label="业绩抵减项">
                <Input
                  placeholder="请输入"
                  value={consumDedut}
                  onChange={e => {
                    const { value } = e.target
                    let val = inputInputNumFn(value)
                    setConsumDedut(val)
                    consumDedutJson[currentId] = val
                    setConsumDedutJson(consumDedutJson)
                  }
                } />
                </dItem>: <dItem label="业绩抵减项">{row.consumDedut == null ? '-' : row.consumDedut}</dItem>}
            </Descriptions>}
            <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              <dItem label="提单人">{orginOrder.userName}</dItem>
              <dItem label="直接主管">{orginOrder.userLeaderName}</dItem>
              <dItem label="部门">{orginOrder.userDeptName}</dItem>
            </Descriptions>
            {/* 退单分成人 */}
            {refundPerformance.length > 0 && <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none", marginBottom: "-30px"}}>
                <dItem label="分成人"></dItem>
                <dItem label="分成人部门"></dItem>
                <dItem label="分成的订单业绩"></dItem>
                <dItem label="已生成的消耗业绩"></dItem>
            </Descriptions>}
            {refundPerformance.map((item, index) => (<Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{borderBottom: "none", marginBottom: "-10px"}}>
              <dItem label="">{item.stfName}</dItem>
              <dItem label="">{item.department}</dItem>
              <dItem label="">{item.performance}</dItem>
              <dItem label="">{item.conPered}</dItem>
            </Descriptions>))}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单收款信息-{base.coltype}</div>
					{colInfo ? <>
            <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              <dItem label="市场价">{orginOrder.colmarketprice}</dItem>
              <dItem label="实收">{orginOrder.colactualprice}</dItem>
              <dItem label="预计收款">{orginOrder.colpredictprice}</dItem>
              <dItem label="折扣">{+orginOrder.colmarketprice === 0 ? '纯利' : orginOrder.colrebate}</dItem>
            </Descriptions>
            <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              <dItem label="成本">{orginOrder.colcost}</dItem>
              <dItem label="调整项">{orginOrder.colrefund}</dItem>
              <dItem label="个人利润">{orginOrder.colprofit}</dItem>
              {colInfo.creditAccount && <dItem label="信用账户ID">{colInfo.creditAccount}（{colInfo.creditType}）</dItem>}
              {orderType == 1 && row.colstatus == 1 && <dItem label="积分">{orginOrder.integral}</dItem>}
            </Descriptions>
            <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              <dItem label="提单人">{orginOrder.userName}</dItem>
              <dItem label="直接主管">{orginOrder.userLeaderName}</dItem>
              <dItem label="部门">{orginOrder.userDeptName}</dItem>
              <dItem label="资金所属公司">{orginOrder.fundCompanyName}</dItem>
            </Descriptions>
            {performance.length > 0 && <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none", marginBottom: "-30px"}}>
                <dItem label="分成人"></dItem>
                <dItem label="分成人部门"></dItem>
                <dItem label="分成的订单业绩"></dItem>
                <dItem label="已生成的消耗业绩"></dItem>
            </Descriptions>}
            {performance.map((item, index) => (<Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{borderBottom: "none", marginBottom: "-10px"}}>
              <dItem label="">{item.stfName}</dItem>
              <dItem label="">{item.department}</dItem>
              <dItem label="">{item.performance}</dItem>
              <dItem label="">{item.conPered}</dItem>
            </Descriptions>))} 
          </> : '-'}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单信息</div>
					{orderInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="项目">{orderInfo.projectName}</dItem>
						<dItem label="产品">{orderInfo.productName}</dItem>
						<dItem label="业务类型">{orderInfo.businessName}</dItem>
						<dItem label="业绩类型">{orderInfo.figtype}</dItem>
						{/* {orderType == 1 && <dItem label="是否撤资重开订单">{row.isodfdivest ? '是' : '否'}</dItem>} */}
						{orderType == 0 && <dItem label="是否退转订单">{+row.isodftrun ? '是' : '否'}</dItem>}
						{orderType == 0 && <dItem label="是否框架订单">{row.isodfkjia ? '是' : '否'}</dItem>}
						{orderType == 0 && <dItem label="销售主项">{row.majorminor == 1 ? '是' : '否'}</dItem>}
					</Descriptions> : '-'}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">厂商信息</div>
					<Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="厂商客户名称">{vendor.vendorClient}</dItem>
            <dItem label="厂商ID">{vendor.vendor}</dItem>
            <dItem label="供应商">{vendor.supplierName}</dItem>
            <dItem label="厂商客户账号">{vendor.account}</dItem>
            <dItem label="密码">{vendor.password}</dItem>
            <dItem label="网站性质">{vendor.websitexz}</dItem>
            <dItem label="行业">{vendor.industry}</dItem>
            <dItem label="地区">{vendor.area}</dItem>
            <dItem label="到期时间">{vendor.dqdate}</dItem>
            <dItem label="结款状态">{vendor.jkstatus}</dItem>
            <dItem label="是否算KPI">{vendor.iskpi}</dItem>
            <dItem label="充值类型">{vendor.money}</dItem>
            <dItem label="出结算">{colInfo.outSettle}</dItem>
					</Descriptions>
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单资料</div>
					{orderExInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="公司名称">{orderExInfo.companyName}</dItem>
						<dItem label="一级行业">{orderExInfo.industry1Name}</dItem>
						<dItem label="二级行业">{orderExInfo.industry2Name}</dItem>
						<dItem label="供应商">{orderExInfo.supplierName}</dItem>
            {
              attachdata.map(((item, index) => <dItem label={item.name} key={+index + 4}>{item.value}</dItem>))
            }
					</Descriptions> : '-'}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">退单备注</div>
					 {orderInfo.remark ? orderInfo.remark : '无'} 
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单消耗信息</div>
					 <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
            <dItem label="可用消耗">{accDcorderInfo.available}</dItem>
            <dItem label="可用余额">{accDcorderInfo.balance}</dItem>
            <dItem label="已用消耗"><a onClick={onConsumptionList}>{accDcorderInfo.usedConsumption}</a></dItem>
            <dItem label="已用余额">{accDcorderInfo.usedBalance}</dItem>
					</Descriptions>
        </div>
        <div className="blank-line"></div>
				<div className="record-list">
          <div className="record-list-title">审批记录</div>
          {aduitLog.length > 0 && <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none", marginBottom: "-30px"}}>
						<dItem label="姓名"></dItem>
						<dItem label="时间"></dItem>
						<dItem label="状态"></dItem>
						<dItem label="审批意见"></dItem>
					</Descriptions>}
					{
						aduitLog.length > 0 ? aduitLog.map((item, index) => {
							return <Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{borderBottom: "none", marginBottom: "-10px"}}>
								<dItem label="">{item.userInfo.staffName}</dItem>
								<dItem label="">{item.addTime}</dItem>
								<dItem label="">{item.status}</dItem>
								<dItem label="">{item.chargeMsg}</dItem>
							</Descriptions>
						}) : '-'
					}
				</div>
				<div className="blank-line"></div>
				{
					auditAuth && <div className="btns">
						{replenish && <Button onClick={debounce(() => {onAudit(3)}, 3000)} className="orange-btn">补充资料</Button>}
						{returnUp && <Button onClick={debounce(() => {onAudit(2)}, 3000)} className="orange-btn">转上级</Button>}
						{hasChargerPower && <Button onClick={() => onAudit(1)}>驳回</Button>}
						{hasChargerPower && <Button onClick={() => onAudit(0)} type="primary">通过</Button>}
					</div>
				}
				<Modal
					title={agreeNode()}
					visible={modalVisible}
          onOk={debounce(() => {oprateHandleOk()}, 3000)}
					onCancel={oprateHandleCancel}
					destroyOnClose={true}
          footer={[
            <Button key="back" onClick={oprateHandleCancel}>取消</Button>,
            <Button key="submit" type="primary" loading={reviewLoading} onClick={debounce(() => {oprateHandleOk()}, 3000)}>确定</Button>
          ]}
				  >
					<div className="officework-modal-content">
						<span className="label">意见：</span><Input.TextArea className="content-area" placeholder={resPlaceholder} onChange={getAreaTxt} rows={4} style={{ width: "100%" }} maxLength={100} />
					</div>
				</Modal>
        <Drawer
          title={`已用消耗: ${base.number}`}
          width={880}
          onClose={() => setUseinfoVisible(false)}
          visible={useinfoVisible}
          destroyOnClose={true}
        >
          <Spin spinning={useinfoLoading}>
            <ConsumptionList
              setUseinfoVisible={setUseinfoVisible}
              setUseinfoLoading={setUseinfoLoading}
              orderPageType={orderType == 0 ? 'Direct': 'Agent'}
              adrId={adrId}
            />
          </Spin>
        </Drawer>
			</div>
		</Spin>
	)
}

export default Refund