/**
 * 模块名称: 订单详情
 * @author lids@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from '@/api'
import moment from 'moment'
import {
	Steps,
	Descriptions,
	Button,
	Modal,
	Input,
	Icon,
	message,
  Spin,
  Table,
  Select,
  InputNumber,
  DatePicker,
  Radio,
  Drawer
} from 'antd'
import { parseSearch, debounce, onUrge } from '@/utils'
import CryptoJS from 'crypto-js'
import ConsumptionList from './ConsumptionList'

const { Step } = Steps
const dItem = Descriptions.item
const { Column } = Table
const { TextArea } = Input

let areaTxt = '', result = ''
let orderPageType = ''
let oprateHandleOkTimer = null
let paramsSX = ''

const DirectDetail = (props) => {

	const { history, location } = props

  const [detail, setDetail] = useState({})//全部数据
  const [base, setBase] = useState({})//基础信息
  const [row, setRow] = useState({})//基础信息
  const [client, setClient] = useState('')//客户信息
	const [auditProgress, setAuditProgress] = useState([])//审核进程
	const [contractInfo, setContractInfo] = useState({})//合同信息
	const [contactInfo, setContactInfo] = useState({})//联系人信息
	const [orderInfo, setOrderInfo] = useState({})//订单信息
	const [attachdata, setAttachdata] = useState([])//订单扩展资料字段信息
	const [orderExInfo, setOrderExInfo] = useState({})//订单资料
	const [colInfo, setColInfo] = useState({})//收款信息
	const [vendor, setVendor] = useState([{}])//厂商ID信息
	const [attchments, setAttchments] = useState([])//附件信息
	const [discountInfo, setDiscountInfo] = useState({})//折扣信息
	const [aduitLog, setAduitLog] = useState([])//审核日志
	const [modalVisible, setModalVisible] = useState(false)
	const [pageId, setPageId] = useState('')
	const [loading, setLoading] = useState(true)
  const [resPlaceholder, setResPlaceholder] = useState('请输入至少1个字符')
  const [discountType, setDiscountType] = useState('')//折扣
  const [company, setCompany] = useState('')//订单转出时的公司id
  const [outSettle, setOutSettle] = useState('')//提交提交时的出结算
  const [distributionVisible, setDistributionVisible] = useState(false)
  const [disStaffName, setDisStaffName] = useState('')//提交分配时的员工id
  const [turnOutVisible, setTurnOutVisible] = useState(false)
  const [companyList, setCompanyList] = useState([])
  const [vendorClient, setVendorClient] = useState('')
  const [vendorId, setVendorId] = useState('')
  const [account, setAccount] = useState('')
  const [password, setPassword] = useState('')
  const [websitexz, setWebsitexz] = useState('')
  const [industry, setIndustry] = useState('')
  const [area, setArea] = useState('')
  const [dqdate, setDqdate] = useState('1970-01-01')
  const [jkstatus, setJkstatus] = useState('')
  const [iskpi, setIskpi] = useState('')
  const [money, setMoney] = useState('')
  const [delay, ] = useState(3000)
  const [operateDisabled, setOperateDisabled] = useState(false)
  const [original, setOriginal] = useState({}) // 原订单流水分成信息
  const [ogPerformance, setOgperformance] = useState([])//原订单分成人信息
	const [performance, setPerformance] = useState([])//分成人信息
  const [submitted, onSubmitted] = useState('')//提交部门审批意见'
  const [accDcorderInfo, setAccDcorderInfo] = useState({})//订单消耗信息
  const [useinfoVisible, setUseinfoVisible] = useState(false)
  const [useinfoLoading, setUseinfoLoading] = useState(false)
  const [adrId, setAdrId] = useState('')

  
  //操作权限
  const [auditAuth, setAuditAuth] = useState(false)//设置按钮组是否展示
  const [hasChargerPower, setHasChargerPower] = useState(false)//设置驳回和通过按钮是否展示（订单同时控制展示不展示手里提交和生效 以及 折扣节点，此4个按钮在它的基础上再根据currentStatus判断是否展示）
  const [distribution, setDistribution] = useState(false)//设置分配按钮是否展示
  const [turnOut, setTurnOut] = useState(false)//设置转出按钮是否展示
  const [replenish, setReplenish] = useState(false)//设置补充资料按钮是否展示
  const [returnUp, setReturnUp] = useState(false)//设置转上级按钮是否展示
  const [currentStatus, setCurrentStatus] = useState('')//设置审批节点，是否展示提交 生效 和 受理 以及折扣节点
  const [rejectMsgRequire, setRejectMsgRequire] = useState(false)//设置审批意见驳回必填
  const [vendorFocus, setvendorFocus] = useState(false)
  const [vendorVisible, setVendorVisible] = useState(false)
  const [vendorCharsVisible, setVendorCharsVisible] = useState(false)
  const [showOrigina, setShowOrigina] = useState(0)
  const [reviewLoading, setReviewLoading] = useState(false) // 审批确定loading
  const [urge, setUrge] = useState(false)
	const [businessNo, setBusinessNo] = useState('')
  const [userAuth, setUserAuth] = useState(false) //判断是否展示补充资料重提按钮

	useEffect(() => {
    let plaintext = parseSearch(location.search).sign
    let plaintextTwo = CryptoJS.MD5(parseSearch(location.search).id + 'ad629fddf8b8756d2e72e96ae035a5a4' + parseSearch(location.search).time).toString()

    if (plaintext == plaintextTwo && +new Date() - +parseSearch(location.search).time <= 21600000) {
      areaTxt = ''
      result = ''
      if(location.search){
        //判断是直销还是代理商页面跳转
        if(location.pathname && location.pathname.indexOf('/orderAgent') > -1){
          orderPageType = 'Agent'
        }else{
          orderPageType = 'Direct'
        }
        // console.log(parseSearch(location.search))
        setPageId(parseSearch(location.search).id)
        getFortuneOrderDetail(parseSearch(location.search).id)
      }else{
        history.push({
          pathname: `/order${orderPageType}/finishedList`
        })
      }

    } else {
      message.error('没有本页访问权限')
      let timer = setTimeout(() => {
        history.go(-2)
        clearTimeout(timer)
      }, 1000)
    }
	}, [])
	//获取详情
	const getFortuneOrderDetail = (id) => {
		api[`getFortuneOrder${orderPageType}Detail`]({id: id, type: 0}).then(res => {
      setOperateDisabled(false)
      if (res.orderInfo.isDel === 1) {
        message.error('该申请已被申请人删除，无法进行审批。')
        setTimeout(() => {
          history.goBack()
        }, 1500)
        return
      }
			if (parseSearch(location.search).msgId) {
				api.setMessageReaded({ ids: [parseSearch(location.search).msgId] })
			}
			if(JSON.stringify(res) !== "[]"){
				let data = Object.assign({}, res)
				if(data.nodeStatus === 0)data.current = 0
				if(data.nodeStatus === 1)data.current = 1
				if(data.nodeStatus === 2)data.current = 2
        if(data.nodeStatus === 3)data.current = 3
        setDetail(data)
        data.base && setBase(data.base)
        setShowOrigina(data.showOrigina)
        setUserAuth(data.userAuth)
        if(data.row){
          setRow(data.row)
          if((data.modifyVendor == 2 || data.modifyVendor == 1) && data.vendor){
            setVendorId(data.vendor.vendor)
            setVendorClient(data.vendor.vendorClient)
            setAccount(data.vendor.account)
            setPassword(data.vendor.password)
            setWebsitexz(data.vendor.websitexz)
            setIndustry(data.vendor.industry)
            setArea(data.vendor.area)
            setDqdate(data.vendor.dqdate)
            setJkstatus(data.vendor.jkstatus == '未结款' ? 0 : 1)
            setIskpi(data.vendor.iskpi == '是' ? 1 : 0)
            setMoney(data.vendor.money == '现金' ? 1 : data.vendor.money == '虚拟金' ? 2 : 3)
          }
        }
        data.client && setClient(data.client)
        //假数据
        // data.currentStatus = '提交生效'
        data.currentStatus && setCurrentStatus(data.currentStatus)
        data.contractInfo && setContractInfo(data.contractInfo)
        data.contactInfo && setContactInfo(data.contactInfo)
        data.orderInfo && setOrderInfo(data.orderInfo)
        if(data.orderExInfo){
          setOrderExInfo(data.orderExInfo)
          if(data.orderExInfo.attachdata && data.orderExInfo.attachdata.length){
            data.orderExInfo.attachdata.forEach((item, index, self) => {
              self[index] = {
                name: Object.keys(item)[0],
                value: Object.values(item)[0]
              }
            })
            setAttachdata(data.orderExInfo.attachdata)
          }
        } 
        data.colInfo && setColInfo(data.colInfo)
        data.originaData && setOriginal(data.originaData)
        data.performance && setPerformance(data.performance)
        data.originaData && setOgperformance(data.originaData.performance)
        data.vendor && setVendor(data.vendor)
        data.attchments && setAttchments(data.attchments)
        data.discountInfo && setDiscountInfo(data.discountInfo)
        data.aduitLog && setAduitLog(data.aduitLog)
        data.accDcorderInfo && setAccDcorderInfo(data.accDcorderInfo)

        // 提交出结算设置初始值
        const condition = ['广点通','腾讯社交广告','腾讯社交广告行业一','爱奇艺','爱奇艺KA','360','腾讯办公QQ','腾讯营销QQ','腾讯企点','腾讯企点ISV']
        if(condition.includes(data.orderInfo.projectName) ){
          if (data.buttonAuthList.hasChargerPower && data.currentStatus == '提交提交') {
            setOutSettle(+data.colInfo.colmarketprice)
          }
        }

        if(data.auditProgress){
          let auditProgress = data.auditProgress
          auditProgress.forEach((item, index, self) => {
            if(item.persons && item.persons.length){
              self[index].persons = item.persons.join('，')
            }
          })
          setAuditProgress(auditProgress)
        }
        if(data.buttonAuthList && JSON.stringify(data.buttonAuthList) !== '[]'){
          setAuditAuth(true)
          setHasChargerPower(data.buttonAuthList.hasChargerPower)
          setReplenish(data.buttonAuthList.replenish)
          setDistribution(data.buttonAuthList.distribution)
          setTurnOut(data.buttonAuthList.turnOut)
					setReturnUp(data.buttonAuthList.return_up)
					setRejectMsgRequire(data.buttonAuthList.reject_msg_require)
          setUrge(data.buttonAuthList.urge)
					setBusinessNo(data.buttonAuthList.businessNo)
        }else{
          setAuditAuth(false)
          setHasChargerPower(false)
          setReplenish(false)
          setDistribution(false)
          setTurnOut(false)
					setReturnUp(false)
					setRejectMsgRequire(false)
          setUrge(false)
					setBusinessNo('')
        }
			}else{
				setDetail({})
        setBase({})
        setClient({})
        setAuditProgress([])
        setCurrentStatus('')
        setContractInfo({})
        setContactInfo({})
        setAccDcorderInfo({})
        setOrderInfo({})
        setAttachdata([])
        setOrderExInfo({})
        setColInfo({})
        setOriginal({})
        setOgperformance({})
        setPerformance({})
        setVendor({})
        setAttchments([])
        setDiscountInfo({})
        setAduitLog([])
			}
			setLoading(false)
		})
	}
	//点击好几个审批的按钮
	const leaveOutAudit = (no) => {
    areaTxt = ''
    result = no
    //通过
    if(no == 0){//eslint-disable-line
		  setResPlaceholder('请输入审批意见')
      setModalVisible(true)
    //驳回
    }else if(no == 1){//eslint-disable-line
			//审批意见是否为必填项改由审批流控制 2020年2月22日 17:19:23
		  setResPlaceholder('请输入审批意见')
      setModalVisible(true)
    //转上级
    }else if(no == 2){
		  setResPlaceholder('请输入审批意见')
      setModalVisible(true)
    //补充资料
    }else if(no == 3){
		  setResPlaceholder('请输入审批意见')
      setModalVisible(true)
    //分配
    }else if(no == 4){
      setDistributionVisible(true)
    //转出
    }else if(no == 5){
      setTurnOutVisible(true)
      api.getCorp({ limit: global.paramsLimit }).then(res => {
        let list = []
        res.list.forEach(item => {
          if (item.isValid === 1) list.push(item)
        })
        setCompanyList([...list])
      })
    //受理
    }else if(no == 6){
      // setResPlaceholder('请输入审批意见')
      // setModalVisible(true)
      oprateHandleOk()
    //已提交
    }else if(no == 7){
      if(outSettle == null || outSettle === '')return message.warning('出结算不能为空')
      // setResPlaceholder('请输入审批意见')
      // setModalVisible(true)
      oprateHandleOk()
    //生效
    }else if(no == 8){
      if(vendorClient === '')return message.warning('厂商客户名称不能为空')
      if(vendorId === '')return message.warning('厂商ID不能为空')
      if(account === '')return message.warning('厂商客户账号不能为空')
      if(jkstatus === '')return message.warning('结款状态为必选')
      if(iskpi === '')return message.warning('请确认是否算KPI')
      if(money === '')return message.warning('充值类型为必选')
      paramsSX = {
        vendorClient,
        vendor: vendorId,
        account,
        password,
        websitexz,
        money,
        industry,
        area,
        dqdate,
        jkstatus,
        iskpi
      }
      if(paramsSX.dqdate == '1970-01-01')paramsSX.dqdate = ''
      // setResPlaceholder('请输入审批意见')
      // setModalVisible(true)
      oprateHandleOk()
    //补充资料、转上级 2 3
    }else{
      setOperateDisabled(true)
      api.onFortuneOrderFlowAduit({
        id: pageId,
        type: result,
        remark: areaTxt,
        discountType
      }, true).then(() => {
        setDiscountType('')
        goAuduitList()
        // setOperateDisabled(false)
      }).catch(() => setOperateDisabled(false))     
    }
  }
  //跳转审批列表页
  const goAuduitList = () => {
    let timer = setTimeout(() => {
      history.push({
        pathname: `/order${orderPageType}/auditList`
      })
      onSubmitted('')
      setOperateDisabled(false)
      clearTimeout(timer)
    }, 1000)
  }
  // 审批
	const oprateHandleOk = () => {
    oprateHandleOkTimer = null
    return function(){
      if(oprateHandleOkTimer)clearTimeout(oprateHandleOkTimer)
      oprateHandleOkTimer = setTimeout(() => {
          clearTimeout(oprateHandleOkTimer)
          if(areaTxt.length < 1 && result == 1 && rejectMsgRequire){//eslint-disable-line
            return message.warning('请输入至少1个字符')
          }
          if(currentStatus === '折扣开始' && !discountType && result === 0){
            return message.warning('请选择折扣类型')
          }
          setReviewLoading(true)
          let params = {
            id: pageId,
            type: result,
            remark: areaTxt
          }
          if(discountType)params.discountType = discountType//折扣类型
          if (result == 8) { // 生效确定
            setOperateDisabled(true)
            api.onFortuneOrderFlowTakeEffect({ id: pageId, ...paramsSX, remark: submitted }, true).then(() => {
              setModalVisible(false)
              setReviewLoading(false)
              message.info('提交已生效成功')
              goAuduitList()
              // setOperateDisabled(false)
            }).catch(() => {
              setModalVisible(false)
              setOperateDisabled(false)
              setReviewLoading(false)
            })
          } else if (result == 7) { // 提交提交确定
            setOperateDisabled(true)
            api.onFortuneOrderFlowSubmit({ id: pageId, outSettle, remark: submitted }, true).then(() => {
              message.info('提交成功')
              onSubmitted('')
              getFortuneOrderDetail(pageId)
              // setOperateDisabled(false)
              setModalVisible(false)
              setReviewLoading(false)
            }).catch(() => {
              setOperateDisabled(false)
              setReviewLoading(false)
            })
          } else if (result == 6) { // 受理确定
            setOperateDisabled(true)
            api.onFortuneOrderFlowAccept({ id: pageId, remark: submitted }, true).then(() => {
              message.info('受理成功')
              onSubmitted('')
              getFortuneOrderDetail(pageId)
              // setOperateDisabled(false)
              setModalVisible(false)
              setReviewLoading(false)
            }).catch(() => {
              setOperateDisabled(false)
              setReviewLoading(false)
            })
          } else {
          api.onFortuneOrderFlowAduit(params, true).then(res => {
            setModalVisible(false)
            message.success('审批成功')
            setReviewLoading(false)
            setDiscountType('')
            getFortuneOrderDetail(pageId)
          }).catch(() => {
            setReviewLoading(false)
          })
        }
      }, 1500)
    }()	
	}
	//同意的样式
	const agreeNode = () => {
    if (result === 0) {
      return <>同意 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /></>
    } else if (result === 1) {
      return <>不同意 <Icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96" /></>
    } else if (result === 2) {
      return <>转上级 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /></>
    } else if (result === 3) {
      return <>补充资料 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /></>
    } else if (result === 6) {
      return <>受理 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /></>
    } else if (result === 7) {
      return <>已提交 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /></>
    } else if (result === 8) {
      return <>生效 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /></>
    }
	}
	//审批意见
	const getAreaTxt = (e) => {
		areaTxt = e.target.value.trim()
  }
  //分配
	const oprateHandleOkDist = () => {
    if(disStaffName === '')return message.warning('请选择审批人')
    setReviewLoading(true)
		api.onFortuneOrderFlowDistribution({id: pageId, stfid: disStaffName}).then(() => {
      setDistributionVisible(false)
      message.info('分配成功')
      setReviewLoading(false)
      setDisStaffName('')
      getFortuneOrderDetail(pageId)
    }).catch(() => {
      setReviewLoading(false)
    })
	}
  //转出
	const oprateHandleOkturnOut = () => {
    if(company === '')return message.warning('请选择转出公司')
    if(outSettle == null || outSettle === '')return message.warning('出结算不能为空')
    api.onFortuneOrderFlowTurnOut({id: pageId, company, outSettle}).then(() => {
      message.info('转出成功')
      setTurnOutVisible(false)
      getFortuneOrderDetail(pageId)
    })
  }
  //保存厂商信息
  const onSaveVendorByModify = () => {
    if(vendorClient === '')return message.warning('厂商客户名称不能为空')
    if(vendorId === '')return message.warning('厂商ID不能为空')
    if(account === '')return message.warning('厂商客户账号不能为空')
    if(jkstatus === '')return message.warning('结款状态为必选')
    if(iskpi === '')return message.warning('请确认是否算KPI')
    if(money === '')return message.warning('充值类型为必选')
    let params = {
      id: vendor.id,
      vendorClient,
      vendor: vendorId,
      account,
      password,
      websitexz,
      money,
      industry,
      area,
      dqdate,
      jkstatus,
      iskpi,
      orderId: pageId
    }
    if(params.dqdate == '1970-01-01')params.dqdate = ''
    api.onSaveVendorByModify(params).then(() => {
      message.info('修改厂商信息成功')
    })
  }

  //退回列表页
  const onGoBack = () => {
    //跳转直销已完成列表页
    if (parseSearch(location.search).type == 'finishzhi') {
      history.push({pathname:'/orderDirect/finishedList',state: { goBack: 1}})
    }
    //跳转直销审批中列表页
    if (parseSearch(location.search).type == 'unfinishedzhi') {
      history.push({pathname:'/orderDirect/auditList',state: { goBack: 1}})
    }
    //跳转直销所有列表页
    if (parseSearch(location.search).type == 'allzhi') {
      history.push({pathname:'/orderDirect/allDirectList',state: { goBack: 1}})
    }
    //跳转代理商已完成列表页
    if (parseSearch(location.search).type == 'finishqu') {
      history.push({pathname:'/orderAgent/finishedList',state: { goBack: 1}})
    }
    //跳转代理商审批中列表页
    if (parseSearch(location.search).type == 'unfinishedqu') {
      history.push({pathname:'/orderAgent/auditList',state: { goBack: 1}})
    }
    //跳转代理商所有列表页
    if (parseSearch(location.search).type == 'allqu') {
      history.push({pathname:'/orderAgent/allAgentList',state: { goBack: 1}})
    }
  }

  // 厂商id失焦
  const onVendorBlur = () => {
    if(vendorId !== '') {
      // 半角空格
      const reg = /^[^ ]+$/
      if (!reg.test(vendorId)) {
        setVendorVisible(true)
        setvendorFocus(true)
        return
      } 
      // 全角空格
      const regs = /^[^　]+$/
      if (!regs.test(vendorId)) {
        setVendorVisible(true)
        setvendorFocus(true)
        return
      } 

      const reg1 = /－/g
      if (reg1.test(vendorId)) {
        setVendorCharsVisible(true)
        setvendorFocus(true)
        return
      } 
      setvendorFocus(false)  
    }
  }  

  const onConsumptionList = () => {
    setUseinfoVisible(true)
    setAdrId(accDcorderInfo.adr_id)
  }

  const submitTyle = () => {
    if (currentStatus == '提交受理') {
      return '请输入受理审批意见'
    } else if (currentStatus == '提交提交') {
      return '请输入提交审批意见'
    } else if (currentStatus == '提交生效') { 
      return '请输入生效审批意见'
    } else {
      return '请输入'
    }
  }

	return (
		<Spin spinning={loading}>
      {
        parseSearch(location.search).type &&  <Button onClick={onGoBack} type="primary" style={{
          position: 'absolute',
          right: '20px',
          top: '0px'
        }}>返回上一页</Button>
      }
			<div className="add-orderDirect-detail">
        <Descriptions title={`${orderPageType == 'Direct' ? '直销订单' : '代理商订单'}-${base.coltype || ''}-${base.number || ''}`} layout="vertical" className="pro-detail-title" column={5} style={{borderBottom: "none"}}>
          <dItem label="客户名称">{client.name}</dItem>
          <dItem label="OAID">{client.id}</dItem>
          {base.orderType && !Array.isArray(base.orderType) && <dItem label="类型">{base.orderType.name}</dItem>}
          {row.agent && orderPageType == 'Agent' && <dItem label="代理商名称">{row.agent}</dItem>}
          {row.agentId && orderPageType == 'Agent' && <dItem label="QID">{row.agentId}</dItem>}
          {vendor.vendor && <dItem label="厂商ID">{vendor.vendor}</dItem>}
          {vendor.vendor && orderPageType == 'Direct' && <dItem label="所属销售">{vendor.relSalerName}</dItem>}
          {vendor.vendor && orderPageType == 'Direct' && <dItem label="所属客服">{vendor.relServicerName}</dItem>}
          {vendor.vendor && orderPageType == 'Agent' && <dItem label="代理商所属开发">{vendor.relAgentStaffName}</dItem>}
          {vendor.vendor && orderPageType == 'Agent' && <dItem label="提单人">{colInfo.userName}</dItem>}
        </Descriptions>
        <div className="blank-line"></div>
				<div className="step-wrap">
          <div className="record-list-title">当前审批进度&nbsp;
						{auditAuth && urge && <Button type='primary' size="small" onClick={() => onUrge(`urgeOrder${orderPageType}`, businessNo)}>催一催</Button>}
					</div>
          <Steps current={detail.current}>
          {
            auditProgress.map((item, index, self) => {
							let status = 'wait'
              if (item.time && item.time.length > 1) {
                status = 'process'
              }
              // if (item.nodeStatus == 2) {
              //   status = 'finish'
              // }
              return <Step status={status} title={item.nodeName} key={index} description={
                <>
                  {item.persons && <div title={item.persons}>{item.persons}</div>}
                  <div title={item.statusName}>{item.statusName}</div>
                  <div title={item.time}>{item.time}</div>
                </>
              } />
            })
          }
          </Steps>					
				</div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">合同信息</div>
					{contractInfo && JSON.stringify(contractInfo) !== '{}' ? <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="客户姓名">{contractInfo.customerName}</dItem>
						<dItem label="合同编号">{contractInfo.contractNo}</dItem>
						<dItem label="合同名称">{contractInfo.templateName}</dItem>
						<dItem label="合同主体">{contractInfo.clientSource == 1 ? contractInfo.partA : contractInfo.partB}</dItem>
						<dItem label="合同有效期">{contractInfo.term}</dItem>
					</Descriptions> : '-'}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单联系人</div>
					{contactInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="订单联系人">{contactInfo.contactname}</dItem>
						<dItem label="手机">{contactInfo.contactmobile}</dItem>
						<dItem label="座机">{contactInfo.contacttel}</dItem>
						<dItem label="传真">{contactInfo.contactfax}</dItem>
						<dItem label="邮箱">{contactInfo.contactemail}</dItem>
						<dItem label="QQ">{contactInfo.contactqq}</dItem>
						{/* <dItem label="网址">{contactInfo.contactweb}</dItem>
						<dItem label="通信地址">{contactInfo.contactaddress}</dItem> */}
					</Descriptions> : '-'}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单信息</div>
					{orderInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="项目">{orderInfo.projectName}</dItem>
						<dItem label="产品">{orderInfo.productName}</dItem>
						<dItem label="业务类型">{orderInfo.businessName}</dItem>
						<dItem label="业绩类型">{orderInfo.figtype}</dItem>
            {/* {orderPageType == 'Direct' && <dItem label="是否撤资重开订单">{orderInfo.isodfdivest}</dItem>} */}
						{orderPageType == 'Direct' && <dItem label="是否退转订单">{+row.isodftrun ? '是' : '否'}</dItem>}
						{orderPageType == 'Direct' && <dItem label="是否框架订单">{row.isodfkjia ? '是' : '否'}</dItem>}
						{orderPageType == 'Direct' && <dItem label="销售主项">{row.majorminor == 1 ? '是' : '否'}</dItem>}
            {orderPageType == 'Agent' && <dItem label="代理商名称">
              <div>{orderInfo.agentName}</div>
              <div>ID: {orderInfo.agentId}</div>
            </dItem>}
					</Descriptions> : '-'} 
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单资料</div>
					{orderExInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="公司名称">{orderExInfo.companyName}</dItem>
						<dItem label="一级行业">{orderExInfo.industry1Name}</dItem>
						<dItem label="二级行业">{orderExInfo.industry2Name}</dItem>
						<dItem label="供应商">{orderExInfo.supplierName}</dItem>
						<dItem label={<span className='font-striking'>提单公司</span>}><span className='font-striking-content'>{orderExInfo.supplierCompanyName}</span></dItem>
            {
              attachdata.map(((item, index) => <dItem label={item.name !== '备注' ? item.name : <span className='font-striking'>备注</span>} key={+index+4}>{item.name !== '备注' ? item.value : <span className='font-striking-content'>{item.value}</span>}</dItem>))
            }
					</Descriptions> : '-'}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单收款信息-{base.coltype}</div>
					{colInfo ? <><Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="市场价">{colInfo.colmarketprice}</dItem> 
            <dItem label='实收'>{colInfo.colactualprice}</dItem>
            {(row.coltype == 2 || row.coltype == 5) && <dItem label='预计收款'>{colInfo.colpredictprice}</dItem>}
						<dItem label="折扣">{+colInfo.colmarketprice === 0 ? '纯利': colInfo.colrebate}</dItem>
						{orderPageType == 'Agent' && <dItem label="积分">{colInfo.integral}</dItem>}
          </Descriptions>
          <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="成本">{colInfo.colcost}</dItem>
						<dItem label="调整项">{colInfo.colrefund}</dItem>
						<dItem label="个人利润">{colInfo.colprofit}</dItem>
            {colInfo.creditAccount && <dItem label="信用账户ID">{colInfo.creditAccount}（{colInfo.creditType}）</dItem>}
          </Descriptions>
          <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="提单人">{colInfo.userName}</dItem>
						<dItem label="直接主管">{colInfo.userLeader}</dItem>
						<dItem label="部门">{colInfo.userDepart}</dItem>
            <dItem label="资金所属公司">{colInfo.fundCompanyName}</dItem>
					</Descriptions>
          {performance.length > 0 && <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none", marginBottom: "-30px"}}>
            <dItem label="分成人"></dItem>
            <dItem label="分成人部门"></dItem>
            <dItem label="分成的订单业绩"></dItem>
            <dItem label="已生成的消耗业绩"></dItem>
          </Descriptions>}
          {performance.map((item, index) => (<Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{borderBottom: "none", marginBottom: "-10px"}}>
            <dItem label="">{item.stfName}</dItem>
            <dItem label="">{item.department}</dItem>
            <dItem label="">{item.performance}</dItem>
            <dItem label="">{item.conPered}</dItem>
          </Descriptions>))}
          </> : '-'}
        </div>
        {
          showOrigina === 1 && <>
            <div className="blank-line"></div>
            <div className="ask-info">
              <div className="record-list-title">原订单流水与分成信息</div>
              {original ? <><Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none" }}>
                <dItem label="市场价">{original.colmarketprice}</dItem>
                <dItem label='实收'>{original.colactualprice}</dItem>
                {(row.coltype == 2 || row.coltype == 5) && <dItem label='预计收款'>{original.colpredictprice}</dItem>}
                <dItem label="折扣">{+original.colmarketprice === 0 ? '纯利' : original.colrebate}</dItem>
                {orderPageType == 'Agent' && <dItem label="积分">{original.integral}</dItem>}
              </Descriptions>
                <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none" }}>
                  <dItem label="成本">{original.colcost}</dItem>
                  <dItem label="调整项">{original.colrefund}</dItem>
                  <dItem label="个人利润">{original.colprofit}</dItem>
                </Descriptions>
                <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none" }}>
                  <dItem label="提单人">{original.userName}</dItem>
                  <dItem label="直接主管">{original.userLeaderName}</dItem>
                  <dItem label="部门">{original.userDeptName}</dItem>
                  <dItem label="资金所属公司">{original.fundCompanyName}</dItem>
                </Descriptions></> : '-'}

              {ogPerformance.length > 0 && <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none", marginBottom: "-30px" }}>
                <dItem label="分成人"></dItem>
                <dItem label="分成人部门"></dItem>
                <dItem label="分成的订单业绩"></dItem>
                <dItem label="已生成的消耗业绩"></dItem>
              </Descriptions>}
              {ogPerformance.map((item, index) => (<Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{ borderBottom: "none", marginBottom: "-10px" }}>
                <dItem label="">{item.stfName}</dItem>
                <dItem label="">{item.department}</dItem>
                <dItem label="">{item.performance}</dItem>
                <dItem label="">{item.conPered}</dItem>
              </Descriptions>))}
            </div>
          </>
        }
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单附件</div>
          <Descriptions title=""
            layout="horizontal"
            className="pro-detail-smailtitle"
          >
            <div className="content" style={{display: 'flex'}}>
              {
                attchments.length ? attchments.map((item, index) => {
                  return <div key={index}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></div>
                }) : '无'
              }
            </div>
          </Descriptions>
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
					<Descriptions title={<span className="font-striking">备注</span>} layout="horizontal" className="pro-detail-smailtitle">
            <div className="content">
            {(orderInfo.remark && orderInfo.remark.length) ? <span className="font-striking-content">{orderInfo.remark}</span> : '-'}
            </div>
					</Descriptions> 
        </div>
        {vendor.vendor && <>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">厂商信息</div>
					<Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="厂商客户名称">{vendor.vendorClient}</dItem>
            <dItem label="厂商ID">{vendor.vendor}</dItem>
            <dItem label="供应商">{vendor.supplierName}</dItem>
            <dItem label="厂商客户账号">{vendor.account}</dItem>
            <dItem label="密码">{vendor.password}</dItem>
            <dItem label="网站性质">{vendor.websitexz}</dItem>
            <dItem label="行业">{vendor.industry}</dItem>
            <dItem label="地区">{vendor.area}</dItem>
            <dItem label="到期时间">{vendor.dqdate}</dItem>
            <dItem label="结款状态">{vendor.jkstatus}</dItem>
            <dItem label="是否算KPI">{vendor.iskpi}</dItem>
            <dItem label="充值类型">{vendor.money}</dItem>
            <dItem label="出结算">{colInfo.outSettle}</dItem>
					</Descriptions>
        </div>
        </>}
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">折扣信息</div>
					{discountInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="客户名称">
              <div>{discountInfo.clientName}</div>
              <div>ID: {client.id}</div>
            </dItem>
            <dItem label="项目">{discountInfo.projectName}</dItem>
            <dItem label="产品">{discountInfo.productName}</dItem>
            <dItem label="一级行业">{discountInfo.industry1}</dItem>
            <dItem label="二级行业">{discountInfo.industry2}</dItem>
            <dItem label="折扣">{colInfo && +colInfo.colmarketprice === 0 ? '纯利' : discountInfo.colrebate}</dItem>
            <dItem label="供应商">
              <div>{discountInfo.supplier}</div>
              <div>ID: {orderExInfo.supplier}</div>
            </dItem>
            <dItem label="折扣类型">{discountInfo.discountType}</dItem>
					</Descriptions> : '-'}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单消耗信息</div>
					 <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
            <dItem label="可用消耗">{accDcorderInfo.available}</dItem>
            <dItem label="可用余额">{accDcorderInfo.balance}</dItem>
            <dItem label="已用消耗"><a onClick={onConsumptionList}>{accDcorderInfo.usedConsumption}</a></dItem>
            <dItem label="已用余额">{accDcorderInfo.usedBalance}</dItem>
					</Descriptions>
        </div>
        <div className="blank-line"></div>
				<div className="record-list">
          <div className="record-list-title">审批记录</div>
          {aduitLog.length > 0 && <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none", marginBottom: "-30px"}}>
						<dItem label="姓名"></dItem>
						<dItem label="时间"></dItem>
						<dItem label="状态"></dItem>
						<dItem label="审批意见"></dItem>
					</Descriptions>}
					{
						aduitLog.length > 0 ? aduitLog.map((item, index) => {
							return <Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{borderBottom: "none", marginBottom: "-10px"}}>
								<dItem label="">{item.userInfo.staffName}</dItem>
								<dItem label="">{item.addTime}</dItem>
								<dItem label="">{item.status}</dItem>
								<dItem label="">{item.chargeMsg}</dItem>
							</Descriptions>
						}) : '-'
					}
				</div>
        {hasChargerPower && currentStatus == '折扣开始' && discountInfo.discountType == null && <>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">处理信息</div>
					 <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
            <dItem label={<><span className="required-label">*</span> 折扣类型</>}>
              <Select
                placeholder="请选择折扣类型"
                style={{width: '200px'}}
                onChange={val => setDiscountType(val)}
              >
                {
                  [{name: '长期折扣', id: 0}, {name: '临时折扣', id: 1}].map(item => {
                    return <Select.Option key={item.id}>{item.name}</Select.Option>
                  })
                }
              </Select>
						</dItem>
					</Descriptions>
        </div>
        </>}
        {hasChargerPower && currentStatus == '提交提交' && <>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">处理信息</div>
					 <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
            <dItem label={<><span className="required-label">*</span> 出结算</>}>
              <InputNumber
                step={0.01}
                min={0}
                style={{width: '200px'}}
                placeholder="请输入"
                value={outSettle}
                onChange={val => setOutSettle(val)}
            />
						</dItem>
					</Descriptions>
        </div>
        </>}
        {/* {hasChargerPower && currentStatus == '提交生效' && <> */}
        {detail.modifyVendor !== -1 && <>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">厂商信息</div>
					 <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
            <dItem label={<><span className="required-label">*</span> 厂商客户名称</>}>
              <Input
                style={{width: '200px'}}
                placeholder="请输入"
                value={vendorClient}
                onChange={e => setVendorClient(e.target.value.trim())}
            />
						</dItem>
            <dItem label={<><span className="required-label">*</span> 厂商ID</>}>
              {detail.modifyVendor == 0 && <Input
                style={{width: '200px'}}
                placeholder="请输入"
                // autoFocus={true}
                ref={ (input) => {
                  if (input != null && vendorFocus) {
                    input.focus()
                  }
                }}
                onChange={e => setVendorId(e.target.value)}
                onBlur={onVendorBlur}
              />}
              {detail.modifyVendor == 1 && <div>{vendor.vendor}</div>}
              {detail.modifyVendor == 2 && <Input
                style={{width: '200px'}}
                placeholder="请输入"
                // autoFocus={true}
                value={vendorId}
                ref={ (input) => {
                  if (input != null && vendorFocus) {
                    input.focus()
                  }
                }}
                onChange={e => setVendorId(e.target.value)}
                onBlur={onVendorBlur}
              />}
						</dItem>
            <dItem label="供应商">
              <div>{orderExInfo.supplierName}</div> 
						</dItem>
            <dItem label={<><span className="required-label">*</span> 厂商客户账号</>}>
              <Input
                style={{width: '200px'}}
                placeholder="请输入"
                value={account}
                onChange={e => setAccount(e.target.value.trim())}
            />
						</dItem>
            <dItem label="密码">
              <Input
                style={{width: '200px'}}
                placeholder="请输入"
                value={password}
                onChange={e => setPassword(e.target.value.trim())}
            />
						</dItem>
            <dItem label="网站性质">
              <Input
                style={{width: '200px'}}
                placeholder="请输入"
                value={websitexz}
                onChange={e => setWebsitexz(e.target.value.trim())}
            />
						</dItem>
            <dItem label="行业">
              <Input
                style={{width: '200px'}}
                placeholder="请输入"
                value={industry}
                onChange={e => setIndustry(e.target.value.trim())}
            />
						</dItem>
            <dItem label="地区">
              <Input
                style={{width: '200px'}}
                placeholder="请输入"
                value={area}
                onChange={e => setArea(e.target.value.trim())}
            />
						</dItem>
            <dItem label="到期日期">
              {row.figtype == 2 && vendor.dqdate ? <DatePicker style={{width: '200px'}} allowClear={false} value={moment(dqdate, 'YYYY-MM-DD')} onChange={val => {
                setDqdate(moment(val).format('YYYY-MM-DD'))
              }} /> : <DatePicker style={{width: '200px'}} allowClear={false} onChange={val => {
                setDqdate(moment(val).format('YYYY-MM-DD'))
              }} />}
						</dItem>
            <dItem label={<><span className="required-label">*</span> 结款状态</>}>
              <Radio.Group style={{width: '250px'}} value={jkstatus} onChange={e => {
                setJkstatus(e.target.value)
              }}>
                <Radio value={1}>已结</Radio>
                <Radio value={0}>未结</Radio>
              </Radio.Group>
						</dItem>
            <dItem label={<><span className="required-label">*</span> 是否算KPI</>}>
              <Radio.Group style={{width: '250px'}} value={iskpi} onChange={e => {
                setIskpi(e.target.value)
              }}>
                <Radio value={1}>是</Radio>
                <Radio value={0}>否</Radio>
              </Radio.Group>
						</dItem>
            <dItem label={<><span className="required-label">*</span> 充值类型</>}>
              <Radio.Group style={{width: '250px'}} value={money} onChange={e => {
                setMoney(e.target.value)
              }}>
                <Radio value={1}>现金</Radio>
                <Radio value={2}>虚拟金</Radio>
                <Radio value={3}>固定账期</Radio>
              </Radio.Group>
						</dItem>
            <dItem label="出结算">
              <div>{colInfo.outSettle}</div>
						</dItem>
					</Descriptions>
        </div>
				{(detail.modifyVendor == 1 || detail.modifyVendor == 2) && !hasChargerPower &&
					<div className="btns">
            <Button type="primary" onClick={debounce(() => {onSaveVendorByModify(3)}, delay)}>保存</Button>
					</div>
				}
        </>}
        {
					auditAuth && <div>
            {
              hasChargerPower && (currentStatus == '提交受理' || currentStatus == '提交提交' || currentStatus == '提交生效') &&
              <TextArea
                style={{ marginBottom: '-10px' }}
                disabled={operateDisabled} 
                placeholder={submitTyle()}
                value={submitted}
                onChange={e => onSubmitted(e.target.value)}
              />
            }
					</div>
				}
				{
					auditAuth && <div className="btns">
            {userAuth && userAuth.fallowReplenish && <Button disabled={operateDisabled} onClick={() => {
              history.push(`/order${orderPageType}/edit`, {
                relationId: row.clientId, 
                id: row.id, 
                qId: row.agentId ? row.agentId : '', 
                auditStatus: row.auditStatus,
                vendorEdit: row.vendor ? row.vendor : ''
              })
              // if (location.pathname && location.pathname.indexOf('/orderAgent') > -1) {
              //   history.push('/orderAgent/edit', {
              //     relationId: row.clientId, 
              //     id: row.id, 
              //     qId: row.agentId, 
              //     auditStatus: row.auditStatus,
              //     vendorEdit: row.vendor ? row.vendor : ''
              //    })
              // } else {
              //   history.push('/orderDirect/edit', {
              //     relationId: row.clientId,
              //     id: row.id, 
              //     auditStatus: row.auditStatus,
              //     vendorEdit: row.vendor ? row.vendor : ''
              //   })
              // }
            }} className="orange-btn">补充资料重提</Button>}
            {replenish && <Button disabled={operateDisabled} onClick={() => {leaveOutAudit(3)}} className="orange-btn">补充资料</Button>}
            {returnUp && <Button disabled={operateDisabled} onClick={() => {leaveOutAudit(2)}} className="orange-btn">转上级</Button>}
						{distribution && <Button onClick={debounce(() => {leaveOutAudit(4)}, delay)}>分配</Button>}
						{/* {turnOut && <Button onClick={debounce(() => {leaveOutAudit(5)}, delay)}>转出</Button>} */}
						{hasChargerPower && <Button disabled={operateDisabled} onClick={() => leaveOutAudit(1)}>驳回</Button>}
						{hasChargerPower && !['提交受理', '提交提交', '提交生效'].some(item => item == currentStatus) && <Button onClick={() => leaveOutAudit(0)} type="primary">通过</Button>}
						{hasChargerPower && currentStatus == '提交受理' && <Button disabled={operateDisabled} onClick={debounce(() => {leaveOutAudit(6)}, delay)} type="primary">受理</Button>}
						{hasChargerPower && currentStatus == '提交提交' && <Button disabled={operateDisabled} onClick={debounce(() => {leaveOutAudit(7)}, delay)}type="primary">已提交</Button>}
						{hasChargerPower && currentStatus == '提交生效' && <Button disabled={operateDisabled} onClick={debounce(() => {leaveOutAudit(8)}, delay)} type="primary">生效</Button>}
					</div>
				}
				<Modal
					title={agreeNode()}
					visible={modalVisible}
					onOk={debounce(() => {oprateHandleOk()}, delay)}
					onCancel={() => setModalVisible(false)}
					destroyOnClose={true}
          footer={[
            <Button key="back" onClick={() => setModalVisible(false)}>取消</Button>,
            <Button key="submit" type="primary" loading={reviewLoading} onClick={debounce(() => {oprateHandleOk()}, delay)}>确定</Button>
          ]}
				>
					<div className="officework-modal-content">
						<span className="label">审批意见：</span><Input.TextArea className="content-area" placeholder={resPlaceholder} onChange={getAreaTxt} rows={4} style={{width: "100%"}} maxLength={100} />
					</div>
				</Modal>
        <Modal
          title="分配受理人"
          visible={distributionVisible}
          onOk={debounce(() => {oprateHandleOkDist()}, delay)}
          onCancel={() => setDistributionVisible(false)}
          destroyOnClose={true}
          footer={[
            <Button key="back" onClick={() => setDistributionVisible(false)}>取消</Button>,
            <Button key="submit" type="primary" loading={reviewLoading} onClick={debounce(() => {oprateHandleOkDist()}, delay)}>确定</Button>
          ]}
        >
          <div className="officework-modal-content">
            <span style={{display: 'inline-block', width: '15%', height: '30px', lineHeight: '30px'}}><span style={{color: 'red'}}>*</span> 审批人</span>
            <Select
              placeholder="请选择审批人"
              style={{width: '80%'}}
              onChange={val => setDisStaffName(val)}
            >
              {
                detail.distributionStaff && detail.distributionStaff.map(item => {
                  return <Select.Option key={item.id}>{item.staffName}</Select.Option>
                })
              }
            </Select>
          </div>
        </Modal>
        <Modal
          title="转出"
          visible={turnOutVisible}
          onOk={debounce(() => {oprateHandleOkturnOut()}, delay)}
          onCancel={() => setTurnOutVisible(false)}
          destroyOnClose={true}
        >
          <div className="officework-modal-content">
            <span style={{display: 'inline-block', width: '15%', height: '30px', lineHeight: '30px'}}><span style={{color: 'red'}}>*</span> 转出至</span>
            <Select
              placeholder="请选择公司"
              style={{width: '80%'}}
              onChange={val => setCompany(val)}
            >
              {
                companyList.map(item => {
                  return <Select.Option key={item.id}>{item.name}</Select.Option>
                })
              }
            </Select>
          </div>
        </Modal>
        {/* 判断不能包含空格 */}
        <Modal
          title="提示"
          visible={vendorVisible}
          onOk={() => {setVendorVisible(false)}}
          onCancel={() => {setVendorVisible(false)}}
          destroyOnClose={true}
        >
          <div>
            <span>厂商ID中不允许有空格，请检查并重新填写，谢谢！</span>
          </div>
        </Modal>
         {/* 判断不允许有半角符号“-” */}
        <Modal
          title="提示"
          visible={vendorCharsVisible}
          onOk={() => { setVendorCharsVisible(false) }}
          onCancel={() => { setVendorCharsVisible(false) }}
          destroyOnClose={true}
        >
          <div>
            <span>厂商ID中不允许有全角符号“－”，如需要可用半角符号“-”代替，请检查并重新填写，谢谢！</span>
          </div>
        </Modal>
        <Drawer
          title={`已用消耗: ${base.number}`}
          width={880}
          onClose={() => setUseinfoVisible(false)}
          visible={useinfoVisible}
          destroyOnClose={true}
        >
          <Spin spinning={useinfoLoading}>
            <ConsumptionList
              setUseinfoVisible={setUseinfoVisible}
              setUseinfoLoading={setUseinfoLoading}
              orderPageType={orderPageType}
              adrId={adrId}
            />
          </Spin>
        </Drawer>
			</div>
		</Spin>
	)
}

export default DirectDetail