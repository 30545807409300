/**
 * 模块名称: 还款申请-单个还款
 * @author lids@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from '@/api'
import { Link } from 'react-router-dom'
import {
	Descriptions,
  Spin,
  InputNumber,
  Radio,
  message,
  Tooltip,
  Modal
} from 'antd'
import { parseSearch, toNumber, toNumberPoint } from '@/utils'
import BtnGroup from '@/components/BtnGroup'

const dItem = Descriptions.item
let orderType = ''

const Payback = (props) => {

  const { history, location } = props

  const [base, setBase] = useState({})//基础信息
  const [client, setClient] = useState('')//客户信息
  const [available, setAvailable] = useState('')//账户信息
  const [orderInfo, setOrderInfo] = useState({})//订单信息
  const [contractInfo, setContractInfo] = useState('')//账户信息
  const [row, setRow] = useState({})
	const [attachdata, setAttachdata] = useState([])//订单扩展资料字段信息
	const [orderExInfo, setOrderExInfo] = useState({})//订单资料
	const [colInfo, setColInfo] = useState({})//收款信息
  const [original, setOriginal] = useState({}) // 原订单流水分成信息
	const [vendorInfo, setVendorInfo] = useState({})//厂商ID信息
	const [discountInfo, setDiscountInfo] = useState({})//折扣信息
  const [pageIds, setPageIds] = useState('')
  const [loading, setLoading] = useState(true)
  const [colactualprice, setColactualprice] = useState('')//实收
  const [colmarketprice, setColmarketprice] = useState('')//还款市场价
  const [isAllIn, setIsAllIn] = useState('')//是否全部还款
  const [colmarketpriceDis, setColmarketpriceDis] = useState(true)
  const [colprofit, setColprofit] = useState('')//个人利润
  const [colcost, setColcost] = useState('')//成本
  const [colrefund, setColrefund] = useState('')//调整项
  const [colpredictprice, setColpredictprice] = useState('')//预计收款
  const [integral, setIntegral] = useState(0)//积分
  const [projectIntegral, setProjectIntegral] = useState(0)//积分（以项目为维度从接口中获得）
  const [colrefundPercent, setColrefundPercent] = useState(0)
	const [performance, setPerformance] = useState([])//分成人信息
	const [ogPerformance, setOgperformance] = useState([])//原订单分成人信息
  const [ifOldOrder, setIfOldOrder] = useState(false)
  const [ifXyOrderPrice, setIfXyOrderPrice] = useState(false)//还款市场价是否小于订单市场价
  const [showOrigina, setShowOrigina] = useState(0)

	useEffect(() => {
    //判断是直销还是代理商页面跳转
    if (location.pathname && location.pathname.indexOf('/orderAgent') > -1) {
      orderType = 1
    } else {
      orderType = 0
    }
		if(location.search){
      if(!parseSearch(location.search).ids && !parseSearch(location.search).vendor){
        history.goBack()
        return
      }
      setPageIds(parseSearch(location.search).ids)
      onFortuneOrderBeforePayBackApplie(parseSearch(location.search).ids, parseSearch(location.search).vendor)
		}else{
      history.goBack()
		}
	}, [])
	//获取详情
	const onFortuneOrderBeforePayBackApplie = (ids, vendor) => {
    let params = {ids: ids, type: orderType, vendor}
    if(parseSearch(location.search).show)params.show = parseSearch(location.search).show
		api.onFortuneOrderBeforePayBackApplie(params).then(res => {
			if(JSON.stringify(res) !== "[]"){
        res.available && setAvailable(res.available)
        res.integral && setProjectIntegral(res.integral)
        dealItemData(Object.assign({}, res.order))
			}
			setLoading(false)
		}).catch(() => setLoading(false))
  }
  //处理单个订单数据
  const dealItemData = data => {
    if(data.row){
      const row = data.row
      setRow(row)
      data.vendor && setVendorInfo(data.vendor)
      if((+row.colcost == 0 && +row.colrefund == 0 && +row.colprofit == 0) || (row.colcost == '' && row.colrefund == '' && row.colprofit == '')){
        setIfOldOrder(true)
      }else{
        setIfOldOrder(false)
      }
    }
    if(data.row && data.colInfo){
      if((+data.row.colcost + +data.row.colprofit + +data.row.colrefund).toFixed(2) !== (+data.colInfo.colpredictprice).toFixed(2)){
        Modal.info({
          title: '提示：',
          content: (
            <div>
              <p>由于您的订单 实收 ≠ 成本+调整项+个人利润，您需要选择部分还款调整至正确的还款数据才能还款</p>
            </div>
          ),
          onOk() {}
        })
      }
    }
    data.base && setBase(data.base)
    data.orderInfo && setOrderInfo(data.orderInfo)
    data.client && setClient(data.client)
    data.contractInfo && setContractInfo(data.contractInfo)
    data.performance && setPerformance(data.performance)
    data.originaData && setOgperformance(data.originaData.performance)
    if(data.orderExInfo){
      setOrderExInfo(data.orderExInfo)
      if(data.orderExInfo.attachdata && data.orderExInfo.attachdata.length){
        data.orderExInfo.attachdata.forEach((item, index, self) => {
          self[index] = {
            name: Object.keys(item)[0],
            value: Object.values(item)[0]
          }
        })
        setAttachdata(data.orderExInfo.attachdata)
      }
    }
    setShowOrigina(data.showOrigina)
    data.colInfo && setColInfo(data.colInfo)
    data.originaData && setOriginal(data.originaData)
    data.discountInfo && setDiscountInfo(data.discountInfo)
    if (parseSearch(location.search).pay == 2) {
      onChangePay(data.row.batchFormatData.type, 2, data)
    }
  }

  //还款
  const submitPayBack = () => {
    checkValidate(() => {
      setLoading(true)
      const oArr = [{
        id: row.id,
        colactualprice,
        colrefund,
        colcost,
        colprofit,
        colpredictprice,
        integral: orderType == 0 ? 0 : integral
      }]

      const params = {
        ids: pageIds,
        colmarketprice: colmarketprice,
        colactualprice: colactualprice,
        isAllIn,
        batchOrderData: JSON.stringify(oArr)
      }
      api.onPayBackApplie(params).then(() => {
        setLoading(false)
        message.success('提交成功')
        history.goBack()
      }).catch(() => setLoading(false))
    })
  }
  
  //前端填写校验
  const checkValidate = callback => {
    if(isAllIn === '')return message.warning('请选择全额还款还是部分还款')
    if(colmarketprice === '')return message.warning('请填写还款市场价')
    if(colactualprice === '')return message.warning('请填写实收金额')
    if(colcost === '') return message.warning('请输入正确的成本')
    if(colrefund === '') return message.warning('请输入正确的调整项')
    if(colprofit === '') return message.warning('请输入正确的个人利润')
    if(+colmarketprice > +colInfo.colmarketprice)return message.warning('还款市场价不能大于原订单市场价' + colInfo.colmarketprice)
    
    if(orderType == 1){
      if(integral === '' || integral === null) return message.warning('请输入正确的积分')
      if(+integral > +projectIntegral) return message.warning('积分不能超过可用积分总额')
      if((+colprofit + +colcost + +colrefund).toFixed(4) !== (+colactualprice + +integral).toFixed(4)){
        return message.warning('调整项 + 成本 + 个人利润 应等于 实收 + 积分')
      }
    }else{
      if((+colprofit + +colcost + +colrefund).toFixed(4) !== (+colactualprice).toFixed(4)){
        return message.warning('调整项 + 成本 + 个人利润 应等于 实收')
      }
    }
    callback()
  }
	//刷新余额
	const refreshAcount = () => {
		api.getOrderRefreshBalance({
			type: orderType,
      relationId: orderType == 0 ? row.clientId : row.agentId,
      companyId: contractInfo.accountId ? contractInfo.accountId : row.companyId
		}).then(data => {
			data.available && setAvailable(data.available)
		})
	}

  const onChangePay = (e, from, data) => {
    if (from == 2 && parseSearch(location.search).pay == 2) { // 驳回重还
      setIsAllIn(e)
      if (e === 1) {
        setColmarketpriceDis(true)
        setIfXyOrderPrice(false)
      }
      if (e === 0) {
        setColmarketpriceDis(false)
      }
      if (e === 0) { // 部分还款驳回返显
        const { colrefund, colcost, colprofit, payback_money, colactualprice, integral } = data.row.batchFormatData
        const { colpredictprice } = data.colInfo
        if (orderType == 1) { // 调整项单选
          if (+(colrefund / data.row.colpredictprice).toFixed(2) === 0.06) {
            setColrefundPercent(0.06)
          } else if (+(colrefund / data.row.colpredictprice).toFixed(2) === 0.1) {
            setColrefundPercent(0.1)
          } else {
            setColrefundPercent(0)
          }
        } else {
          setColrefundPercent(0)
        }
        setColmarketprice(+payback_money)  //还款市场价
        setColactualprice(+colactualprice)  //实收
        setColrefund(+colrefund) //调整项
        setColcost(+colcost) //成本
        setColpredictprice(+colpredictprice)  //预计收款
        setColprofit(+colprofit) //个人利润
        orderType == 1 && setIntegral(integral) //积分
      } else { // 全额还款驳回返显
        const { colrefund, colcost, colprofit, integral } = data.row
        const { colmarketprice, colpredictprice } = data.colInfo
        if (orderType == 1) {
          if (+(colrefund / data.row.colpredictprice).toFixed(2) === 0.06) {
            setColrefundPercent(0.06)
          } else if (+(colrefund / data.row.colpredictprice).toFixed(2) === 0.1) {
            setColrefundPercent(0.1)
          } else {
            setColrefundPercent(0)
          }
        } else {
          setColrefundPercent(0)
        }
        setColmarketprice(+colmarketprice)
        setColactualprice(+colpredictprice)
        setColrefund(+colrefund)
        setColcost(+colcost)
        setColpredictprice(+colpredictprice)
        setColprofit(+colprofit)
        orderType == 1 && setIntegral(+integral)
      }
    } else { // 原切换逻辑
      setIsAllIn(e.target.value)
      if (orderType == 1) {
        if (+(row.colrefund / row.colpredictprice).toFixed(2) === 0.06) {
          setColrefundPercent(0.06)
        } else if (+(row.colrefund / row.colpredictprice).toFixed(2) === 0.1) {
          setColrefundPercent(0.1)
        } else {
          setColrefundPercent(0)
        }
      } else {
        setColrefundPercent(0)
      }
      setColmarketprice(+colInfo.colmarketprice)
      setColactualprice(+colInfo.colpredictprice)
      setColrefund(+row.colrefund)
      setColcost(+row.colcost)
      setColpredictprice(+colInfo.colpredictprice)
      setColprofit(+row.colprofit)
      orderType == 1 && setIntegral(0)
      if (e.target.value === 1) {
        setColmarketpriceDis(true)
        setIfXyOrderPrice(false)
      }
      if (e.target.value === 0) {
        setColmarketpriceDis(false)
      }
    }
  }

	return (
		<Spin spinning={loading}>
			<div className="add-orderDirect-detail">
        <Descriptions title='还款申请-信用收款' layout="vertical" className="pro-detail-title" column={5} style={{borderBottom: "none"}}>
          <dItem label="客户名称">{vendorInfo.client}</dItem>
          <dItem label="OAID">{vendorInfo.clientId}</dItem>
          {base.orderType && !Array.isArray(base.orderType) && <dItem label="类型">{base.orderType.name}</dItem>}
          <dItem label="厂商ID">{vendorInfo.vendor}</dItem>
          {orderType == 0 && <dItem label="所属销售">{vendorInfo.relSalerName}</dItem>}
          {orderType == 0 && <dItem label="所属客服">{vendorInfo.relServicerName}</dItem>}
          {orderType == 1 && <dItem label="所属渠道开发">{vendorInfo.relAgentStaffName}</dItem>}
          <dItem label="订单编号">{base.number}</dItem>
        </Descriptions>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">还款</div>
            <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              <dItem label="可用余额">
                <div className="dItem-txt-box">
                ￥ {available && available.balance}
                <Link target="_blank" to={`/fund/moneyClaim/detail?clientId=${orderType == 0 ? vendorInfo.clientId : vendorInfo.agentId}&clientType=${orderType}`}> 认款</Link>
								<span onClick={refreshAcount} style={{color: '#1890ff', cursor: 'pointer', marginLeft: '5px'}}>刷新余额</span>
                </div>
              </dItem>
              {orderType == 1 && <dItem label="可用积分">
                <div className="dItem-txt-box">
                ￥ {projectIntegral && projectIntegral}
                <Link target="_blank" to={`/agent/integralManage/add/0?relationId=${vendorInfo.agentId}&bizType=0`}> 充积分</Link>
                </div>
              </dItem>}
              </Descriptions>
              <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              <dItem className="radio-in-label" label={<>
                <span className="required-label">*</span> 还款市场价 
                <Radio.Group style={{width: '210px', marginLeft: '5px'}} value={isAllIn} onChange={(e) => onChangePay(e,1)}>
                  <Radio value={1} style={{marginLeft: -4}}>
                    <Tooltip title="还款市场价与实收读取订单提交时填写的数据，还款市场价=市场价，实收=预计收款，不可修改">全额还款</Tooltip>
                  </Radio>
                  <Radio value={0} style={{marginLeft: -12}}>
                    <Tooltip title={<>
                      <div> 1. 若填写的还款市场价=订单市场价，在填写实收后，该订单可直接负利收款（实收/市场&lt;折扣时，触发负利还款审批的折扣申请，实收/市场&gt;=折扣时，不触发触发负利还款审批的折扣申请）；</div>
                      <div> 2. 若填写的还款市场价&lt;订单市场价，该订单会被拆分，实收、调整项、成本、个人利润按照拆分比例自动计算且不可修改，本订单可走负利还款流程，未还款部分生成新的负利订单；</div>
                    </>}>部分还款</Tooltip>
                  </Radio>
                </Radio.Group>
              </>}>
                <InputNumber
                  disabled={colmarketpriceDis}
                  step={0.01}
                  min={0}
                  style={{width: '210px'}}
                  placeholder="请输入"
                  value={colmarketprice}
                  onChange={val => {
                    if(+val > +colInfo.colmarketprice){
                      return message.info('还款市场价不能大于订单市场价')
                    }
                    if(+val <= +colInfo.colmarketprice){
                      let per = +val / +colInfo.colmarketprice
                      // let perOrigina = +val / +original.colmarketprice
                      setIfXyOrderPrice((+val == +colInfo.colmarketprice) || (+colInfo.colcost == 0 && +colInfo.colprofit == 0 && +colInfo.colrefund == 0) ? false : true)
                      let a = (colInfo.colpredictprice * per).toFixed(2)
                      let b = (row.colrefund * per).toFixed(2)
                      let c = (row.colprofit * per).toFixed(2)
                      setColactualprice(a) // 实收
                      setColrefund(b) // 调整项
                      setColprofit(c) // 个人利润
                      setColcost((a - b - c).toFixed(2)) // 成本
                      orderType == 1 && setColrefundPercent((b/a).toFixed(2)) // 调整项单选
                      // setColpredictprice((original.colpredictprice * perOrigina).toFixed(2))
                      orderType == 1 && setIntegral(0)
                    }
                    setColmarketprice(val)
                  }}
								/>
                <div>正确的格式为：10.00</div>
              </dItem>
              <dItem
                label={<><span className="required-label">*</span> 实收</>}
              >
                <InputNumber
                  disabled={colmarketpriceDis}
                  step={0.01}
                  min={0}
                  style={{width: '210px'}}
                  placeholder={colmarketpriceDis ? '请选择是否全额还款' : '请输入'}
                  value={colactualprice}
                  onChange={val => {
                    setColactualprice((+val).toFixed(2))
                    orderType == 1 && colrefundPercent && !ifOldOrder && setColrefund(toNumber((+val * colrefundPercent).toFixed(2)))
                  }}
								/>
                <div>正确的格式为：10.00</div>
              </dItem>
              {orderType == 1 && <dItem label={<><span className="required-label">*</span> 积分</>}>
                <InputNumber
                  disabled={isAllIn !== 0}
                  step={1}
                  min={0}
                  style={{width: '210px'}}
                  placeholder="请输入"
                  value={integral}
                  onChange={val => {
                    const reg = /^\d+$/
                    if (val && !reg.test(val)) {
                      // message.error('积分请输入0或正整数')
                      setIntegral(0)
                    } else {
                      setIntegral(+val)
                    }
                    // (+colmarketprice !== +colInfo.colmarketprice || colmarketpriceDis) && !ifOldOrder && setColactualprice((+colcost + +colrefund + +colprofit - val).toFixed(2))
                    // if( (+colmarketprice !== +colInfo.colmarketprice || colmarketpriceDis) && !ifOldOrder ) {
                    //   setColactualprice(toNumber((+colcost + +colrefund + +colprofit - val).toFixed(2)))
                    // }
                  }}
								/>
                <div>正确的格式为：0或正整数</div>
              </dItem>}
            {colactualprice && colmarketprice && <dItem label="折扣">{
              orderType == 1 ? toNumberPoint((+colactualprice + +integral) / colmarketprice) : toNumberPoint(colactualprice / colmarketprice)
            }
            </dItem>}
              </Descriptions>
              <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              <dItem label={<><span className="required-label">*</span> 成本</>}>
                <InputNumber
                  // disabled={(isAllIn !== 0 && !ifOldOrder) || ifXyOrderPrice}
                  disabled={(isAllIn !== 0)}
                  step={0.01}
                  style={{width: '210px'}}
                  placeholder="请输入"
                  value={colcost}
                  onChange={val => setColcost((+val).toFixed(2))}
								/>
                <div>正确的格式为：10.00</div>
              </dItem>
              <dItem label={orderType == 0 ? <><span className="required-label">*</span> 调整项</> : 
								<><span className="required-label">*</span> 调整项&nbsp;
									<Radio.Group
                    // disabled={!colactualprice || (isAllIn !== 0 && !ifOldOrder) || ifXyOrderPrice}
                    disabled={!colactualprice || (isAllIn !== 0)}
                    value={colrefundPercent == 0.06 ? 0 : colrefundPercent == 0.1 ? 1 : 2}
                    style={{width: '180px'}}
                    onChange={e => {
                      if(e.target.value == 0){
                        setColrefund((colactualprice * 0.06).toFixed(2))
                        setColrefundPercent(0.06)
                      }
                      if(e.target.value == 1){
                        setColrefund((colactualprice * 0.10).toFixed(2))
                        setColrefundPercent(0.1)
                      }
                      if(e.target.value == 2){
                        setColrefund(0)
                        setColrefundPercent(0)
                      }
                    }}
									>
										<Radio value={0}>6%</Radio>
										<Radio value={1}>10%</Radio>
										<Radio value={2}>其它</Radio>
									</Radio.Group>
								</>
								}>
                  <InputNumber
                    disabled={(!colactualprice && colrefundPercent !== 0) || colrefundPercent == 0.06 || colrefundPercent == 0.1 || (isAllIn !== 0)}
                    step={0.01}
                    style={{ width: '210px' }}
                    placeholder="请输入"
                    value={colrefund}
                    onChange={val => setColrefund((+val).toFixed(2))}
                  />
                  <div>正确的格式为：10.00</div>
							</dItem>
              <dItem label={<><span className="required-label">*</span> 个人利润</>}>
                <InputNumber
                  // disabled={(isAllIn !== 0 && !ifOldOrder) || ifXyOrderPrice}
                  disabled={(isAllIn !== 0)}
                  step={0.01}
                  style={{width: '210px'}}
                  placeholder="请输入"
                  value={colprofit}
                  onChange={val => setColprofit((+val).toFixed(2))}
								/>
                <div>正确的格式为：10.00</div>
              </dItem>
              <dItem label={<>预计收款</>}>
                <InputNumber
                  disabled={true}
                  step={0.01}
                  style={{width: '210px'}}
                  placeholder="请输入"
                  value={colpredictprice}
                  // onChange={val => setColpredictprice((+val).toFixed(2))}
								/>
              </dItem>
            </Descriptions>
            <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              <dItem label="提单人">{colInfo.userName}</dItem>
              <dItem label="直接主管">{colInfo.userLeader}</dItem>
              <dItem label="部门">{colInfo.userDepart}</dItem>
            </Descriptions>
            {performance.length > 0 && <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none", marginBottom: "-30px"}}>
              <dItem label="分成人"></dItem>
              <dItem label="分成人业绩"></dItem>
              <dItem label="分成人直接主管"></dItem>
              <dItem label="分成人部门"></dItem>
            </Descriptions>}
            {performance.map((item, index) => (<Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{borderBottom: "none", marginBottom: "-10px"}}>
              <dItem label="">{item.stfName}</dItem>
              {/* <dItem label="">{item.performance}</dItem> */}
              <dItem label="">{toNumber(item.performance / +row.colprofit * +colprofit).toFixed(2)}</dItem>
              <dItem label="">{item.superior}</dItem>
              <dItem label="">{item.department}</dItem>
            </Descriptions>))}
        </div>
        {
          showOrigina === 1 && <>
            <div className="blank-line"></div>
            <div className="ask-info">
              <div className="record-list-title">原订单流水与分成信息</div>
              {original ? <><Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none" }}>
                <dItem label="市场价">{original.colmarketprice}</dItem>
                <dItem label="预计收款">{original.colpredictprice}</dItem>
                <dItem label="折扣">{+original.colmarketprice === 0 ? '纯利' : original.colrebate}</dItem>
                <dItem label="个人利润">{original.colprofit}</dItem>
                <dItem label="成本">{original.colcost}</dItem>
                <dItem label="调整项">{original.colrefund}</dItem>
              </Descriptions>
                <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none" }}>
                  <dItem label="提单人">{original.userName}</dItem>
                  <dItem label="直接主管">{original.userLeaderName}</dItem>
                  <dItem label="部门">{original.userDeptName}</dItem>
                </Descriptions></> : '-'}
              {ogPerformance.length > 0 && <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none", marginBottom: "-30px" }}>
                <dItem label="分成人"></dItem>
                <dItem label="分成人业绩"></dItem>
                <dItem label="分成人直接主管"></dItem>
                <dItem label="分成人部门"></dItem>
              </Descriptions>}
              {ogPerformance.map((item, index) => (<Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{ borderBottom: "none", marginBottom: "-10px" }}>
                <dItem label="">{item.stfName}</dItem>
                <dItem label="">{item.performance}</dItem>
                <dItem label="">{item.superior}</dItem>
                <dItem label="">{item.department}</dItem>
              </Descriptions>))}
            </div>
          </>
        }
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单收款信息-{base.coltype}</div>
					{colInfo ? <><Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="市场价">{colInfo.colmarketprice}</dItem>
						<dItem label="预计收款">{colInfo.colpredictprice}</dItem>
						<dItem label="折扣">{+colInfo.colmarketprice === 0 ? '纯利': colInfo.colrebate}</dItem>
						<dItem label="个人利润">{colInfo.colprofit}</dItem>
						<dItem label="成本">{colInfo.colcost}</dItem>
						<dItem label="调整项">{colInfo.colrefund}</dItem>
          </Descriptions>
          <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="提单人">{colInfo.userName}</dItem>
						<dItem label="直接主管">{colInfo.userLeader}</dItem>
						<dItem label="部门">{colInfo.userDepart}</dItem>
					</Descriptions></> : '-'}
          {performance.length > 0 && <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none", marginBottom: "-30px"}}>
            <dItem label="分成人"></dItem>
            <dItem label="分成人业绩"></dItem>
            <dItem label="分成人直接主管"></dItem>
            <dItem label="分成人部门"></dItem>
          </Descriptions>}
          {performance.map((item, index) => (<Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{borderBottom: "none", marginBottom: "-10px"}}>
            <dItem label="">{item.stfName}</dItem>
            <dItem label="">{item.performance}</dItem>
            <dItem label="">{item.superior}</dItem>
            <dItem label="">{item.department}</dItem>
          </Descriptions>))}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div style={{ color: 'red', fontWeight: 800 }}>备注</div>
          <div style={{ color: 'red', fontWeight: 800, marginTop: '20px' }}>{orderInfo ? orderInfo.remark : '-'}</div>
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单信息</div>
					{orderInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="项目">{orderInfo.projectName}</dItem>
						<dItem label="产品">{orderInfo.productName}</dItem>
						<dItem label="业务类型">{orderInfo.businessName}</dItem>
						<dItem label="业绩类型">{orderInfo.figtype}</dItem>
						{/* {orderType == 1 && <dItem label="是否撤资重开订单">{row.isodfdivest ? '是' : '否'}</dItem>} */}
						{orderType == 0 && <dItem label="是否退转订单">{+row.isodftrun ? '是' : '否'}</dItem>}
						{orderType == 0 && <dItem label="是否框架订单">{row.isodfkjia ? '是' : '否'}</dItem>}
						{orderType == 0 && <dItem label="销售主项">{row.majorminor == 1 ? '是' : '否'}</dItem>}
					</Descriptions> : '-'}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单资料</div>
					{orderExInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="公司名称">{orderExInfo.companyName}</dItem>
						<dItem label="一级行业">{orderExInfo.industry1Name}</dItem>
						<dItem label="二级行业">{orderExInfo.industry2Name}</dItem>
						<dItem label="供应商">{orderExInfo.supplierName}</dItem>
            {
              attachdata.map(((item, index) => <dItem label={item.name} key={+index+4}>{item.value}</dItem>))
            }
					</Descriptions> : '-'}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">折扣信息</div>
					{discountInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="客户名称">
              <div>{discountInfo.clientName}</div>
              <div>ID: {client.id}</div>
            </dItem>
            <dItem label="项目">{discountInfo.projectName}</dItem>
            <dItem label="产品">{discountInfo.productName}</dItem>
            <dItem label="一级行业">{discountInfo.industry1}</dItem>
            <dItem label="二级行业">{discountInfo.industry2}</dItem>
            <dItem label="折扣">{colInfo && +colInfo.colmarketprice === 0 ? '纯利' : discountInfo.colrebate}</dItem>
            <dItem label="供应商">
              <div>{discountInfo.supplier}</div>
              <div>ID: {orderExInfo.supplier}</div>
            </dItem>
            <dItem label="折扣类型">{discountInfo.discountType}</dItem>
					</Descriptions> : '-'}
        </div>
				<div className="blank-line"></div>
				<div className="ask-info" style={{marginBottom: 0, marginTop: '10px'}}>
					<BtnGroup onCancel={() => {history.go(-1)}} onConfirm={submitPayBack} confirmName = '提交' right="to-right" />
				</div>
			</div>
		</Spin>
	)
}

export default Payback