/**
 * 模块名称: 退单编辑
 * @author lids@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
	Descriptions,
  Spin,
  InputNumber,
  message,
  Tag,
  Icon,
  Tooltip,
  Input
} from 'antd'
import BtnGroup from '@/components/BtnGroup'
import { toNumberPoint } from '@/utils'

const dItem = Descriptions.item
const { CheckableTag } = Tag
let ordersJson = []
let orderType = ''

const Refund = (props) => {

  const { history, location } = props

  const [row, setRow] = useState({})
  const [base, setBase] = useState({})//基础信息
  const [orderNumber, setOrderNumber] = useState([])
	const [orderInfo, setOrderInfo] = useState({})//订单信息
	const [attachdata, setAttachdata] = useState([])//订单扩展资料字段信息
	const [orderExInfo, setOrderExInfo] = useState({})//订单资料
	const [colInfo, setColInfo] = useState({})//收款信息
	const [performance, setPerformance] = useState([])//分成人信息
  const [pageIds, setPageIds] = useState('')
  const [loading, setLoading] = useState(true)
  const [colprofit, setColprofit] = useState('')//个人利润
  const [integral, setIntegral] = useState('')//积分
  const [colcost, setColcost] = useState('')//成本
  const [colrefund, setColrefund] = useState('')//调整项
  const [remark, setRemark] = useState('') // 备注
  const [vendor, setVendor] = useState(null)
  const [currentId, setCurrenId] = useState(null)
  const [systemRefund, setSystemRefund] = useState('')
  const [clientRefund, setClientRefund] = useState('')
  const [chargebackMarketPrice, setChargebackMarketPrice] = useState('')
  const [visitedIds, setVisitedIds] = useState([])//记录查询过的id，如果查询过一次，就不要再查询了
  const [saveLoading, setSaveLoading] = useState(false)

	useEffect(() => {
    // console.log(location.state)
    //判断是直销还是代理商页面跳转
    if (location.pathname && location.pathname.indexOf('/orderAgent') > -1) {
      orderType = 1
    } else {
      orderType = 0
    }
    ordersJson = []
    if(location.state && location.state.id){
      firstGetDetail(location.state.id)
    }else{
      history.goBack()
    }
	}, [])
	//获取详情（实际是获取了订单列表，然后再根据每个订单列表查询单个订单详情）
	const getBackList = list => {
    let on = list.map(item => ({...item, checked: false, taped: false}))
    let json = {
      colprofit: '', 
      colcost: '', 
      colrefund: '', 
      clientRefund: '', 
      systemRefund: '',
      chargebackMarketPrice: ''
    }
    ordersJson = list.map(item => {
      return orderType == 0 ? {
        order_id: item.id, 
        orderNumber: item.number, 
        ...json
      } : {
        order_id: item.id, 
        orderNumber: item.number, 
        integral: item.colstatus === "未收款" ? 0 : '',
        ...json
      }
    })
    on[0].checked = true
    on[0].taped = true
    setOrderNumber(on)//默认请求第0个
    setCurrenId(on[0].id)
  }
  //第一次进来先请求一下detail接口，先获取一下订单列表，订单id，订单编号，厂商ID
  //因为不能保证在多个订单退单的时候，哪个是第一个
  const firstGetDetail = (id) => {
    api.getFortuneOrderRefundOrderDetail({ id: id, type: orderType, audit: 0 }, true).then(data => {
      setRemark(data.orderInfo.remark)
      if(data.batchOrders && data.batchOrders.list){
        if(data.batchOrders.list.length || !data.vendor){
          let arr = data.batchOrders.list.reduce((prev, curr) => [...prev, curr.id], [])
          setPageIds(arr)
          setVendor(data.vendor)
          getBackList(data.batchOrders.list)
          getSingleDetail(arr[0])
        }else{
          message.warning('数据错误')
          history.goBack()
        }
      }
    })
  }
  //获取单个订单的详情
  const getSingleDetail = (id) => {
    let params = { id, type: orderType, audit: 0 }
    setLoading(true)
    api.getFortuneOrderRefundOrderDetail(params, true).then(res => {
      if(JSON.stringify(res) !== "[]"){
        let data = Object.assign({}, res)
        let orderIndex = 0
        let arr = data.batchOrders.list.reduce((prev, curr) => [...prev, curr.id], [])
        arr.forEach((item, index) => {
          if(item == id) orderIndex = index
        })
        data.base && setBase(data.base)
        if(data.row){
          const row = data.row
          setRow(row)
          if(!visitedIds.includes(id)){
            visitedIds.push(id)
            setVisitedIds(visitedIds)
            setColrefund(+row.colrefund)
            setColcost(+row.colcost)
            setColprofit(+row.colprofit)
            orderType == 1 && setIntegral(+row.integral)
  
            if(row.batchData){
              const bData = JSON.parse(row.batchData)
              setClientRefund(+bData.refund)
              setSystemRefund(+bData.systemRefund)
              setChargebackMarketPrice(+bData.chargebackMarketPrice)
  
              ordersJson[orderIndex].clientRefund = +bData.refund
              ordersJson[orderIndex].systemRefund = +bData.systemRefund
              ordersJson[orderIndex].chargebackMarketPrice = +bData.chargebackMarketPrice
            }
          }
        }
        data.orderInfo && setOrderInfo(data.orderInfo)
        if(data.orderExInfo){
          setOrderExInfo(data.orderExInfo)
          if(data.orderExInfo.attachdata && data.orderExInfo.attachdata.length){
            data.orderExInfo.attachdata.forEach((item, index, self) => {
              self[index] = {
                name: Object.keys(item)[0],
                value: Object.values(item)[0]
              }
            })
            setAttachdata(data.orderExInfo.attachdata)
          }
        }
        data.colInfo && setColInfo(data.colInfo)
        data.performance && setPerformance(data.performance)
      }else{
        setBase({})
        setOrderInfo({})
        setOrderExInfo({})
        setColInfo({})
        setPerformance([])
      }
      setLoading(false)
    }).catch(() => setLoading(false)) 
  }
  //切换时校验
  const checkOrder = (foo) => {
    checkValidate(() => foo && foo())
  }
  //还款
  const submitPayBack = () => {
    checkValidate(() => {
      ordersJson = ordersJson.map(item => {
        if(item.order_id == currentId){
          item.colprofit = colprofit
          if(orderType == 1) item.integral = integral
          item.colcost = colcost
          item.colrefund = colrefund
          item.clientRefund = clientRefund
          item.systemRefund = systemRefund
          item.chargebackMarketPrice = chargebackMarketPrice
          return item
        }
        return item
      })
      let errNumbers = {}, errArr = []
      ordersJson.forEach(item => {
        errNumbers[item.orderNumber] = true
        if(item.colprofit === '' || item.colcost === '' || item.colrefund === ''){
          errNumbers[item.orderNumber] = false
        }
        if(item.integral === '' && orderType == 1){
          errNumbers[item.orderNumber] = false
        }
      })
      let oArr = JSON.parse(JSON.stringify(ordersJson))
      for(let i = 0, len = oArr.length; i < len; i++){
        delete oArr[i].orderNumber
      }
      let tapedArr = []
      orderNumber.forEach(item => {
        if(!item.taped){
          tapedArr.push(item.id)
        }
      })
      if(tapedArr.length) return message.warning('您有未查看的订单')
      let params = {
        vendor: vendor,
        orders: JSON.stringify(oArr),
        type: orderType,
        edit: 1,
        remark
      }
      for(let [key, value] of Object.entries(errNumbers)){
        if(!value)errArr.push(key)
      }

      if (!errArr.length) {
        setSaveLoading(true)
        api.getFortuneOrderRefundApplie(params).then(() => {
          message.success('提交成功')
          history.goBack()
          setSaveLoading(false)
        }).catch(() => setSaveLoading(false))
      } else {
        message.warning(`订单号${errArr.join(',')} 信息填写有误，请检查`)
      }
    })
  }

  const getCheckedId = (data) => {
    if(data.id == currentId)return//点击为当前订单，return
    checkOrder(() => {
      // console.log(data, currentId, ordersJson)
      ordersJson.forEach(item => {
        if(item.order_id == data.id){
          setColprofit(item.colprofit)
          orderType == 1 && setIntegral(item.integral)
          setColcost(item.colcost)
          setColrefund(item.colrefund)
          setClientRefund(item.clientRefund)
          setSystemRefund(item.systemRefund)
          setChargebackMarketPrice(item.chargebackMarketPrice)
        }
      })
      ordersJson = ordersJson.map(item => {
        if(item.order_id == currentId){
          item.colprofit = colprofit
          if(orderType == 1) item.integral = integral
          item.colcost = colcost
          item.colrefund = colrefund
          item.clientRefund = clientRefund
          item.systemRefund = systemRefund
          item.chargebackMarketPrice = chargebackMarketPrice
          return item
        }
        return item
      })
      // console.log(ordersJson)
      setCurrenId(data.id)
      let d = [...orderNumber]
      for(let i = 0; i<d.length; i++){
        d[i].checked = false
        if(d[i].id == data.id){
          d[i].checked = true
          d[i].taped = true
        }
      }
      setOrderNumber(d)
      getSingleDetail(data.id)
    })
  }
  //前端填写校验
  const checkValidate = callback => {
    if(row.colstatus == 1){
      if(orderType == 1 && (integral === '' || integral < 0)) return message.warning('请输入正确的积分')
      if(colcost === '' || colcost < 0) return message.warning('请输入正确的成本')
      if(colrefund === '' || colrefund < 0) return message.warning('请输入正确的调整项')
      if(colprofit === '') return message.warning('请输入正确的退个人利润')
      let txt = row.colstatus == 1 ? '实退客户金额' : '实退预计收款'
      let cdPrice = row.colstatus == 1 ? clientRefund : colInfo.colpredictprice
      if(row.type == 0){
        if(row.colstatus == 1 && (colprofit + colcost + colrefund).toFixed(2) !== (+cdPrice).toFixed(2))return message.warning('个人利润 + 成本 + 调整项 应等于 ' + txt)
      }else{
        if(row.colstatus == 1 && (colprofit + colcost + colrefund).toFixed(2) !== (+cdPrice + +integral).toFixed(2))return message.warning('个人利润 + 成本 + 调整项 应等于 ' + txt + ' + 积分')
      }
    }
    callback()
  }

	return (
		<Spin spinning={loading}>
			<div className="add-orderDirect-detail">
        <Descriptions title={`退单申请-驳回重提`} layout="vertical" className="pro-detail-title" column={5} style={{borderBottom: "none"}}>
          {/* {base.orderType && !Array.isArray(base.orderType) && <dItem label="类型">{base.orderType.name}</dItem>} */}
        </Descriptions>
        {orderNumber.length > 0 && <>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">退款订单</div>
          <Descriptions layout="horizontal" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
            {
              orderNumber.map(item => 
              <dItem label="退单编号" key={item.id}><CheckableTag color="blue" checked={item.checked} onChange={() => getCheckedId(item)}>
                {!item.taped ? <Tooltip title="该订单还未查看"><Icon type="exclamation-circle" style={{color: '#ff4d4f', fontSize: 14}} fill="currentColor" /> {item.number}</Tooltip> : item.number}</CheckableTag>
              </dItem>)
            }
          </Descriptions>
        </div>
        </>}
        <div className="blank-line"></div> 
        <div className="ask-info">
          <div className="record-list-title">退单信息</div>
            <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              <dItem label="系统实退金额">{(+systemRefund).toFixed(2)}</dItem>
              {base.coltypeId == 2 && <dItem label="实退预计收款">{clientRefund}</dItem>}
              {
                row.colstatus == 1 ? <dItem label={<><span className="required-label">*</span> 实退客户金额</>}>
                  <Input 
                    placeholder="请输入实退客户金额" 
                    value={clientRefund} 
                    onChange={e => {
                      e.persist()
                      setClientRefund(e.target.value)
                    }}
                  />
                </dItem> :
                <dItem label="实退信用额度">{(+systemRefund).toFixed(2)}</dItem>
              }
              {orderType == 1 && row.colstatus == 1 && <dItem label={<><span className="required-label">*</span> 退积分</>}>
                <InputNumber
                  step={1}
                  min={0}
                  style={{width: '171px'}}
                  placeholder="请输入"
                  value={integral}
                  onChange={val => {
                    const reg = /^\d+$/
                    if (val && !reg.test(val)) {
                      // message.error('积分请输入0或正整数')
                      setIntegral(0)
                    } else {
                      setIntegral(+val)
                    }
                  }}
								/>
                <div>正确的格式为：0或正整数</div>
              </dItem>}
              {/* <dItem label="折扣">{colInfo.colrebate}</dItem> */}
              <dItem label="折扣">{
                (orderType == 1 && row.colstatus == 1) ? toNumberPoint((+clientRefund + +integral) / systemRefund) : colInfo.colrebate}
              </dItem>
            </Descriptions>
            {row.colstatus == 1 && <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              <dItem label={<><span className="required-label">*</span> 退成本</>}>
                <InputNumber
                  step={0.01}
                  min={0}
                  style={{width: '171px'}}
                  placeholder="请输入"
                  value={colcost}
                  onChange={val => setColcost(val)}
								/>
                <div>正确的格式为：10.00</div>
              </dItem>
              <dItem label={<><span className="required-label">*</span> 退调整项</>}>
                <InputNumber
                  step={0.01}
                  min={0}
                  style={{width: '171px'}}
                  placeholder="请输入"
                  value={colrefund}
                  onChange={val => setColrefund(val)}
								/>
                <div>正确的格式为：10.00</div>
              </dItem>
              <dItem label={<><span className="required-label">*</span> 退个人利润</>}>
                <InputNumber
                  step={0.01}
                  // min={0}
                  style={{width: '171px'}}
                  placeholder="请输入"
                  value={colprofit}
                  onChange={val => setColprofit(val)}
								/>
                <div>正确的格式为：10.00</div>
              </dItem>
            </Descriptions>}
            <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              <dItem label="提单人">{colInfo.userName}</dItem>
              <dItem label="直接主管">{colInfo.userLeader}</dItem>
              <dItem label="部门">{colInfo.userDepart}</dItem>
            </Descriptions>
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单收款信息-{base.coltype}</div>
					{colInfo ? <>
            <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              <dItem label="收款类型">{base.coltype}</dItem>
              <dItem label="市场价">{colInfo.colmarketprice}</dItem>
              <dItem label="实收">{colInfo.colactualprice}</dItem>
              <dItem label="预计收款">{colInfo.colpredictprice}</dItem>
              <dItem label="折扣">{colInfo.colrebate}</dItem>
            </Descriptions>
            <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              {orderType == 1 && <dItem label="积分">{colInfo.integral}</dItem>}
              <dItem label="成本">{colInfo.colcost}</dItem>
              <dItem label="调整项">{colInfo.colrefund}</dItem>
              <dItem label="个人利润">{colInfo.colprofit}</dItem>
            </Descriptions>
            <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              <dItem label="提单人">{colInfo.userName}</dItem>
              <dItem label="直接主管">{colInfo.userLeader}</dItem>
              <dItem label="部门">{colInfo.userDepart}</dItem>
            </Descriptions>
            {performance.length > 0 && <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none", marginBottom: "-30px"}}>
              <dItem label="分成人"></dItem>
              <dItem label="分成人业绩"></dItem>
              <dItem label="分成人直接主管"></dItem>
              <dItem label="分成人部门"></dItem>
            </Descriptions>}
            {performance.map((item, index) => (<Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{borderBottom: "none", marginBottom: "-10px"}}>
              <dItem label="">{item.stfName}</dItem>
              <dItem label="">{item.performance}</dItem>
              <dItem label="">{item.superior}</dItem>
              <dItem label="">{item.department}</dItem>
            </Descriptions>))}
          </> : '-'}
        </div>
				<div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单信息</div>
					{orderInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="项目">{orderInfo.projectName}</dItem>
						<dItem label="产品">{orderInfo.productName}</dItem>
						<dItem label="业务类型">{orderInfo.businessName}</dItem>
						<dItem label="业绩类型">{orderInfo.figtype}</dItem>
						<dItem label="代理商名称">{orderInfo.agentName}</dItem>
						{/* {orderType == 1 && <dItem label="是否撤资重开订单">{row.isodfdivest ? '是' : '否'}</dItem>} */}
						{orderType == 0 && <dItem label="是否退转订单">{+row.isodftrun ? '是' : '否'}</dItem>}
						{orderType == 0 && <dItem label="是否框架订单">{row.isodfkjia ? '是' : '否'}</dItem>}
						{orderType == 0 && <dItem label="销售主项">{row.majorminor == 1 ? '是' : '否'}</dItem>}
					</Descriptions> : '-'}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单资料</div>
					{orderExInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="公司名称">{orderExInfo.companyName}</dItem>
						<dItem label="一级行业">{orderExInfo.industry1Name}</dItem>
						<dItem label="二级行业">{orderExInfo.industry2Name}</dItem>
						<dItem label="供应商">{orderExInfo.supplierName}</dItem>
            {
              attachdata.map(((item, index) => <dItem label={item.name} key={+index + 4}>{item.value}</dItem>))
            }
					</Descriptions> : '-'}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">退单备注</div>
          <Input.TextArea 
            placeholder="请输入备注" 
            maxLength={3000} 
            value={remark} 
						style={{minWidth: '900px', minHeight: '5em'}}
            onChange={e => {
              setRemark(e.target.value)
          }} />
        </div>
        <div className="blank-line"></div>
        <div style={{height: 30, backgroundColor: 'white'}}></div>
				<div className="ask-info submit-btn-box">
					<BtnGroup onCancel={() => {history.go(-1)}} onConfirm={submitPayBack} confirmName = '提交' right="to-right" loading={saveLoading} />
				</div>
			</div>
		</Spin>
	)
}

export default Refund