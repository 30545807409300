/**
 * 模块名称: 直销/代理商开单 还包括续费开单，编辑
 * @author lids@372163.com
 */
import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import { Link } from 'react-router-dom'
import {
	Descriptions,
	Button,
	Modal,
	Input,
	Select,
	message,
	Spin,
	Form,
	Table,
	Row,
	Col,
	Radio,
	InputNumber,
	Upload,
	Icon
} from 'antd'
import { parseSearch, toNumber } from '@/utils'
import { upLoadModule } from '@/utils/common'
import BtnGroup from '@/components/BtnGroup'
import { useSelector } from 'react-redux'
import CryptoJS from 'crypto-js'

const FormItem = Form.Item
const dItem = Descriptions.item
const Option = Select.Option
const { Column } = Table
const { Dragger } = Upload
const { confirm } = Modal
let orderPageType = ''
let idNum = 0
let editeleaderName = []
let editeDepartment = []

const Apply = (props) => {

	const { history, location } = props
	const { getFieldDecorator, validateFields, setFieldsValue, getFieldValue } = props.form

	const [vendorId, setVendorId] = useState(null)//厂商id
	const [pageId, setPageId] = useState(null)//订单id

	const [detail, setDetail] = useState({})//全部数据
	const [client, setClient] = useState({})//客户OAID信息
	const [contractInfo, setContractInfo] = useState('')//合同信息
	const [oderDetail, setOrderDetail] = useState({})//订单详情（detail）
	const [rowData, setRowData] = useState({})//编辑时订单信息row
	const [vendor, setVendor] = useState({})//厂商ID信息
	const [credit, setCredit] = useState({})//信用额度
	const [orderInfo, setOrderInfo] = useState({})
	const [loading, setLoading] = useState(true)
	const [relationId, setRelationId] = useState('')
	const [staffProjects, setStaffProjects] = useState([])

	const [contactPersonList, setContactPersonList] = useState([])
	const [contactnameVisible, setContactnameVisible] = useState(false)
	const [selContactPerson, setSelContactPerson] = useState('') //选中的手机联系人保存
	const [selContactPersonId, setSelContactPersonId] = useState('')
	const [projectId, setProjectId] = useState('')
	const [projectName, setProjectName] = useState('')
	const [productId, setProductId] = useState('')
	const [productName, setProductName] = useState('')
	const [businessId, setBusinessId] = useState('')
	const [attachdataList, setAttachdataList] = useState([])//自定义字段列表
	const [productList, setProductList] = useState([])
	const [businessList, setBusinessList] = useState([])
	const [industryList, setIndustryList] = useState([])
	const [industryList2, setIndustryList2] = useState([])
	const [supplierList, setSupplierList] = useState([])
	const [supplierName, setSupplierName] = useState('')
	const [supplierId, setSupplierId] = useState('')
	const [industryName, setIndustryName] = useState('')
	const [industryName2, setIndustryName2] = useState('')
	const [attachList, setAttachList] = useState([])
	const [fileList, setFileList] = useState([])
	const [colTypeSel, setColTypeSel] = useState('')
	const [colrebate, setColrebate] = useState(null)
	const [available, setAvailable] = useState({})
	// const [productIds, setProductIds] = useState([])
	const [integral, setIntegral] = useState(0)
	const draggerRef = useRef(null)
	const userInfo = useSelector(state => state.userInfo)
	const [supplierCompanyList, setSupplierCompanyList] = useState([])
	const [initCompId, setInitCompId] = useState(null)//默认提单公司
	const [vendorContractEmpty, setVendorContractEmpty] = useState(Boolean)//当续费时，返回的合同信息是否为空
	const [vendorContractEmptys, setVendorContractEmptys] = useState(false)//判断是否有合同信息的存在
	const [newAdd, setNewAdd] = useState(true)
	const [perfStfName, setPerfStfName] = useState('')
	const [colrefundRadio, setColrefundRadio] = useState(0)//获取调整项radio
	const [contractNoChecked, setContractNoChecked] = useState('')
	const [ifEditBh, setIfEditBh] = useState(false)//判断是否驳回
	const [performance, setPerformance] = useState([])//分成人信息
	const [submitDis, setSubmitDis] = useState(false)//当个人利润聚焦时，不让点
	const [renSubmitDis, setRenSubmitDis] = useState(false)
	const [isColprofit, setIsColprofit] = useState(false)//判断个人利润是否禁用
	const [colrebateVal, setColrebateVal] = useState('')//获取市场价的值
	const [startDay, setStartDay] = useState('')//有没有使用期限
	const [longIsTemp, setlongIsTemp] = useState({})//长期或者临时数据
	const [saveLoading, setSaveLoading] = useState(false)
	const [parentIsMainProject, setParentIsMainProject] = useState('--')
	const [isContract, setIsContract] = useState(false)
	const [profit, setProfit] = useState(false)
	const [staffList, setStaffList] = useState([]) // 分成人数据
	const [creditFlag, setCreditFlag] = useState(false) // 信用额度展示判断
	const [longcreditFlag, setLongCreditFlag] = useState(false) // 长期信用额度展示判断
	const [tempcreditFlag, setTempCreditFlag] = useState(false) // 短期信用额度展示判断
	const [accountId, setAccountId] = useState('')
	const [industryFlag, setIndustryFlag] = useState(true)

	useEffect(() => {
		//判断是直销还是代理商页面跳转
		if (location.pathname && location.pathname.indexOf('/orderAgent') > -1) {
			orderPageType = 'Agent'
		} else {
			orderPageType = 'Direct'
		}
		//如果没有detail信息 或者 没有client客户id信息，都将返回
		if (!location.state || !location.state.relationId) {
			// history.replace('/csr/customer/')
			history.goBack()
			return
		} else {
			setRelationId(location.state.relationId)
		}
		//续费（自带详情数据，需要反显厂商信息）
		//编辑、驳回重提、补充资料 需要传id反显所有信息

		if(location.state){
			if (location.state.vendorId) {
				setVendorId(location.state.vendorId)
				getFortuneOrderBeforeApplie(location.state.vendorId, location.state.relationId, null)
			} else if (location.state.id) {
				setPageId(location.state.id)
				getFortuneOrderBeforeApplie(null, location.state.relationId, location.state.id)
			} else {
				getFortuneOrderBeforeApplie(null, location.state.relationId, null)
			}
			//新开
			if(!location.state.vendorId && !location.state.id){
				setNewAdd(true)
			}else{
				setNewAdd(false)
			}
			//如果是编辑驳回
			if(location.state.auditStatus && location.state.auditStatus == 2){
				setIfEditBh(true)
			}else{
				setIfEditBh(false)
			}
		}
	}, [])
	//获取详情
	const getFortuneOrderBeforeApplie = (vendorId, relationId, id) => {
		let params = { client: relationId }
		if (vendorId !== null) params.vendorId = vendorId//有厂商ID，说明是续费
		if (id !== null) params.id = id//有订单id，说明是编辑
		if (location.state.qId) params.agent = location.state.qId//有代理商ID，说明是渠道订单
		setLoading(true)
		api.onOfficeWorkGetStaffByName({isAll: 1, type: 'ignore'}).then((manpowerRes) => {
			setStaffList(manpowerRes.list)
		})
		api[`getFortuneOrder${orderPageType}BeforeApplie`](params).then(async res => {
			if (parseSearch(location.search).msgId) {
				api.setMessageReaded({ ids: [parseSearch(location.search).msgId] })
			}
			if (JSON.stringify(res) !== "[]") {
				let data = Object.assign({}, res)
				//判断是否计业绩是'否'0，还是'是'1，然后为0时个人利润需要禁用
				if (orderPageType == 'Agent' && data.orderType && data.orderType.name == '渠道') {
					setIsColprofit(true)
				}
				setDetail(data)
				// 获取“一级行业”字段是否为空
				setIndustryFlag(+data.client.industry1 === 0 ? false : true)
				//判断是否为新开
				const ifNewAdd = (!location.state.vendorId && !location.state.id) ? true : false
				//新开时不需要设置
				!ifNewAdd && data.staff_projects && setStaffProjects(data.staff_projects)
				ifNewAdd && setFieldsValue({//新开时公司名称有默认值
					'companyName': data.client ? data.client.name : undefined
				})

				data.client && setClient(data.client)
				if (data.contractInfo) {
					setContractInfo(data.contractInfo)
					data.contractInfo.credit && setCredit(data.contractInfo.credit)
					data.contractInfo.available && setAvailable(data.contractInfo.available)
					setVendorContractEmpty(false)
					setTimeout(() => {
						if (data.contractInfo.credit && data.order.row.creditAccount && data.contractInfo.credit.map(item => item.id).includes(data.order.row.creditAccount)) {
							setFieldsValue({ 'creditAccountId': data.order.row.creditAccount })
						}
					}, 1500)	
				}else{
					data.available && setAvailable(data.available)
					data.credit && setCredit(data.credit)
					setContractInfo('')
					setVendorContractEmpty(true)
					setTimeout(() => {
						if (data.credit && data.order.row.creditAccount && data.credit.map(item => item.id).includes(data.order.row.creditAccount)) {
							setFieldsValue({ 'creditAccountId': data.order.row.creditAccount })
						}
					}, 1500)
				}
				ifNewAdd && setLoading(false)
				//如果是续费（也可以判断路由url，如果为renew则为续费）
				if (location.state.vendorId && data.vendor && Object.prototype.toString.call(data.vendor) !== '[object Array]') {
					api.getContractWithVendor({vendor: data.vendor.vendor}).then(res => {
						if (!Array.isArray(res)) {
							setContractInfo(res)
							setVendorContractEmptys(false)
							setAccountId(res.accountId)
						} else {
							setContractInfo('')
							setVendorContractEmptys(true)
						}
					})
					setVendor(data.vendor)
					const v = data.vendor
					/*setFieldsValue({
						'contractno': v.contract
					})
					if(data.contractInfo && data.contractInfo.contactInfo){
						let cData = data.contractInfo.contactInfo
						setFieldsValue({
							'contactname': cData.contacts,
							'contactmobile': cData.tel,
							'contacttel': undefined,
							'contactfax': undefined,
							'contactemail': cData.email,
							'contactqq': undefined
						})
					}*/
					setInitCompId(v.relBranchId)
					setFieldsValue({
						'contractno': v.contract
					})
					if(data.lastContact){
						let cData = data.lastContact
						cData.attachdata && setAttachdataList(cData.attachdata)
						//订单联系人
						setFieldsValue({
							'contactname': cData.contactname,
							'contactmobile': cData.contactmobile,
							'contacttel': cData.contacttel,
							'contactfax': cData.contactfax,
							'contactemail': cData.contactemail,
							'contactqq': cData.contactqq,
						})
						//订单资料
						setFieldsValue({
							'companyName': cData.companyName,
							'supplier': {
								key: cData.supplier,
								label: cData.supplierName
							}
						})
						cData.industry1 ? setFieldsValue({
							'industry1': {
								key: cData.industry1,
								label: cData.industry1Name
							}
						}) : setFieldsValue({
							'industry1': undefined
						})
						cData.industry2 ? setFieldsValue({
							'industry2': {
								key: cData.industry2,
								label: cData.industry2Name
							}
						}) : setFieldsValue({
							'industry2': undefined
						})
						//获取一级行业列表和提单公司列表
						api.getIndustrysBySupplier({
							supplierId: cData.supplier,
							projectId: cData.project
						}).then(L => {
							setIndustryList(L.list)
							setSupplierCompanyList(L.companys)
							setFieldsValue({
								'supplierCompanyId': {
									key: cData.supplierCompanyId,
									label: cData.supplierCompanyName
								}
							})
						})
						setIndustryName(cData.industry1Name)
						setIndustryName2(cData.industry2Name)
						setSupplierName(cData.supplierName)
						//获取二级行业列表
						api.getSysProjectIndustryList({
							sysProjectId: cData.project,
							projectIndustryPid: cData.industry1
						}).then(L2 => {
							setIndustryList2(L2)
						})
					}
					
					setProjectId(v.project)
					setProjectName(v.projectName)
					
					//获取产品列表
					api.getFortuneOrderProjectGetChildInfo({ 
						id: v.project, 
						relationType: orderPageType == 'Agent' ? 1 : 0, 
						companyId: data.contractInfo.companyId, 
						relationId: orderPageType == 'Agent' ? data.agent.id : relationId, 
						projectId: v.project 
					}).then(res => {
						setParentIsMainProject(res.parent_is_main_project)
						let arr = []
						res.list.forEach((item, index) => {
							arr[index] = item.id
						})
						if(arr.includes(+v.product)){
							setProductId(v.product)
							setProductName(v.productName)
						}
						setProductList(res.list)
						setFieldsValue({
							'product': {
								key: v.product,
								label: v.productName
							}
						})
					})
					//获取供应商列表
					api.getFortuneOrderSupplier({
						project: v.project
					}).then(res => {
						setSupplierList(res)
					})
					//获取业务类型列表
					api.getFortuneOrderProjectGetChildInfo({ 
						id: v.product, 
						relationType: orderPageType == 'Agent' ? 1 : 0, 
						companyId: data.contractInfo.accountId, 
						relationId: orderPageType == 'Agent' ? data.agent.id : relationId, 
						projectId: v.project
					}).then(res => {
						setParentIsMainProject(res.parent_is_main_project)
						let list = [...res.list].filter(item => item.name !== '新开')
						setBusinessList(list)
						//反显业务类型
						setFieldsValue({
							'business': '' + list[0].id
						})
						setBusinessId(list[0].id)
						getFortuneOrderAttchData(list[0].id, list[0].name, data.lastContact.attachdata)
						if(orderPageType == 'Agent'){
							res.integral && setFieldsValue({
								'integral': res.integral ? res.integral : '0'
							})
							setIntegral(res.integral)
						}
					})
					setLoading(false)
				}
				//编辑、驳回重提、补充资料（也可判断路由url，如果为edit则为编辑）
				if (data.order) {
					setLoading(true)
					setOrderDetail(data.order)
					setFieldsValue({
						'isPerformance': data.order.row.isPerformance
					})
					// 驳回重提 判断是否计业绩是'否'0，还是'是'1，然后为0时个人利润需要禁用
					if (orderPageType == 'Agent' && data.orderType && data.orderType.name == '渠道' && data.order.row.isPerformance === 0) {
						setIsColprofit(true)
					} else {
						setIsColprofit(false)
					}
					//?
					// if (data.credit.temp && data.credit.long) {
					// 	if (data.credit.temp.trueAmount) {
					// 		if (data.order.colInfo.colmarketprice > data.credit.temp.trueAmount) {
					// 			// console.log('长期')
					// 			setStartDay(data.credit.long.startDay)
					// 			setlongIsTemp(data.credit.long)
					// 		} 
					// 		if (data.order.colInfo.colmarketprice <= data.credit.temp.trueAmount) {
					// 			// console.log('临时')
					// 			setStartDay(data.credit.temp.startDay)
					// 			setlongIsTemp(data.credit.temp)
					// 		}
					// 	}
					// } else if (data.credit.temp && !data.credit.long) {
					// 	setStartDay(data.credit.temp.startDay)
					// 	setlongIsTemp(data.credit.temp)
					// } else if (!data.credit.temp && data.credit.long) {
					// 	setStartDay(data.credit.long.startDay)
					// 	setlongIsTemp(data.credit.long)
					// }
					if(data.order.row){
						setRowData(data.order.row)
						setFieldsValue({'figtype': data.order.row.figtype})
					}
					let order = data.order
					if (order.contractInfo && order.contractInfo.contractNo) setContractInfo(order.contractInfo)
					if (order.orderInfo) {
						// setVendor(order.vendor)
						setOrderInfo(order.orderInfo)
						setProjectId(order.orderInfo.project)
						setProjectName(order.orderInfo.projectName)
						setProductId(order.orderInfo.product)
						setProductName(order.orderInfo.productName)
					} else {
						message.error('订单信息错误')
						let timer = setTimeout(() => {
							history.goBack()
							clearTimeout(timer)
						}, 1500)
						return
					}
					//?
					const { long, temp } = data.credit
					if(data.credit.length > 0) {// 有信用账户
						setCreditFlag(false)
						let arr = []
						data.credit.map(item => {
							// console.log(item.projectNames.split(','))
							if (!(item.projectNames.split(',').some(n => n == order.orderInfo.projectName))) {
								arr.push(true)
							}
						})
						if (arr.length > 0 && arr.length == data.credit.length) {
							setCreditFlag(true)
						}
					} else  {
						setCreditFlag(false)
					}
					// if (long && long.trueAmount || long && long.trueAmount == 0 || temp && temp.trueAmount || temp && temp.trueAmount == 0) { // 有信用账户
					// 	let projectNameLong = long && long.projectNames ? long.projectNames.split(',') : []
					// 	let projectNameTemp = temp && temp.projectNames ? temp.projectNames.split(',') : []
					// 	if (!projectNameLong.some(n => n == order.orderInfo.projectName) && !projectNameTemp.some(n => n == order.orderInfo.projectName)) {
					// 		setCreditFlag(true)
					// 	} else {
					// 		projectNameLong.some(n => n == order.orderInfo.projectName) && setLongCreditFlag(true)
					// 		projectNameTemp.some(n => n == order.orderInfo.projectName) && setTempCreditFlag(true)
					// 	}
					// } else {
					// 	setCreditFlag(false)
					// }
					//业绩反显
					// order.performance && setPerformance(order.performance)
					// 获取返显分成人
					let stfIdArr = []
					let yejiArr = []
					editeleaderName = []
					editeDepartment = []
					const keys = getFieldValue('keys');
					const nextKeys = []
					idNum = 0
					order.performance.map((v,i) => {
						stfIdArr.push(v.stfId)
						yejiArr.push(v.performance)
						editeleaderName[idNum] = v.superior
						editeDepartment[idNum] = v.department
						nextKeys.push(idNum++)
					// console.log("nextKeys.push(id++)",nextKeys.push(id++))
					})
					setFieldsValue({
						keys: nextKeys,
					})
					setFieldsValue({
						'stfId': stfIdArr,
						'performance': yejiArr
					})

					let industryName1 = '', industryName2 = '', supplierCompanyName = ''
					if (order.orderExInfo) {
						//自定义资料
						let arr = [], json = {}
						order.orderExInfo.attachdata.forEach((item, index) => {
							arr[index] = {
								id: 'myid' + index,
								name: Object.keys(item)[0]
							}
							json['attach-myid' + index + '-' + Object.keys(item)[0]] = Object.values(item)[0]
							let timer = setTimeout(() => {
								setFieldsValue(json)
								clearTimeout(timer)
							}, 2000)
						})
						setAttachList(arr)
					}
					//获取供应商
					await api.getFortuneOrderSupplier({
						project: order.orderInfo.project
					}).then(res => {
						setSupplierList(res)
					})
					setSupplierName(order.orderExInfo.supplierName)
					setSupplierId(order.orderExInfo.supplier)
					//获取一级行业列表
					await api.getIndustrysBySupplier({
						supplierId: order.orderExInfo.supplier,
						projectId: order.orderInfo.project
					}).then(L1 => {
						L1.list.forEach(item => {
							if (order.orderExInfo && item.id == order.orderExInfo.industry1) {
								industryName1 = item.name
								setIndustryName(item.name)
							}
						})
						L1.companys.forEach(item => {
							if (order.orderExInfo && item.id == data.order.row.supplierCompanyId) {
								supplierCompanyName = item.name
							}
						})
						setIndustryList(L1.list)
						setSupplierCompanyList(L1.companys)
					})
					//获取二级行业列表
					await api.getSysProjectIndustryList({
						sysProjectId: order.orderInfo.project,
						projectIndustryPid: order.orderExInfo.industry1
					}).then(L2 => {
						L2.forEach(item => {
							if (order.orderExInfo && item.id == order.orderExInfo.industry2) {
								industryName2 = item.name
								setIndustryName2(item.name)
							}
						})
						setIndustryList2(L2)
					})
					//判断收款类型
					if (order.row && order.colInfo) {
						setColTypeSel(order.row.coltype)
						setFieldsValue({
							'coltype': order.row.coltype + ''
						})
						if(order.row.coltype == 1 || order.row.coltype == 3 || order.row.coltype == 7){
							setFieldsValue({
								'colactualprice': order.colInfo.colactualprice
							})
						}
						if(order.row.coltype == 1 || order.row.coltype == 3){ 
							// 积分 区分直销/渠道
							if (orderPageType == 'Agent') {
								let val = ((+order.colInfo.colactualprice + +order.colInfo.integral) / +order.colInfo.colmarketprice).toFixed(4)
								setColrebate(isNaN(val) ? 0 : val)
							} else {
								let val = (+order.colInfo.colactualprice / +order.colInfo.colmarketprice).toFixed(4)
							    setColrebate(isNaN(val) ? 0 : val)
							}
						} else {
							let val = (+order.colInfo.colactualprice / +order.colInfo.colmarketprice).toFixed(4)
							setColrebate(isNaN(val) ? 0 : val)
						}
						
						/*if (order.base.coltype == '正常收款' || order.base.coltype == '厂商直汇' || order.base.coltype == '厂商赠送') {
							order.base.coltype == '正常收款' && setColTypeSel(1)
							order.base.coltype == '厂商直汇' && setColTypeSel(3)
							order.base.coltype == '厂商赠送' && setColTypeSel(7)
							setFieldsValue({
								'coltype': order.base.coltype,
								'colactualprice': order.colInfo.colactualprice
							})
							let val = (+order.colInfo.colactualprice / +order.colInfo.colmarketprice).toFixed(4)
							setColrebate(isNaN(val) ? 0 : val)
						}*/
						if (order.row.coltype == 2) {
							setFieldsValue({
								'colpredictprice': order.colInfo.colpredictprice
							})
							let val = (+order.colInfo.colpredictprice / +order.colInfo.colmarketprice).toFixed(4)
							setColrebate(isNaN(val) ? 0 : val)
						}
					}
					//附件
					if (order.attchments && order.attchments.length) {
						order.attchments.forEach((item, index) => {
							fileList.push({
								status: 'done',
								uid: index,
								name: item.name,
								url: item.url
							})
						})
						setFileList(order.attchments.map((item, index) => ({...item, uid: index})))
					}
					//let business = order.orderInfo && (order.orderInfo.businessName == '新开' ? 1 : order.orderInfo.businessName == '续费' ? 2 : 3)
					let business = order.orderInfo.business
					setBusinessId(order.orderInfo.business)
					let timer = setTimeout(() => {
						if (order.contractInfo) {
							setFieldsValue({
								'contractno': order.contractInfo.contractNo
							})
						}
						if (order.contactInfo) {
							setFieldsValue({
								'contactname': order.contactInfo.contactname
								, 'contactmobile': order.contactInfo.contactmobile
								, 'contacttel': order.contactInfo.contacttel
								, 'contactfax': order.contactInfo.contactfax
								, 'contactemail': order.contactInfo.contactemail
								, 'contactqq': order.contactInfo.contactqq
								// ,'contactweb': order.contactInfo.contactweb
								// ,'contactaddress': order.contactInfo.contactaddress
							})
						}
						if (order.orderInfo) {
							setFieldsValue({
								'project': order.orderInfo.project
								, 'product': order.orderInfo.product
								, 'remark': order.orderInfo.remark
							})
						}
						if (order.orderExInfo) {
							setFieldsValue({
								'companyName': order.orderExInfo.companyName
								, 'supplier': {
									key: order.orderExInfo.supplier,
									label: order.orderExInfo.supplierName
								}
								, 'supplierCompanyId': {
									key: data.order.row.supplierCompanyId,
									label: supplierCompanyName
								}
							})
							order.orderExInfo.industry1 ? setFieldsValue({
								'industry1': {
									key: order.orderExInfo.industry1,
									label: industryName1
								}
							}) : setFieldsValue({
								'industry1': undefined
							})
							order.orderExInfo.industry2 ? setFieldsValue({
								'industry2': {
									key: order.orderExInfo.industry2,
									label: industryName2
								}
							}) : setFieldsValue({
								'industry2': undefined
							})
						}
						if (order.colInfo) {
							setFieldsValue({
								'colmarketprice': order.colInfo.colmarketprice
							})
							if(orderPageType == 'Agent' && order.row && (order.row.coltype == 1 || order.row.coltype == 3)){
								setFieldsValue({
									'integral': order.colInfo.integral
								})
							}
							// if(order.row && order.row.colstatus !== 2){
							if(order.row){
								setColrefundRadio(2)
								setFieldsValue({
									'colprofit': order.colInfo.colprofit
									,'colcost': order.colInfo.colcost
									,'colrefund': order.colInfo.colrefund
								})
							}
							api.getFortuneOrderProjectGetChildInfo({
								id: order.orderInfo.project, 
								relationType: orderPageType == 'Agent' ? 1 : 0, 
								companyId: order.contractInfo && order.contractInfo.accountId ? order.contractInfo.accountId : order.row.companyId,
								relationId: orderPageType == 'Agent' ? location.state.qId : location.state.relationId, 
								projectId: order.orderInfo.project
							}).then(res => {
								setParentIsMainProject(res.parent_is_main_project)
								setIntegral(res.integral ? res.integral : 0)
							})
						}
						setFieldsValue({
							'business': business
						})
						clearTimeout(timer)
						location.state.id && ifEditBh && order.contractInfo && order.contractInfo.contractNo && getFortuneOrderContract(order.contractInfo.contractNo, order.client.id)
						setLoading(false)
					}, 1500)
					await getColrebate()
				}
			}
		}).catch(() => setLoading(false))
	}
	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 8 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 },
		}
	}
	const searchSubmit = (e) => {
		e.preventDefault()
		validateFields((err, vals) => {
			// 判断渠道提渠道时，是否计业绩为否0
			if (orderPageType === 'Agent' && detail.orderType && detail.orderType.name == '直销渠道') {
				vals.isPerformance = 1
			}
			if (err) {
				let errArr = Object.values(err)
				if (errArr.length) {
					return message.warning(errArr[0].errors[0].message)
				}
			}
			let { stfId, performance, zhuguan, keys, bumen, ...data } = vals
			// let data = { ...vals }
			let attachdatas = []
			for (let i in data) {
				if (data[i] == undefined) data[i] = ''
				if (location.state.id == null) {
					if (i == 'supplierCompanyId' || i == 'industry1' || i == 'industry2' || i == 'product' || i == 'project' || i == 'supplier') {
						if (data[i] !== '') {
							data[i] = data[i].key//这些情况下key为id，label为name
						}
					}
				} else {
					//在驳回重提等状态下，project和product为固定写死的，故单独排除处理
					if (i == 'supplierCompanyId' || i == 'industry1' || i == 'industry2' || i == 'supplier') {
						if (data[i] !== '') {
							data[i] = data[i].key
						}
					}
				}
				//处理自定义字段
				if (i.indexOf('attach-') > -1) {
					let d = i.split('-')
					let json = {}
					json[d[2]] = data[i]
					attachdatas.push(json)
					delete data[i]
				}
			}
			//处理附件
			let uploadList = []
			for (let i = 0, k = fileList.length; i < k; i++) {
				if (fileList[i].status !== 'removed') {
					uploadList.push({
						url: fileList[i].url,
						name: fileList[i].name
					})
				}
			}
			if (!err) {
				/**
				 * id 订单ID 编辑、驳回重提、补充资料需要
				 * vendor 厂商ID，续费的时候传
				 */
				// 判断“一级行业”字段是否为空
				// if (!industryFlag) {
				// 	message.warning("请填写客户行业信息并审批通过后才可提单")
				// 	return
				// }

				let majorminor = null, isodfkjia = null
				if(location.state.id){
					majorminor = rowData && rowData.majorminor ? rowData.majorminor : null
					isodfkjia = rowData && rowData.isodfkjia ? 1 : ''
				}else{
					majorminor = parentIsMainProject ? 1 : 2
					// contractInfo.projectIds ? (contractInfo.projectIds.indexOf(projectId) > -1 ? 1 : 2) : null
					isodfkjia = contractInfo.isFrame ? 1 : ''
				}
				
				let params = {
					clientId: relationId,
					isodfkjia: isodfkjia,
					majorminor: majorminor,
					attachdata: attachdatas,
					colrebate,
					attachments: uploadList,
					supplierName,
					...data
				}
				if (location.state.id) {
					params['id'] = location.state.id
					if (params['coltype'] == oderDetail['base']['coltype']) params['coltype'] = oderDetail['base']['coltypeId']
				}
				if (vendor.vendor) params.vendor = vendor.vendor
				if (vendor && vendor.id) params.vendorId = vendor.id
				if (orderPageType == 'Agent' && location.state.qId) params.agentId = location.state.qId
				params.project = projectId
				params.product = productId
				params.business = businessId
				params.perfStfName = perfStfName
				//渠道转渠道时，如果是否计业绩选了否 不需要获取分成人
				if(vals.coltype !== '7' && vals.isPerformance !== 0){
					if(!vals.stfId){
						return message.warning('请重新输入订单中‘个人利润’后再进行尝试，务必注意格式，如‘0.00’、‘500.00’')
					}
				}
				let perInfoObj
				if (vals.stfId) {
					let stfIdToempty = vals.stfId.filter(item => item)
					let performanceToempty = vals.performance.map(String).filter(item => item)

					perInfoObj = stfIdToempty.map((item,i) => {
						return {stfId: item, performance: performanceToempty[i]}
					})	

					if(new Set(stfIdToempty).size !== stfIdToempty.length){
						return message.warning('有重复的分成人，请检查')
					}

					let sumValue= performanceToempty.map(Number).reduce((sum2,number2) => {
						return sum2 + number2
					}, 0 )
					if ((Math.abs((Number(sumValue)).toFixed(2) - (Number(vals.colprofit)).toFixed(2))).toFixed(2) > 0.02) {
						return message.error('分成额度的和必须等于个人利润')
					}
					if (!performanceToempty.map(Number).every(num => num <= 0) && !performanceToempty.map(Number).every(num => num >= 0)) {
						return message.error("请注意：同一订单分成人业绩不允许有正有负的情况，即必须同为正数或同为负数")
					}
				}
				params.perInfo = perInfoObj ? perInfoObj : ''

				//startDay 0-没有使用期限 >0有使用期限，没有使用期限时给出提示
				// console.log('params=>', params)
				// console.log('credit=>', credit)
				if (colTypeSel == 2) {
					//?
					if (credit.length > 0) {
						let creditLongIsTemp = credit.filter(v => v.id == params.creditAccountId)[0]
						// console.log('creditLongIsTemp=>', creditLongIsTemp)
						if (creditLongIsTemp.startDay === 0) {
							// console.log(contractInfo)
							return confirm({
								title: '提示',
								content: '请申请该客户的“信用额度调整”，补填“使用期限”，该申请审批通过后再提单！',
								okText: '去申请',
								onOk() {
									history.push('/account/creditapply/edit?customerType='+ (orderPageType == 'Agent' ? '1' : '0')+
									'&customerId='+creditLongIsTemp.oaqId+
									'&accountType='+(creditLongIsTemp.accountType == 'long' ? 0 : 1)+
									'&company='+creditLongIsTemp.company+
									'&trueAmount='+creditLongIsTemp.trueAmount+
									'&usedAmount='+creditLongIsTemp.usedAmount+
									'&accountId='+creditLongIsTemp.id+
									'&companyId='+creditLongIsTemp.companyId)
								}
							})
						}
					}
				}
				// console.log(contractInfo)
				// console.log(projectName)
				if(vendorId && contractInfo && contractInfo.contractNo !== '' && projectName !== '其他' && projectName !== '新媒体平台服务') {
					api.getFortuneContractDetail({ type: 0, contractNo: contractInfo.contractNo, check: 1}).then(res => {
						let projectNamesNew = res.projectNames && res.projectNames.split(',')
						// console.log(projectNamesNew)
						// console.log(projectNamesNew.some(n => n == projectName))
						if (!projectNamesNew.some(n => n == projectName)) {
							return message.warning('该续费订单的项目不包含在关联的合同中，无法继续续费，请检查合同')
						}
						api.getFortuneOrderContract({
							contractNo: contractInfo.contractNo,
							clientId: relationId,
							vendor: vendorId,
							isRebill: 2,
							agentId: orderPageType == 'Agent' && location.state.qId ? location.state.qId : ''
						}).then(res => {
							// console.log(res)
							setSaveLoading(true)
							api[`onFortuneOrder${orderPageType}Applie`](params).then(() => {
								message.success('提交成功')
								history.push(`/order${orderPageType}/auditList`)
								setSaveLoading(false)
							}).catch(() => setSaveLoading(false))
						}).catch(err => console.log(err))
					})
				} else {
					setSaveLoading(true)
					api[`onFortuneOrder${orderPageType}Applie`](params).then(() => {
						message.success('提交成功')
						history.push(`/order${orderPageType}/auditList`)
						setSaveLoading(false)
					}).catch(() => setSaveLoading(false))
				}
			}
		})
	}
	//前端填写校验
  /*const checkValidate = (data) => {   
    if(orderPageType == 'Agent'){
      if((+data.colprofit + +data.colcost + +data.colrefund).toFixed(4) !== (+data.colactualprice + +data.integral).toFixed(4)){
        return message.warning('调整项' + (data.coltype == 2 ? '预计收款' : '成本') + '个人利润 应等于 实收 + 积分')
      }
    }else{
      if((+data.colprofit + +data.colcost + +data.colrefund).toFixed(4) !== (+data.colactualprice).toFixed(4)){
        return message.warning('调整项' + (data.coltype == 2 ? '预计收款' : '成本') + '个人利润 应等于 实收')
      }
    }
  }*/
	//查询合同信息
	const getFortuneOrderContract = (contractNo, clientId = 0) => {
		if(contractNo == contractNoChecked)return//如果查询的是相同的上次的合同，就不再查询(防止获取焦点时，如果值不变，重复请求接口)
		let params = { contractNo, clientId: clientId == 0 ? relationId : clientId, vendor: vendorId ? vendorId: ''}
		if (orderPageType == 'Agent' && location.state.qId) params.agentId = location.state.qId
		if (location.state.vendorId || rowData.businessName == '续费'){
			params.accountId = accountId
			params.isRebill = 2
		}else{
			params.isRebill = 1
		}
		setLoading(true)
		api.getFortuneOrderContract(params).then(data => {
			setVendorContractEmptys(false)
			setContractNoChecked(contractNo)
			for(let i in data){
				if(typeof data[i] == 'string'){
					data[i].replace(/\s+/g, '')
				}
			}
			setContractInfo(data)
			setLoading(false)
			data.available && setAvailable(data.available)
			data.credit && setCredit(data.credit)	
			// data.productIds && setProductIds(data.productIds.split(','))
			if(data.companyId || data.companyId == 0) setInitCompId(data.companyId)
			let projectIds = data.projectIds.split(','), projectNames = data.projectNames.split(',')
			//必须length相等，否则数据就是错误的
			let arr = []
			if (projectIds.length && projectNames.length && projectIds.length === projectNames.length) {
				for (let i = 0; i < projectIds.length; i++) {
					arr.push({
						id: projectIds[i],
						name: projectNames[i]
					})
				}
			}
			setStaffProjects(arr)
			!location.state.id && setFieldsValue({
				'project': undefined,
				'product': undefined,
				'business': undefined
			})
			let cData = data.contactInfo
			//既不是续费也不是编辑，而是新开的时候
			!vendorId && !location.state.id && cData && setFieldsValue({
				'contactname': cData.contacts,
				'contactmobile': cData.tel,
				'contacttel': undefined,
				'contactfax': undefined,
				'contactemail': cData.email,
				'contactqq': undefined
			})
		}).catch(() => {
			setLoading(false)
			setContractNoChecked('')
			if(!location.state.id){
				setFieldsValue({
					'contractno': undefined,
					'project': undefined,
					'product': undefined,
					'business': undefined,
					'supplier': undefined,
					'supplierCompanyId': undefined,
					'industry1': undefined,
					'industry2': undefined,
					'isodftrun': undefined
				})
				setContractInfo('')
				setAvailable({})
				setCredit({})
				// setProductIds([])
				setStaffProjects([])
				if (!vendorId) setProjectName('')
				setProductName('')
				setIndustryName('')
				setIndustryName2('')
				setColrebate(0)
				setSupplierId('')
				setSupplierName('')
			}
		})
	}
	//获取合同信息
	const onGetFortuneOrderContract = e => {
		let val = e.target.value.trim()
		if(val !== ''){
			getFortuneOrderContract(val)
		}
	}
	//获取客户联系人信息
	const getFortuneOrderContact = () => {
		setContactnameVisible(true)
		api.getFortuneOrderContact({ client: relationId }).then(res => {
			setContactPersonList(res)
		})
	}
	//获得项目id
	const onChangeProject = (data) => {
		setProjectId(data.key)
		setProjectName(data.label)
		// if(!detail.perfStaff){
		// 	let cprofit = getFieldValue('colprofit')
		// 	if(cprofit) getPerformanceProfitForStaff(data.key)
		// }
		/**
		 * 当改变项目id时，产品、业务类型、一级行业、二级行业、供应商全部重置
		 */
		setProductId('')
		setProductName('')
		setBusinessId('')
		setProductList([])
		setBusinessList([])
		setIndustryList([])
		setSupplierCompanyList([])
		setIndustryList2([])
		setSupplierList([])
		setSupplierName('')
		setSupplierId('')
		setIndustryName('')
		setIndustryName2('')
		setAttachList([])
		setFieldsValue({
			'product': undefined,
			'business': undefined,
			'supplierCompanyId': undefined,
			'industry1': undefined,
			'industry2': undefined,
			'supplier': undefined
		})
		//获取产品列表
		api.getFortuneOrderProjectGetChildInfo({
			id: data.key, 
			relationType: orderPageType == 'Agent' ? 1 : 0, 
			companyId: contractInfo.accountId,
			relationId: orderPageType == 'Agent' ? location.state.qId : location.state.relationId, 
			projectId: data.key
		}).then(res => {
			/*let interSelList = []
			res.list.forEach(item => {
				if (productIds.includes(item.id + '')) interSelList.push(item)
			})

			//templateId、businessType == 1 or templateId == 3 的时候不过滤
			let ifQQ = (contractInfo.templateId == contractInfo.businessType == 1 || contractInfo.templateId == 3) ? true : false
			setProductList(!ifQQ ? interSelList : res.list)*/
			setParentIsMainProject(res.parent_is_main_project)
			setProductList(res.list)
			setIntegral(res.integral ? res.integral : 0)
		})
		//初始化供应商
		api.getFortuneOrderSupplier({project: data.key}).then(res => {
			setSupplierList(res)
		})
		// 信用订单 先选择收款类型在选择项目
		colTypeSel && colTypeSel == 2 && getCreditFlag(data.label)
	}

	//获得产品id
	const onChangeProduct = (data) => {
		setProductId(data.key)
		setProductName(data.label)
		setBusinessId('')
		setBusinessList([])
		setAttachList([])
		setFieldsValue({
			'business': undefined
		})

		//获取业务类型列表
		api.getFortuneOrderProjectGetChildInfo({ 
			id: data.key, 
			relationType: orderPageType == 'Agent' ? 1 : 0, 
			companyId: contractInfo.companyId, 
			relationId: orderPageType == 'Agent' ? location.state.qId : location.state.relationId, 
			projectId 
		}).then(res => {
			setParentIsMainProject(res.parent_is_main_project)
			let list = [...res.list].filter(item => item.name !== (vendorId !== null ? '新开' : '续费'))

			setBusinessList(list)
		})
	}

	//获得业务类型id
	const onChangeBusiness = (val, option) => {
		setBusinessId(val)
		getFortuneOrderAttchData(val, option.props.children, attachdataList)
		// if(!detail.perfStaff){
			let cprofit = getFieldValue('colprofit')
			if(cprofit) setRenSubmitDis(true)
			if(cprofit) getPerformanceProfitForStaff(projectId, val)
		// }
	}

	// 一级行业list 提单公司list
	const getIndustryList = (supplierId) => {
		api.getIndustrysBySupplier({
			supplierId,
			projectId: projectId
		}).then(data => {
			setIndustryList(data.list)
			setSupplierCompanyList(data.companys)
			let initData = data.companys.filter(item => item.id == initCompId)
			initData.length && setFieldsValue({
				'supplierCompanyId': {
					key: initCompId,
					label: initData[0].name
				}
			})
		})
	}
	//获取一级行业选中id
	const onChangeIndustry = async (data) => {
		setIndustryName(data.label)
		setIndustryName2('')
		setFieldsValue({
			'industry2': undefined
		})
		// 2级行业list
		await api.getSysProjectIndustryList({
			sysProjectId: projectId,
			projectIndustryPid: data.key
		}).then(data => {
			setIndustryList2(data)
		})
	}
	//获取二级行业name
	const onChangeIndustry2 = async (data) => {
		setIndustryName2(data.label)
	}
	//获取提单公司选中id
	const onChangeSupComp = id => {
		
	}
	//获取供应商name
	const getSupplierName = data => {
		setSupplierName(data.label)
		setSupplierId(data.key)
		setFieldsValue({
			'supplierCompanyId': undefined,
			'industry1': undefined,
			'industry2': undefined
		})
		setIndustryName('')
		setIndustryName2('')
		//获取一级行业列表
		getIndustryList(data.key)
		setIndustryList2([])
	}
	//获取自定义资料
	const getFortuneOrderAttchData = (bussinessId, bussinessName, attachdataList) => {
		api.getFortuneOrderAttchData({ bussinessId }).then(data => {
			setAttachList(data)//自定义label列表
			if(bussinessName == '续费'){//101表示续费，续费的时候需要对自定义字段反显赋值
				//attachdataList 订单中自定义字段列表
				const json = {}
				let len = attachdataList && attachdataList.length, lenJ = data.length
				// if(len >= lenJ){
					for(let i = 0; i < len; i++){
						for(let j = 0; j < lenJ; j++){
							if(data[j].name == Object.keys(attachdataList[i])[0]){
								json['attach-' + data[j]['id'] + '-' + data[j]['name']] = Object.values(attachdataList[i])[0]
							}
						}
					}
				// }
				setFieldsValue(json)
			}
		})
	}
	//选择收款类型
	const onChangeColType = val => {
		setColTypeSel(val)
		setColrebate(0)
		setColrefundRadio(0)
		setStartDay('')
		setlongIsTemp({})
		setProfit(false)

		// 切换类型，分成人需要清空
		const keys = getFieldValue('keys');
		const nextKeys = []
		idNum = 0
		setFieldsValue({
			keys: nextKeys,
		})

		if (val == 7) {//7表示厂商赠送
			const timer = setTimeout(() => {
				setProfit(true)
				setFieldsValue({
					'colactualprice': 0,
					'colmarketprice': 0,
					'colprofit': 0,
					'colcost': 0,
					'colrefund': 0
				})
				clearTimeout(timer)
			}, 600)
			
		}else{
			setProfit(false)
			if (isColprofit) {
				setFieldsValue({
					'colprofit': 0
				})
			} else {
				setFieldsValue({
					'colprofit': undefined
				})
			}
			setFieldsValue({
				'colactualprice': undefined,
				'colmarketprice': undefined,
				'colrefund': undefined,
				'colcost': undefined,
			})
			if (val == 2) {
				projectName && getCreditFlag(projectName)
			}
		}
		// if(performance.length){
		// 	performance.forEach(item => item.performance = 0)
		// 	setPerformance(performance)
		// }
	}

	// 判断信用账户的关联的项目是否包含合同中的项目
	const getCreditFlag = (projectNameVal) => {
		// console.log('projectNameVal=>', projectNameVal)
		// console.log('credit=>', credit)
		//?
		if (projectNameVal) {
			if(credit.length > 0) {// 有信用账户
				setCreditFlag(false)
				let arr = []
				credit.map(item => {
					// console.log(item.projectNames.split(','))
					if (!(item.projectNames.split(',').some(n => n == projectNameVal))) {
						arr.push(true)
					}
				})
				if (arr.length > 0 && arr.length == credit.length) {
					setCreditFlag(true)
				}
			} else  {
				setCreditFlag(false)
			}
			// if (credit.long && credit.long.trueAmount || credit.long && credit.long.trueAmount == 0 || credit.temp && credit.temp.trueAmount || credit.temp && credit.temp.trueAmount == 0) { // 有信用账户
			// 	setCreditFlag(false)
			// 	setLongCreditFlag(false)
			// 	setTempCreditFlag(false)
			// 	let projectNameLong = credit.long && credit.long.projectNames ? credit.long.projectNames.split(',') : []
			// 	let projectNameTemp = credit.temp && credit.temp.projectNames ? credit.temp.projectNames.split(',') : []
			// 	if (!projectNameLong.some(n => n == projectNameVal) && !projectNameTemp.some(n => n == projectNameVal)) {
			// 		setCreditFlag(true)
			// 	} else {
			// 		projectNameLong.some(n => n == projectNameVal) && setLongCreditFlag(true)
			// 		projectNameTemp.some(n => n == projectNameVal) && setTempCreditFlag(true)
			// 	}
			// } 
			
		} else {
			setCreditFlag(false)
		}
	}

	// 文件上传 
	const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.pptx, .PDF, .doc, .docx, .csv, .xlsx, .png, .gif, .jpg, .jpeg, .bmp',
		allowSizeType: 4,
		limitNum: 15
	})

	//获取市场价
	const onChangeColrebate = (value) => {
		setColrebateVal(value)
	}

	//计算折扣
	const getColrebate = (val) => {
		if (colTypeSel == 1 || colTypeSel == 3) { //正常订单，厂商直汇
			if ((getFieldValue('integral') == 0 || getFieldValue('integral') || getFieldValue('colactualprice')) && (getFieldValue('colmarketprice') || getFieldValue('colmarketprice') == 0)) {
				let colactualprice = toNumber(getFieldValue('colactualprice')),
					colmarketprice = toNumber(getFieldValue('colmarketprice')),
					integralVal = toNumber(getFieldValue('integral'))
				let val = integralVal ? ((+colactualprice + +integralVal) / colmarketprice).toFixed(4) : (colactualprice / colmarketprice).toFixed(4)
				if (+colmarketprice === 0) {
					setProfit(true)
				} else {
					setProfit(false)
					setColrebate((val == 'NaN' || val+'' == 'Infinity') ? 0 : val)
				}
			}
		}
		if (colTypeSel == 2) {//信用订单 => 预计收款
			//?
			// if (val == 1) {
			// 	// console.log(credit)
			// 	if (JSON.stringify(credit) !== '{}') {
			// 		if (credit.temp && credit.long) {
			// 			if (credit.temp.trueAmount) {
			// 				if (colrebateVal > credit.temp.trueAmount) {
			// 					// console.log('长期')
			// 					setStartDay(credit.long.startDay)
			// 					setlongIsTemp(credit.long)
			// 				} 
			// 				if (colrebateVal <= credit.temp.trueAmount) {
			// 					// console.log('临时')
			// 					setStartDay(credit.temp.startDay)
			// 					setlongIsTemp(credit.temp)
			// 				}
			// 			}
			// 		} else if (credit.temp && !credit.long) {
			// 			setStartDay(credit.temp.startDay)
			// 			setlongIsTemp(credit.temp)
			// 		} else if (!credit.temp && credit.long) {
			// 			setStartDay(credit.long.startDay)
			// 			setlongIsTemp(credit.long)
			// 		}
			// 	} else {
			// 		message.error('请先填写合同号')
			// 		setFieldsValue({
			// 			colmarketprice: undefined
			// 		})
			// 	}
			// }

			let colpredictprice = toNumber(getFieldValue('colpredictprice')),
				colmarketprice = toNumber(getFieldValue('colmarketprice'))
			let disc = (colpredictprice / colmarketprice).toFixed(4)
			if (getFieldValue('colmarketprice') === 0 && JSON.stringify(credit) !== '[]') {
				setProfit(true)
			} else {
				setProfit(false)
				setColrebate((disc == 'NaN' || disc + '' == 'Infinity') ? 0 : disc)
			}
		}
		if (colTypeSel == 7) { // 厂商赠送
			if (getFieldValue('colmarketprice') || getFieldValue('colmarketprice') == 0) {
				let colmarketprice = toNumber(getFieldValue('colmarketprice'))
				if (+colmarketprice === 0) {
					setProfit(true)
				} else {
					setProfit(false)
					setColrebate(0)
				}
			}
		}
	}
	//弹窗确定获取手机联系人信息
	const onSelContactName = () => {
		if (selContactPerson.length) {
			setFieldsValue({
				'contactname': selContactPerson
			})
		}
		contactPersonList.forEach(item => {
			if (item.id == selContactPersonId) {
				setFieldsValue({
					'contactmobile': item.mobile,
					'contacttel': item.tel,
					'contactfax': item.fax,
					'contactemail': item.mail,
					'contactqq': item.qq
				})
			}
		})
		setContactnameVisible(false)
	}
	//刷新余额
	const refreshAcount = () => {
		api.getOrderRefreshBalance({
			type: orderPageType == 'Direct' ? 0 : 1,
			relationId: orderPageType == 'Direct' ? relationId : location.state.qId,
      companyId: contractInfo ? contractInfo.accountId : vendor.relBranchId
		}).then(data => {
			data.available && setAvailable(data.available)
			data.credit && setCredit(data.credit)
		})
	}

	//保存分成人获取名字
	const onChangePerfStaff = (value, Option) => {
		setPerfStfName(Option.props.children)
		let colprofit = getFieldValue('colprofit')
		if(colprofit !== undefined){
			let perArr = []
			//添加提单人为第一个分成人
			perArr.push({
				stfId: userInfo.id,
				stfName: userInfo.staffName, 
				performance: (colprofit / 2).toFixed(2), 
				leaderName: userInfo.leaderName,
				department: userInfo.departmentName
			})
			//如果详情接口存在分成人列表，添加第二个分成人
			detail.perfStaff.forEach(item => {
				if(item.id == value){
					perArr.push({
						stfId: item.id,
						stfName: item.staffName, 
						performance: (colprofit / 2).toFixed(2),
						leaderName: item.leaderName, 
						department: item.departmentName
					})
				}
			})
			setPerformance(perArr)
		}
	}
	//修改实收/预计收款
	const onChangeColactualprice = val => {
		if(orderPageType == 'Direct'){
			setFieldsValue({
				'colrefund': 0
			})
		}
		if(orderPageType == 'Agent'){
			const colprice = toNumber(val)
			const colpt = toNumber(getFieldValue('colprofit'))
			const integralVal = toNumber(getFieldValue('integral'))
			let colfund = 0
			if(colrefundRadio == 0){
				colfund = colprice * 0.06
				setFieldsValue({
					'colrefund': colfund.toFixed(2),
				})
			}
			if(colrefundRadio == 1){
				colfund = colprice * 0.1
				setFieldsValue({
					'colrefund': colfund.toFixed(2),
				})
			}
			if(colrefundRadio == 2){
				colfund = toNumber(getFieldValue('colrefund'))
			}

			setFieldsValue({
				'colcost': (colprice + integralVal - colfund - colpt).toFixed(2)
			})
		}
	}

	//获取分成人信息列表
	const getPerformanceProfitForStaff = (projectId, businessId) => {
		let json = {
			userId: userInfo.id,
			colprofit: toNumber(getFieldValue('colprofit')),
			oderType: getFieldValue('figtype'),
			project: projectId,
			business: businessId,
			clientId: relationId,
			// relationId: orderPageType == 'Agent' ? location.state.qId : location.state.relationId, 
			// relationType: orderPageType == 'Agent' ? 1 : 0, 
		}, params = {}
		if(!location.state.vendorId && !location.state.id){
			params = {...json}
		}else{
			params = {...json, vendor: vendor.vendor ? vendor.vendor : location.state.vendorEdit}
		}
		api.getPerformanceProfitForStaff(params).then(res => {
			setSubmitDis(false)
			setRenSubmitDis(false)
			// setPerformance(res)
			// 获取返显分成人
			let stfIdArr = []
			let yejiArr = []
			editeleaderName = []
			editeDepartment = []
			const keys = getFieldValue('keys');
			let nextKeys = []
			idNum = 0
			res.map((v,i) => {
			  stfIdArr.push(v.stfId)
			  yejiArr.push(v.performance)
			  editeleaderName[idNum] = v.leaderName
			  editeDepartment[idNum] = v.department
			  nextKeys.push(idNum++)
			// console.log("nextKeys.push(id++)",nextKeys.push(id++))
			})
			setFieldsValue({
				keys: nextKeys,
			})
			setFieldsValue({
				'stfId': stfIdArr,
				'performance': yejiArr
			})
		}).catch(() => {
			setSubmitDis(false)
			setRenSubmitDis(false)
		})
	}
	//修改个人利润信息
	const onChangeColProfit = (val) => {
		if(val.target.value.length && +colTypeSel !== 7){
			businessId && setRenSubmitDis(true)
			businessId && getPerformanceProfitForStaff(projectId, businessId)
			// if(detail.perfStaff){//如果是客服提单（此时有分成人列表）
			// 	let perArr = []
			// 	let perfStfId = getFieldValue('perfStfId')
			// 	// console.log(perfStfId)
			// 	perArr.push({
			// 		stfId: userInfo.id,
			// 		stfName: userInfo.staffName, 
			// 		performance: perfStfId ? (val.target.value / 2).toFixed(2) : val.target.value, 
			// 		leaderName: userInfo.leaderName, 
			// 		department: userInfo.departmentName
			// 	})
			// 	if(perfStfId){
			// 		detail.perfStaff.forEach(item => {
			// 			if(item.id == perfStfId){
			// 				perArr.push({
			// 					stfId: item.id,
			// 					stfName: item.staffName, 
			// 					performance: (val.target.value / 2).toFixed(2), 
			// 					leaderName: item.leaderName, 
			// 					department: item.departmentName
			// 				})
			// 			}
			// 		})
			// 	}
			// 	setPerformance(perArr)
			// }else{
			// 	projectId && getPerformanceProfitForStaff(projectId)
			// }
		} else {
			setSubmitDis(false)
			setRenSubmitDis(false)
		}
	}

	//合同号修改
	const onClickModification = () => {
		if(isContract) {
			setIsContract(false)
			api.getContractWithVendor({vendor: vendor.vendor}).then(res => {
				setFieldsValue({contractno: res.contractNo})
				setContractNoChecked('')
				if (!Array.isArray(res)) {
					setContractInfo(res)
					setVendorContractEmptys(false)
				} else {
					setContractInfo('')
					setVendorContractEmptys(true)
				}
			})
		} else {
			setIsContract(true)
		}
	}

	const formItemLayoutTwo = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 8 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 },
		}
	}
	const formItemLayoutWithOutLabel = {
		wrapperCol: {
			xs: { span: 24, offset: 0 },
			sm: { span: 20, offset: 4 },
		},
	}

	const onSelectRen = (val, Option, k, index) => {
		staffList.forEach((item, index, arr) => {
			if (item.id === val) {
				editeleaderName[k] = item.leaderName
				editeDepartment[k] = item.departmentName
			}
		})
	}

	const labelRen = (k) => {
		return <>
			<span style={{ marginRight: 5 }}>分成人</span>
			{ k === 0 ?
				<a>
					<Icon onClick={() => add()} style={{ color: '#000' }} type="plus" />
				</a> :
				<a>
					<Icon type="minus" style={{ color: '#000' }} onClick={() => onRemovePoints(k)} />
				</a>
			}
		</>
	}

	getFieldDecorator('keys', { initialValue: [] });
	const keys = getFieldValue('keys');
	const formItems = keys.map((k, index) => (
		<Row gutter={24} key={k}>
			<Col span={8}>
				<Form.Item
					{...formItemLayoutTwo}
					label={labelRen(k)}
					labelCol={{ style: { width: '100%', height: '30px', textAlign: 'left' } }}
					required={false}
					colon={false}
					key={k}
				>
					{getFieldDecorator(`stfId[${k}]`, {
						rules: [
							{
								required: true,
								message: "请输入分成人",
							},
						],
					})(<Select
						optionFilterProp="children"
						showSearch
						notFoundContent='未搜索到相关分成人'
						onSelect={(val, Option) => onSelectRen(val, Option, k, index)}
						onChange={(val, Option) => onSelectRen(val, Option, k, index)}
						placeholder={"请输入分成人"}>
						{staffList && staffList.map(item =>
							<Option key={item.id} value={item.id} >
								{item.name}
							</Option>
						)}
					</Select>)}
				</Form.Item>
			</Col>
			<Col span={5}>
				<Form.Item
					{...formItemLayoutTwo}
					label={'业绩'}
					labelCol={{ style: { width: '100%', height: '30px', textAlign: 'left' } }}
					required={false}
					colon={false}
					key={k}
				>
					{getFieldDecorator(`performance[${k}]`, {
						rules: [
							{
								required: true,
								message: "请输入业绩",
							},
						],
					})(<InputNumber 
						step={0.01}
						// min={0} 
						// precision={2}
						placeholder="请输入" />)}
				</Form.Item>
			</Col>
			<Col span={4}>
				<Form.Item
					{...formItemLayoutTwo}
					label={'直接主管'}
					labelCol={{ style: { width: '100%', height: '30px', textAlign: 'left' } }}
					required={false}
					colon={false}
					key={k}
				>
					{getFieldDecorator(`zhuguan[${k}]`, {
					})(<span>{editeleaderName.filter((v, i) => i === k) && editeleaderName.filter((v, i) => i == k)}</span>)}
				</Form.Item>
			</Col>
			<Col span={5}>
				<Form.Item
					{...formItemLayoutTwo}
					label={'部门'}
					labelCol={{ style: { width: '100%', height: '30px', textAlign: 'left' } }}
					required={false}
					colon={false}
					key={k}
				>
					{getFieldDecorator(`bumen[${k}]`, {
					})(<span>{editeDepartment.filter((v, i) => i === k) && editeDepartment.filter((v, i) => i == k)}</span>)}
				</Form.Item>
			</Col>
		</Row>
	))

	const add = () => {
		const keys = getFieldValue('keys')
		const nextKeys = keys.concat(idNum++)
		setFieldsValue({
			keys: nextKeys,
		})
	}

	const onRemovePoints = (k) => {
		const keys = getFieldValue('keys')
		if (keys.length === 1) {
			return
		}
		setFieldsValue({
			keys: keys.filter(key => key !== k),
		})
	}

	return (
		<Spin spinning={loading}>
			<Form onSubmit={searchSubmit} {...formItemLayout} className="input-number-none">
				<div className="add-orderDirect-detail">
					<Descriptions 
						title={`提交订单-${orderPageType == 'Agent' ? '代理商' : '直销'}${vendorId ? '-续费' : pageId ? '' : '-开单'}${oderDetail.base && oderDetail.base.number ? "(" + oderDetail.base.number + ")" : ""}`} 
						layout="vertical" 
						className="pro-detail-title" 
						column={5} 
						style={{ borderBottom: "none" }}
					>
						<dItem label="客户名称">{client.name}</dItem>
						<dItem label="OAID">{relationId}</dItem>
          	{detail.orderType && !Array.isArray(detail.orderType) && <dItem label="类型">{detail.orderType.name}</dItem>}
						{vendor.vendor && <dItem label="厂商ID">{vendor.vendor}</dItem>}
						{vendor.vendor && orderPageType == 'Direct' && <dItem label="所属销售">{vendor.relSalerName}</dItem>}
						{vendor.vendor && orderPageType == 'Direct' && <dItem label="所属客服">{vendor.relServicerName}</dItem>}
						{vendor.vendor && orderPageType == 'Agent' && <dItem label="代理商所属开发">{vendor.relAgentStaffName}</dItem>}
						{vendor.vendor && orderPageType == 'Agent' && <dItem label="提单人">{detail.currentUserName}</dItem>}
					</Descriptions>
					<div className="blank-line"></div>
					{/* 
						新开，必须有合同号
						续费，contractinfo为空必须填写合同号否则隐藏合同号 && vendorContractEmpty == true
						驳回重提，根据contractinfo如果有内容显示合同相关输入框，如果没有为空则不显示合同相关内容
					*/}
					{(newAdd || (vendorId) || (pageId && oderDetail.contractInfo && oderDetail.contractInfo.contractNo)) ? <>
					<div className="ask-info">
						<div className="record-list-title">合同信息</div>
						<Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none" }}>
							<dItem label={orderPageType == 'Direct' ? '客户名称' : '代理商名称'}>
								{
									vendorId && vendorContractEmptys == true ? '—' : <div className="dItem-txt-box">
										{contractInfo.customerName}
										<div>
											{contractInfo.customerId ? `ID: ${contractInfo.customerId}` : ''}
										</div>
									</div>
								}
							</dItem>
							<dItem label={<><span className="required-label">*</span> 合同号</>}>
								{(vendorId == null) ? <FormItem className="colrefund-item" label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('contractno', {
										rules: [{ required: true, message: '合同号不能为空' }]
									})(<Input
											disabled={pageId && !ifEditBh}
											style={{ width: '200px' }}
											placeholder="请输入合同号"
											onPressEnter={e => {
												e.preventDefault()
												onGetFortuneOrderContract(e)
											}}
											onBlur={onGetFortuneOrderContract}
										/>)
									}
								</FormItem> :
								<FormItem label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('contractno', {
										initialValue: contractInfo.businessNo,
										rules: [{ required: vendorId && vendorContractEmptys == true ? false : true, message: '合同号不能为空' }]
									})(<Input
											type={isContract ? 'text' : 'hidden'}
											disabled={pageId && !ifEditBh}
											style={{ width: '200px' }}
											placeholder="请输入合同号"
											onPressEnter={e => {
												e.preventDefault()
												onGetFortuneOrderContract(e)
											}}
											onBlur={onGetFortuneOrderContract}
									/>)}
									<div style={{ height: '54px', minWidth: '200px' }}>
										{
											isContract ? '' : 
											vendorId && vendorContractEmptys == true ? '—' : <Link target="_blank" to={`/protocol/contract/details?id=${contractInfo.id}&time=${+new Date()}&sign=${CryptoJS.MD5(contractInfo.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{contractInfo.contractNo}</Link>
										}
										<span onClick={onClickModification} style={{ color: 'blue', marginLeft: '10px', cursor: 'pointer' }}>{isContract ? '取消' : '修改'}</span>
									</div>
								</FormItem>}
							</dItem>
							<dItem label="合同名称">{vendorId && vendorContractEmptys == true ? '—' : <div className="dItem-txt-box">{contractInfo.templateName ? contractInfo.templateName : contractInfo.templateTypeName ? contractInfo.templateTypeName : ''}</div>}</dItem>
							<dItem label="合同主体">
								{
									vendorId && vendorContractEmptys == true ? '—' : <div className="dItem-txt-box">
										<div>{contractInfo.clientSource == 1 ? contractInfo.partA : contractInfo.partB}</div>
										{/* {contractInfo.accountName} */}
									</div>
								}
							</dItem>
							<dItem label="合同有效期">{vendorId && vendorContractEmptys == true ? '—' : <div className="dItem-txt-box">{contractInfo ? (!contractInfo.isExpired ? contractInfo.endTime == '0000-00-00' ? '长期合同' : `${contractInfo.beginTime} ${contractInfo.endTime ? ' - ' + contractInfo.endTime : ''}` : `已过期`) : '-'}</div>}</dItem>
						</Descriptions>
					</div>
					<div className="blank-line"></div>
					</> : null}
					<div className="ask-info">
						<div className="record-list-title">订单联系人</div>
						<Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none" }}> 
							<dItem label={<><span className="required-label">*</span> 订单联系人</>}>
								<FormItem label="" colon={false} style={{ width: '100%' }}>
									<Row gutter={20}>
										<Col span={20}>
											{getFieldDecorator('contactname', {
												rules: [{ required: true, message: '订单联系人不能为空' }]
											})(<Input
													readOnly={pageId && !ifEditBh}
													style={{ width: '130px' }}
													placeholder="请输入订单联系人"
												/>)}
										</Col>
										<Col span={4}>
											<Button disabled={pageId} type="primary" style={{ padding: '0 5px' }} onClick={getFortuneOrderContact}>选择联系人</Button>
										</Col>
									</Row>
								</FormItem>
							</dItem>
							{
								orderPageType == 'Direct' ? <dItem label={<><span className="required-label">*</span> 手机</>}>
								<FormItem label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('contactmobile', {
										rules: [{ required: true, message: '手机号不能为空' },
										{
											pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: '手机号格式不正确'
										}
										]
									})(<Input
											readOnly={pageId && !ifEditBh}
											style={{ width: '200px' }}
											placeholder="请输入手机号"
										/>)}
								</FormItem>
							</dItem> : <dItem label="手机">
								<FormItem label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('contactmobile', {
										rules: [{ required: false, message: '手机号不能为空' }]
									})(<Input
											readOnly={pageId && !ifEditBh}
											style={{ width: '200px' }}
											placeholder="请输入手机号"
										/>)}
								</FormItem>
							</dItem>
							}
							<dItem label="座机">
								<FormItem label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('contacttel', {
										rules: [{ required: false, message: '座机号不能为空' },
										{
											pattern: /^((0\d{2,3})-*)*(\d{7,8})(-(\d{3,}))?$/, message: '座机号格式不正确'
											// pattern: /^\d{7,}$/, message: '座机号格式不正确'
										}]
									})(<Input
											readOnly={pageId && !ifEditBh}
											style={{ width: '200px' }}
											placeholder="请输入座机号"
										/>)}
								</FormItem>
							</dItem>
							<dItem label="传真">
								<FormItem label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('contactfax', {
										rules: [{ required: false, message: '传真号不能为空' },
										{
											// pattern: /^((0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/, message: '传真号格式不正确'
										}]
									})(<Input
											readOnly={pageId && !ifEditBh}
											style={{ width: '200px' }}
											placeholder="请输入传真号"
										/>)}
								</FormItem>
							</dItem>
							{
								orderPageType == 'Direct' ? <dItem label={<><span className="required-label">*</span> 邮箱</>}>
								<FormItem label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('contactemail', {
										rules: [{ required: true, message: '邮箱不能为空' },
										{
											pattern: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{1,30})$/, message: '邮箱格式不正确'
										}
										]
									})(<Input
											readOnly={pageId && !ifEditBh}
											style={{ width: '200px' }}
											placeholder="请输入邮箱"
										/>)}
								</FormItem>
							</dItem> : <dItem label="邮箱">
								<FormItem label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('contactemail', {
										rules: [{ required: false, message: '邮箱不能为空' }]
									})(<Input
											readOnly={pageId && !ifEditBh}
											style={{ width: '200px' }}
											placeholder="请输入邮箱"
										/>)}
								</FormItem>
							</dItem>
							}
							<dItem label="QQ">
								<FormItem label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('contactqq', {
										rules: [{ required: false, message: 'QQ号不能为空' },
										{
											pattern: /^\d{4,}$/, message: 'QQ号格式不正确'
										}]
									})(<Input
										readOnly={pageId && !ifEditBh}
										style={{ width: '200px' }}
										placeholder="请输入QQ号"
									/>)}
								</FormItem>
							</dItem>
							{/* <dItem label="网址">
							<FormItem label="" colon={false} style={{width: '100%'}}>
								{getFieldDecorator('contactweb',  {
										rules: [{ required: false, message: '' }]
									})(<Input
									readOnly={pageId && !ifEditBh}
									style={{width: '200px'}}
									placeholder="请输入网址"
								/>)}
							</FormItem>
						</dItem>
						<dItem label={<><span className="required-label">*</span> 通信地址</>}>
							<FormItem label="" colon={false} style={{width: '100%'}}>
								{getFieldDecorator('contactaddress',  {
										rules: [{ required: true, message: '通信地址不能为空' }]
									})(<Input.TextArea
									readOnly={pageId && !ifEditBh}
									style={{width: '200px'}}
									placeholder="请输入通信地址"
								/>)}
							</FormItem>
						</dItem> */}
						</Descriptions>
					</div>
					<div className="blank-line"></div>
					<div className="ask-info">
						<div className="record-list-title">订单信息</div>
						<Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none" }}>
							{/* <dItem label={!newAdd && !contractInfo ? <><span className="required-label">*</span> 项目</> : <><span className="required-label">*</span> 项目(需先输入合同信息)</>}> */}
							<dItem label={<><span className="required-label">*</span> 项目</>}>
								{vendorId == null && pageId === null ? 
									<FormItem label="" colon={false} style={{ width: '100%' }}>
										{getFieldDecorator('project', {
											rules: [{ required: true, message: '项目不能为空' }]
										})(<Select
											placeholder="请选择项目"
											style={{ width: '200px' }}
											onChange={onChangeProject}
											labelInValue
											showSearch
											optionFilterProp="children"
										>
											{
												staffProjects.map(item => {
													return <Option key={item.id}>{item.name}</Option>
												})
											}
										</Select>)}
									</FormItem> :
									<FormItem label="" colon={false} style={{ width: '100%' }}>
										{getFieldDecorator('project', {
											initialValue: vendorId == null ? orderInfo.project : projectId,
										})(<Input
											type="hidden"
											style={{ width: '200px' }}
										/>)}
										<div className="dItem-txt-box">{vendorId == null ? orderInfo.projectName : projectName}</div>
									</FormItem>}
							</dItem>
							<dItem label={projectId ? <><span className="required-label">*</span> 产品</> : <><span className="required-label">*</span> 产品(需先选择项目)</>}>
								{pageId === null ? <FormItem label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('product', {
										rules: [{ required: true, message: '产品不能为空' }]
									})(<Select
											placeholder="请选择产品"
											style={{ width: '200px' }}
											onChange={onChangeProduct}
											labelInValue
											showSearch
											optionFilterProp="children"
										>
										{
											productList.map(item => {
												return <Option key={item.id}>{item.name}</Option>
											})
										}
									</Select>)}
								</FormItem> :
									<FormItem label="" colon={false} style={{ width: '100%' }}>
										{getFieldDecorator('product', {
											initialValue: orderInfo.product,
										})(<Input
												type="hidden"
												style={{ width: '200px' }}
											/>)}
										<div className="dItem-txt-box">{orderInfo.productName}</div>
									</FormItem>}
							</dItem>
							<dItem label={productId ? <><span className="required-label">*</span> 业务类型</> : <><span className="required-label">*</span> 业务类型(请先选择产品)</>}>
								{pageId === null ? <FormItem label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('business', {
										rules: [{ required: true, message: '业务类型不能为空' }]
									})(<Select
											placeholder="请选择业务类型"
											style={{ width: '200px' }}
											onChange={onChangeBusiness}
										>
										{
											businessList.map(item => {
												return <Option key={item.id}>{item.name}</Option>
											})
										}
									</Select>)}
								</FormItem> :
									<FormItem label="" colon={false} style={{ width: '100%' }}>
										{getFieldDecorator('business')(<Input
											type="hidden"
											style={{ width: '200px' }}
										/>)}
										<div className="dItem-txt-box">{rowData && rowData.businessName}</div>
									</FormItem>}
							</dItem>
						</Descriptions>
						<Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none" }}>
							<dItem label={<><span className="required-label">*</span> 业绩类型</>}>
								<FormItem label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('figtype', {
										// initialValue: vendorId === null ? 1 : vendor.relServicer ? 3 : 2,
										initialValue: vendorId === null ? 1 : 2,
										rules: [{ required: true, message: '业绩类型为必选项' }]
										// })(<Radio.Group style={{width: '200px'}} disabled={vendorId !== null || pageId !== null ? true : false}>
									// })(<Radio.Group style={{ width: '200px' }} disabled={vendorId && vendor.relServicer ? true : false}>
									})(<Radio.Group style={{ width: '200px' }} onChange={(e)=> {
										if (e.target.value === 2 || e.target.value === 3) {
											setFieldsValue({
												colprofit: undefined
											})
											// setPerformance([])
										}
									}} disabled={(pageId && !ifEditBh) || (pageId && !ifEditBh && vendorId !== null)}>
										{
											//新开和续费的时候从detail.figure去读，驳回重提的时候从row里面去读
											pageId == null ? detail.figure && detail.figure.map((v, i) => <Radio value={v.id} key={i}>{v.name}</Radio>) :
											<Radio value={rowData.figtype}>{
												(() => {
													if(rowData.figtype == 1) return '新开'
													if(rowData.figtype == 2) return '续费'
													if(rowData.figtype == 3) return '续费算新开'
												})()
											}</Radio>
										}
									</Radio.Group>)}
								</FormItem>
							</dItem>
							{/* 2020年12月12日 产品要求去掉该字段 */}
							{/* <dItem label={<><span className="required-label">*</span> 是否撤资重开订单</>}>
								{pageId === null ? <FormItem label="" colon={false} style={{ width: '100%' }}>{getFieldDecorator('isodfdivest', {
									// initialValue: 1
									rules: [{ required: true, message: '请选择' }]
								})(<Radio.Group style={{ width: '200px' }}>
									<Radio value={1}>是</Radio>
									<Radio value={0}>否</Radio>
								</Radio.Group>)}</FormItem> : <FormItem label="" colon={false} style={{ width: '100%' }}>{getFieldDecorator('isodfdivest', {
									initialValue: rowData && rowData.isodfdivest === '否' ? 0 : 1,
									rules: [{ required: true, message: '请选择' }]
								})(<Radio.Group style={{ width: '200px' }}>
									<Radio value={1}>是</Radio>
									<Radio value={0}>否</Radio>
								</Radio.Group>)}</FormItem>}
							</dItem> */}
							{orderPageType == 'Direct' && <dItem label="是否退转订单">
								{pageId === null ? <FormItem label="" colon={false} style={{ width: '100%' }}>{getFieldDecorator('isodftrun', {
									// initialValue: 1
									rules: [{ required: false, message: '请选择是否退转订单' }]
								})(<Radio.Group style={{ width: '200px' }}>
									<Radio value={1}>是</Radio>
									<Radio value={0}>否</Radio>
								</Radio.Group>)}</FormItem> : <FormItem label="" colon={false} style={{ width: '100%' }}>{getFieldDecorator('isodftrun', {
									initialValue: rowData && +rowData.isodftrun,
									rules: [{ required: false, message: '请选择是否退转订单' }]
								})(<Radio.Group style={{ width: '200px' }} disabled>
									<Radio value={1}>是</Radio>
									<Radio value={0}>否</Radio>
								</Radio.Group>)}</FormItem>}
							</dItem>}
							{orderPageType == 'Direct' && (pageId === null ? <dItem label="是否框架订单">{contractInfo ? contractInfo.isFrame ? '是' : '否' : '-'}</dItem> :
							<dItem label="是否框架订单">{rowData ? rowData.isodfkjia ? '是' : '否' : '-'}</dItem>)}
							{orderPageType == 'Direct' && (pageId === null ? <dItem label="销售主项">
								{parentIsMainProject ? parentIsMainProject == '--' ? '--' : '主项' : '副项'}
							</dItem> : <dItem label="销售主项">
								{rowData ? rowData.majorminor == 1 ? '主项' : '副项' : '--'}
							</dItem>)}
							{orderPageType === 'Agent' ? detail.orderType && detail.orderType.name == '直销渠道' ? '' : 
							<dItem label={<><span className="required-label">*</span> 是否计业绩</>}>
								<FormItem label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('isPerformance', {
										initialValue: 0,
										rules: [{ required: true, message: '是否计业绩为必选项' }]
									})(<Radio.Group style={{ width: '200px' }} disabled={pageId && !ifEditBh} onChange={(e) => {
										if (e.target.value == 1) {
											setIsColprofit(false)
											setFieldsValue({
												colprofit: undefined
											})
										} else {
											setIsColprofit(true)
											setFieldsValue({
												colprofit: '0.00'
											})
											// 选择是，后改为否，分成人需要清空
											const keys = getFieldValue('keys');
											const nextKeys = []
											idNum = 0
											setFieldsValue({
												keys: nextKeys,
											})
										}
									}}>
											<Radio value={0}>否</Radio>
											<Radio value={1}>是</Radio>
									</Radio.Group>)}
								</FormItem>
							</dItem> : null}
						</Descriptions>
						{orderPageType === 'Direct' && <div style={{width: 0, height: 0, overflow: 'hidden'}}><Descriptions layout="horizontal" className="pro-detail-title" column={4} style={{ borderBottom: "none" }}>
							<dItem label={<><span className="required-label">*</span> 是否计业绩</>}>
								{getFieldDecorator('isPerformance', {
									initialValue: 1
								})(<Radio.Group style={{ width: '200px' }} disabled>
										<Radio value={0}>否</Radio>
										<Radio value={1}>是</Radio>
								</Radio.Group>)}
							</dItem>
						</Descriptions></div>}
					</div>
					<div className="blank-line"></div>
					<div className="ask-info">
						<div className="record-list-title">订单资料</div>
						<Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none" }}>
							<dItem label={<><span className="required-label">*</span> 公司名称</>}>
								<FormItem label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('companyName', {
										rules: [{ required: true, message: '请输入公司名称' }]
									})(<Input
											style={{ width: '200px' }}
											placeholder="请输入公司名称"
										/>)
									}
								</FormItem>
							</dItem>
							<dItem label={projectId ?
								<>
									<span className="required-label">*</span> 供应商
								</>
								:
								<>
									<span className="required-label">*</span> 供应商(需先选择项目)
								</>
							}>
								<FormItem label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('supplier', {
										rules: [{ required: true, message: '供应商不能为空' }]
									})(<Select
											placeholder="请选择供应商"
											style={{ width: '200px' }}
											onChange={getSupplierName}
											labelInValue
											showSearch
											optionFilterProp="children"
										>
										{
											supplierList.map(item => {
												return <Option key={item.id}>{item.name}</Option>
											})
										}
									</Select>)}
								</FormItem>
							</dItem>
							{supplierCompanyList.length > 0 && <dItem label="提单公司">
								<FormItem label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('supplierCompanyId', {
										rules: [{ required: false, message: '提单公司不能为空' }]
									})(<Select
											placeholder="请选择提单公司"
											style={{ width: '200px' }}
											onChange={onChangeSupComp}
											labelInValue
											showSearch
											optionFilterProp="children"
										>
										{
											supplierCompanyList.map(item => {
												return <Option key={item.id}>{item.name}</Option>
											})
										}
									</Select>)}
								</FormItem>
							</dItem>}
						</Descriptions>
						<Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none" }}>
							<dItem label={supplierId ? "一级行业" : "一级行业(需先选择供应商)"}>
								<FormItem label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('industry1', {
										rules: [{ required: false, message: '一级行业不能为空' }]
									})(<Select
											placeholder="请选择一级行业"
											style={{ width: '200px' }}
											onChange={onChangeIndustry}
											labelInValue
											showSearch
											optionFilterProp="children"
										>
										{
											industryList.map(item => {
												return <Option key={item.id}>{item.name}</Option>
											})
										}
									</Select>)}
								</FormItem>
							</dItem>
							<dItem label="二级行业">
								<FormItem label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('industry2', {
										rules: [{ required: false, message: '二级行业不能为空' }]
									})(<Select
											placeholder="请选择二级行业"
											style={{ width: '200px' }}
											onChange={onChangeIndustry2}
											labelInValue
											showSearch
											optionFilterProp="children"
										>
										{
											industryList2.map(item => {
												return <Option key={item.id}>{item.name}</Option>
											})
										}
									</Select>)}
								</FormItem>
							</dItem>
							{
								businessId && attachList.map((item =>
									<dItem 
										label={item.isRequired ? <><span className="required-label">*</span> {item.name !== '备注' ? item.name : <span className='font-striking'>备注</span>}</> 
										: (item.name !== '备注' ? item.name : <span className='font-striking'>备注</span>)} 
										key={item.id}
									>
										<FormItem label="" colon={false} style={{ width: '100%' }}>
											{getFieldDecorator(`attach-${item.id}-${item.name}`, {
												rules: [{ required: item.isRequired, message: `请输入${item.name}名称` }]
											})(<Input
													className={item.name !== '备注' ? '' : 'font-striking-content'}
													style={{ width: '200px' }}
													placeholder={`请输入${item.name}`}
												/>)
											}
										</FormItem>
									</dItem>
								))
							}
						</Descriptions>
					</div>
					<div className="blank-line"></div>
					<div className="ask-info">
						<div className="record-list-title">订单收款信息</div>
						<Spin spinning={renSubmitDis}>
						<Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none" }}>
							<dItem label={<><span className="required-label">*</span> 收款类型</>}>
								<FormItem label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('coltype', {
										rules: [{ required: true, message: '收款类型不能为空' }]
									})(<Select
											placeholder="请选择收款类型"
											style={{ width: '200px' }}
											onChange={onChangeColType}
											disabled={pageId && !ifEditBh}
										>
										{
											detail.coltype && detail.coltype.map(item => {
												return <Option key={item.id}>{item.name}</Option>
											})
										}
									</Select>)}
								</FormItem>
							</dItem>
							{colTypeSel == 1 &&
								<dItem label="可用余额">
									<div className="dItem-txt-box">
										￥ {available && available.balance ? available.balance : 0}
										{/* <Link target="_blank" to={`/fund/moneyClaim`}> 认款</Link> */}
										<Link target="_blank" to={`/fund/moneyClaim/detail?clientId=${orderPageType == 'Direct' ? relationId : location.state.qId}&clientType=${orderPageType == 'Direct' ? 0 : 1}`}> 认款</Link>
										{(newAdd || vendorId) && <span onClick={refreshAcount} style={{color: '#1890ff', cursor: 'pointer', marginLeft: '5px'}}>刷新余额</span>}
									</div>
								</dItem>}
							{(colTypeSel == 1 || colTypeSel == 3) && orderPageType == 'Agent' && <dItem label="可用积分">
								<div className="dItem-txt-box">
									￥ {integral ? integral : '0'}
								</div>
							</dItem>}
							{colTypeSel == 2 && !creditFlag &&<dItem label="信用额度">
								<div className="dItem-txt-box">
									{
										<FormItem label="" colon={false} style={{ width: '100%' }}>
											{getFieldDecorator('creditAccountId', {
												initialValue: credit.length == 1 ? credit[0].id : '',
												rules: [{ required: true, message: '请选择信用额度' }]
											})(<Radio.Group>
												{/* {
													console.log(credit, projectName)
												} */}
												{
													credit.length > 0 && credit.map(item => {
														if (item.projectNames.split(',').indexOf(projectName) > -1) {
															return <Radio disabled={pageId && !ifEditBh} value={item.id} key={item.id}>￥ {item.trueAmount}（{item.accountType == 'long' ? '长期额度' : '临时额度'}，账户ID：{item.id}）
																{
																	pageId && !ifEditBh ? null : (
																		<Link target="_blank" to={`/account/creditapply/edit?customerType=${orderPageType == 'Direct' ? 0 : 1}&customerId=${item.oaqId}&accountType=${item.accountType == 'long' ? 0 : 1}&company=${item.company}&trueAmount=${item.trueAmount}&usedAmount=${item.usedAmount}&accountId=${item.id}&companyId=${item.companyId}&ids=${item.id}`}>调整</Link>
																	)
																}
															</Radio>
														}
													})
												}
												{/* {
													credit.length > 0 && credit.map(item=> {
														return <Radio value={item.id} key={item.id}>￥ {item.trueAmount}（{item.accountType == 'long' ? '长期额度' : '临时额度'}，账户ID：{item.id}）
														<Link target="_blank" to={`/account/creditapply/add?customerType=${orderPageType == 'Direct' ? 0 : 1}&customerId=${location.state.relationId}&qId=${location.state.qId}`}>调整</Link>
														</Radio>
													})
												} */}
											  </Radio.Group>)}
										</FormItem>
									}
									{/* {longcreditFlag && credit.long && (credit.long.trueAmount || credit.long.trueAmount == 0) ? `长期额度: ${credit.long.trueAmount} ` : `您未申请额度 `}
									{tempcreditFlag && credit.temp && (credit.temp.trueAmount || credit.temp.trueAmount == 0) && `临时额度: ${credit.temp.trueAmount} `}
									{tempcreditFlag && credit.temp && credit.temp.endDay && `(有效期: ${credit.temp.endDay}）`} */}
									{/* {
										<Link target="_blank" to={`/account/creditapply/add?customerType=${orderPageType == 'Direct' ? 0 : 1}&customerId=${location.state.relationId}&qId=${location.state.qId}`}>
											{credit.long && (credit.long.trueAmount || credit.long.trueAmount == 0) ? '提升额度' : '申请额度'}
										</Link>
									} */}
								</div>
							</dItem>}
							{/* 判断信用账户的关联的项目是否包含合同中的项目 */}
							{colTypeSel == 2 && creditFlag && <dItem label="信用额度">
								<div className="dItem-txt-box">
									{`您的信用账户中未包含该项目，请通过"`}
									{
										orderPageType == 'Agent' ? <Link target="_blank" to={`/account/credit?id=${location.state.qId}&type=${1}`}>
											{'调整信用账户'}</Link> : <Link to={`/account/credit?id=${relationId}&type=${0}`}>{'调整信用账户'}</Link>
									}
									{`"添加该项目`}
								</div>
							</dItem>}
						</Descriptions>
						<Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none" }}>
							<dItem label={<><span className="required-label">*</span> 市场价</>}>
								<FormItem className="colrefund-item" label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('colmarketprice', {
										rules: [{ required: true, message: '请输入市场价' }],
										initialValue: colTypeSel == 7 ? 0 : ''
									})(<InputNumber
												step={0.01}
												precision={2}
												min={0}
												style={{ width: '200px' }}
												placeholder="请输入市场价"
												onChange={onChangeColrebate}
												onBlur={() => getColrebate(1)}
												disabled={pageId && !ifEditBh}
											/>
										)
									}
								</FormItem>
							</dItem>
							{(colTypeSel == 1 || colTypeSel == 3 || colTypeSel == 7) && <dItem label={<><span className="required-label">*</span> 实收 (折扣率: {
								(() => {
									let val1 = toNumber(getFieldValue('colactualprice'))
									let val2 = toNumber(getFieldValue('colmarketprice'))
									return val2 !== 0 ? (((val1 / val2) * 100).toFixed(2) ? ((val1 / val2) * 100).toFixed(2) + '%' : '') : '0.00%'
								})()
							})</>}>
								<FormItem className="colrefund-item" label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('colactualprice', {
										rules: [{ required: true, message: '请输入实收价' }],
										initialValue: colTypeSel == 7 ? 0 : ''
									})(<InputNumber
												step={0.01}
												precision={2}
												min={0}
												readOnly={colTypeSel == 7 ? true : false}
												style={{ width: '200px' }}
												placeholder="请输入实收价"
												onBlur={getColrebate}
												disabled={pageId && !ifEditBh}
												onChange={onChangeColactualprice}
											/>
										)
									}
								</FormItem>
							</dItem>}
							{colTypeSel == 2 && <dItem label={<><span className="required-label">*</span> 预计收款 (折扣率: {
								(() => {
									let val1 = toNumber(getFieldValue('colpredictprice'))
									let val2 = toNumber(getFieldValue('colmarketprice'))
									return val2 !== 0 ? ((val1 / val2) * 100).toFixed(2) + '%' : '0.00%'
								})()
							})</>}>
								<FormItem className="colrefund-item" label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('colpredictprice', {
										rules: [{ required: true, message: '请输入预计收款' }]
									})(<InputNumber
												step={0.01}
												precision={2}
												min={0}
												style={{ width: '200px' }}
												placeholder="请输入预计收款"
												onBlur={getColrebate}
												disabled={pageId && !ifEditBh}
												onChange={onChangeColactualprice}
											/>
										)
									}
								</FormItem>
							</dItem>}
							{colrebate !== null  && !isNaN(colrebate) && <dItem label={<><span className="required-label">*</span> 折扣</>}>
								<div className="dItem-txt-box">{profit ? '纯利': colrebate}</div>
							</dItem>}
						</Descriptions>
						{(colTypeSel == 1 || colTypeSel == 3) && orderPageType == 'Agent' && <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none" }}>
							<dItem label={<><span className="required-label">*</span> 使用积分抵扣</>}>
								<FormItem className="colrefund-item" label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('integral', {
										rules: [{ required: true, message: '请输入积分' }, { 
                                            pattern: /^\d+$/, message: '请输入0或正整数'
                                        }],
										initialValue: orderPageType == 'Direct' ? undefined : '0'
									})(<InputNumber
												step={1}
												// precision={2}
												min={0}
												style={{ width: '200px' }}
												placeholder="请输入积分"
												disabled={pageId && !ifEditBh}
												onBlur={getColrebate}
												onChange={val => {
													if(orderPageType === 'Agent'){
														const integralVal = toNumber(val)
														const colprice = toNumber(colTypeSel != 2 ? getFieldValue('colactualprice') : getFieldValue('colpredictprice'))
														const colfund = toNumber(getFieldValue('colrefund'))
														const colpt = toNumber(getFieldValue('colprofit'))
														setFieldsValue({
															'colcost': (colprice + integralVal - colfund - colpt).toFixed(2)
														})
													}
												}}
											/>
										)
									}
								</FormItem>
							</dItem>
						</Descriptions>}

						<Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none" }}>
							{colTypeSel && <dItem label={<><span className="required-label">*</span> 成本</>}>
								<FormItem className="colrefund-item" label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('colcost', {
										rules: [{ required: true, message: '请输入成本' }],
										initialValue: colTypeSel == 7 ? 0 : ''
									})(<InputNumber
												step={0.01}
												precision={2}
												// min={0}
												readOnly={colTypeSel == 7 ? true : false}
												style={{ width: '200px' }}
												placeholder={orderPageType == 'Direct' ? "请输入成本" : ''}
												disabled={orderPageType == 'Agent' || (pageId && !ifEditBh)}
											/>
										)
									}
								</FormItem>
							</dItem>}
							{colTypeSel && <dItem className="colrefund-item" label={orderPageType == 'Direct' ? <><span className="required-label">*</span> 调整项</> : 
								<><span className="required-label">*</span> 调整项&nbsp;
									<Radio.Group 
										value={colrefundRadio}
										disabled={pageId && !ifEditBh}
										onChange={e => {
											setColrefundRadio(e.target.value)
											const colprice = toNumber(colTypeSel != 2 ? getFieldValue('colactualprice') : getFieldValue('colpredictprice'))
											const colpt = toNumber(getFieldValue('colprofit'))
											let integralVal = 0
											let colfund = 0
											if(orderPageType == 'Agent'){
												integralVal = toNumber(getFieldValue('integral'))
											}
											if(e.target.value == 0){
												colfund = colprice * 0.06
											}
											if(e.target.value == 1){
												colfund = colprice * 0.1
											}
											setFieldsValue({
												'colrefund': colfund.toFixed(2),
												'colcost': (colprice + integralVal - colfund - colpt).toFixed(2)
											})
										}}
									>
										<Radio value={0}>6%</Radio>
										<Radio value={1}>10%</Radio>
										<Radio value={2}>其它</Radio>
									</Radio.Group>
								</>
								}>
								<FormItem label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('colrefund', {
										rules: [{ required: true, message: '请输入调整项' }],
										initialValue: colTypeSel == 7 ? 0 : ''
									})(<InputNumber
												step={0.01}
												precision={2}
												// min={0}
												readOnly={colTypeSel == 7 ? true : false}
												disabled={(pageId && !ifEditBh) || (orderPageType == 'Agent' && (colrefundRadio == 0 || colrefundRadio == 1 || (!getFieldValue('colactualprice') && !getFieldValue('colpredictprice'))))}
												style={{ width: '200px' }}
												placeholder={orderPageType == 'Direct' ? "请输入调整项" : ''}
												onChange={val => {
													if(orderPageType == 'Agent'){
														setColrefundRadio(2)
														const colfund = toNumber(val)
														const colprice = toNumber(colTypeSel != 2 ? getFieldValue('colactualprice') : getFieldValue('colpredictprice'))
														const colpt = toNumber(getFieldValue('colprofit'))
														const integralVal = toNumber(getFieldValue('integral'))
														setFieldsValue({
															'colcost': (colprice + integralVal - colfund - colpt).toFixed(2)
														})					
													}
												}}
											/>
										)
									}
								</FormItem>
							</dItem>}
							{colTypeSel && <dItem label={<><span className="required-label">*</span> 个人利润</>}>
								<FormItem className="colrefund-item" label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('colprofit', {
										rules: [{ required: true, message: '请输入个人利润' }],
										initialValue: orderPageType == 'Agent' ? detail.orderType && detail.orderType.name == '渠道' ? 0 : undefined : undefined
									})(<InputNumber
												step={0.01}
												precision={2}
												// min={0}
												readOnly={colTypeSel == 7 ? true : false}
												style={{ width: '200px' }}
												placeholder="请输入个人利润"
												disabled={isColprofit || (pageId && !ifEditBh)}
												onChange={val => {
													if(orderPageType == 'Agent'){
														const colpt = toNumber(val)
														const colprice = toNumber(colTypeSel != 2 ? getFieldValue('colactualprice') : getFieldValue('colpredictprice'))
														const colfund = toNumber(getFieldValue('colrefund'))
														const integralVal = toNumber(getFieldValue('integral'))
														setFieldsValue({
															'colcost': (colprice + integralVal - colfund - colpt).toFixed(2)
														})
													}
												}}
												onFocus={val => {
													// !performance.length && onChangeColProfit(val)
													setSubmitDis(true)
												}}
												onBlur={val => {
													onChangeColProfit(val)
												}}
											/>
										)
									}
								</FormItem>
							</dItem>}
							{/* {detail.perfStaff && <dItem label="分成人">
								<FormItem label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('perfStfId', {
										// rules: [{ required: true, message: '请选择分成人' }]
									})(<Select
										placeholder="请选择分成人"
										style={{ width: '200px' }}
										onSelect={onChangePerfStaff}
										showSearch={true}
              			optionFilterProp="children"
										disabled={pageId && !ifEditBh}
									>
										{
											detail.perfStaff && detail.perfStaff.map(item => {
												return <Option key={item.id} value={item.id}>{item.name}</Option>
											})
										}
									</Select>)}
								</FormItem>
							</dItem>} */}
						</Descriptions>
						<Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none" }}>
							<dItem label="提单人">{userInfo && userInfo.staffName}</dItem>
							<dItem label="直接主管">{userInfo && userInfo.leaderName}</dItem>
							<dItem label="部门">{userInfo && userInfo.departmentName}</dItem>
						</Descriptions>
						{formItems}
						</Spin>
						{/* <Form.Item {...formItemLayoutWithOutLabel}>
							<Button type="dashed" onClick={add} style={{ width: '60%' }}>
								<Icon type="plus" /> 添加分成人
						    </Button>
						</Form.Item> */}

                       {/* 2118 订单业绩详情需求汇总 分成人改为可编辑 */}
						{/* {performance.length > 0 && <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none", marginBottom: "-30px"}}>
							<dItem label="分成人"></dItem>
							<dItem label="分成人业绩"></dItem>
							<dItem label="分成人直接主管"></dItem>
							<dItem label="分成人部门"></dItem>
						</Descriptions>}
						{performance.map((item, index) => (<Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{borderBottom: "none", marginBottom: "-10px"}}>
							<dItem label="">{item.stfName}</dItem>
							<dItem label="">{item.performance}</dItem>
							<dItem label="">{item.leaderName}</dItem>
							<dItem label="">{item.department}</dItem>
						</Descriptions>))} */}
					</div>
					<div className="blank-line"></div>
					<div className="ask-info">
						<div className="record-list-title">订单附件</div>
						<div className="dragger-box" ref={draggerRef} style={{ width: '50%' }}>
							<Dragger
								{...uploadFiles}
							>
								<p className="ant-upload-drag-icon">
									<Icon type="inbox" />
								</p>
								<p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
								<p className="ant-upload-hint">
									附件支持格式pptx、PDF、doc、docx、csv、xlsx、png、gif、jpg、jpeg、bmp等文件  支持添加多个附件，单个附件大小不超过10M，文件数量不超过15个。
							</p>
							</Dragger>
						</div>
					</div>
					<div className="blank-line"></div>
					<div className="ask-info">
						<Descriptions title={<span className="font-striking">备注</span>} layout="horizontal" className="pro-detail-smailtitle">
							<div className="content">
								<FormItem label="" colon={false} style={{ width: '100%' }}>
									{getFieldDecorator('remark', {
										rules: [{ required: false, message: '' }]
									})(<Input.TextArea
										className="font-striking-content"
										style={{ minWidth: '900px', minHeight: '6em' }}
										maxLength={300}
										placeholder="输入备注"
									/>)}
								</FormItem>
							</div>
						</Descriptions>
					</div>
					<div className="blank-line"></div>
					<div className="ask-info">
						<div className="record-list-title">折扣信息</div>
						<Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none" }}>
							<dItem label="客户名称">
								<div>{client.name}</div>
								{client.id && <div>ID: {client.id}</div>}
							</dItem>
							<dItem label="项目">{projectName}</dItem>
							<dItem label="产品">{productName}</dItem>
							<dItem label="一级行业">{industryName}</dItem>
							<dItem label="二级行业">{industryName2}</dItem>
							<dItem label="折扣">{colrebate}</dItem>
							<dItem label="供应商">
								<div>{supplierName}</div>
								{supplierId && <div>ID: {supplierId}</div>}
							</dItem>
						</Descriptions>
					</div>
					<div className="blank-line"></div>
					<div className="ask-info submit-btn-box">
						<BtnGroup disabled={submitDis && getFieldValue('coltype') !== '7'} onCancel={() => {history.goBack()}} htmlType="submit" confirmName='提交' right="to-right" loading={saveLoading} />
					</div>
					<div className="btns"></div>
				</div>
			</Form>
			<Modal
				title="选择手机联系人"
				visible={contactnameVisible}
				onOk={onSelContactName}
				onCancel={() => setContactnameVisible(false)}
				destroyOnClose={true}
			>
				<div className="officework-modal-content">
					<Select
						placeholder="请选择手机联系人"
						labelInValue
						style={{ width: '100%' }}
						onSelect={val => {
							setSelContactPerson(val.label)
							setSelContactPersonId(val.key)
						}}
					>
						{
							contactPersonList.map(item => {
								return <Option key={item.id}>{item.name}</Option>
							})
						}
					</Select>
				</div>
			</Modal>
		</Spin>
	)
}

export default Form.create()(Apply)