/**
 * 模块名称: 消耗的记录 
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Button,
} from 'antd'

const { Column } = Table

let pageSize = 10
let currentPage = 1

const ConsumptionList = (props) => {
  const { setUseinfoVisible, setUseinfoLoading, adrId, orderPageType } = props
  const [vendorList, setVendorList] = useState([])
  const [count, setCount] = useState(0)

  useEffect(() => {
    pageSize = 10
    currentPage = 1
    getConsumptionList()
  }, [])


  const getConsumptionList = () => {
    setUseinfoLoading(true)
    api[`getConsumption${orderPageType}List`]({ adrId, limit: pageSize, page: currentPage }).then(res => {
      setUseinfoLoading(false)
      setVendorList(res.list)
      setCount(res.count)
    })
      .catch(() => setUseinfoLoading(false))
  }

  const onChangeTable = (pagination, filters, sorter) => {
    currentPage = pagination.current
    pageSize = pagination.pageSize
    getConsumptionList()
  }

  return (
    <>
      <div className="vendorstry">
        <Table
          dataSource={vendorList}
          rowKey="adl_id"
          className="table-page"
          // pagination={false}
          pagination={{
            pageSize,
            total: count,
            current: currentPage,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
          }}
          onChange={onChangeTable}
        >
          <Column title="记录ID" dataIndex="adl_id" />
          <Column title="导入消耗" dataIndex="adl_consumption" />
          <Column title="消耗时间" dataIndex="adl_xiaotime" />
          <Column title="导入时间" dataIndex="adl_addtime" />
        </Table>

        <div style={{ height: 60 }}></div>
        <div className="setVendorstryBtn">
          <Button type="primary" onClick={() => { setUseinfoVisible(false) }}>关闭</Button>
        </div>
      </div>
    </>
  )
}

export default ConsumptionList