/**
 * 模块名称: 信用收款
 * @author zuoshen@372163..com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import PayBackDetail from './module/PayBackDetail'

const Index = ({ match, history }) => {

  return (
    <Switch> 
      <Route path={match.path + '/Order/PayBackDetail'} exact component={PayBackDetail} /> 
      <Route component={PayBackDetail} />
    </Switch>
  )
}

export default Index