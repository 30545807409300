/**
 * 模块名称: 还款详情
 * @author lids@372163.com
 */
import React, { useState, useEffect } from 'react'
import api from '@/api'
import { parseSearch, debounce } from '@/utils'
import {
	Descriptions,
  Spin,
  Table,
  message,
  Icon,
  Button,
  Modal,
  Input,
  Steps,
  Tag,
  Tooltip,
  Drawer
} from 'antd'
import CryptoJS from 'crypto-js'
import ConsumptionList from './ConsumptionList'

const dItem = Descriptions.item
const { Column } = Table
const { Step } = Steps
const { CheckableTag } = Tag
let areaTxt = '', result = ''
let orderType = ''

const PaybackDetail = (props) => {

  const { history, location } = props
  
  const [single, setSingle] = useState(true)
  const [detail, setDetail] = useState({})
  const [row, setRow] = useState({})
  const [base, setBase] = useState({})//基础信息
  const [client, setClient] = useState('')//客户信息
	const [orderInfo, setOrderInfo] = useState({})//订单信息
	const [attachdata, setAttachdata] = useState([])//订单扩展资料字段信息
	const [orderExInfo, setOrderExInfo] = useState({})//订单资料
	const [colInfo, setColInfo] = useState({})//收款信息
	const [vendorInfo, setVendorInfo] = useState({})//厂商ID信息
	const [discountInfo, setDiscountInfo] = useState({})//折扣信息
  const [pageId, setPageId] = useState('')
  const [loading, setLoading] = useState(true)
	const [resPlaceholder, setResPlaceholder] = useState('请输入至少1个字符')
  const [modalVisible, setModalVisible] = useState(false)
	const [auditProgress, setAuditProgress] = useState([])//审核进程
	const [aduitLog, setAduitLog] = useState([])//审核日志
  const [currentId, setCurrenId] = useState(null)
  const [orderNumber, setOrderNumber] = useState([])
  const [original, setOriginal] = useState({}) // 原订单流水分成信息
	const [ogPerformance, setOgperformance] = useState([])//原订单分成人信息
	const [performance, setPerformance] = useState([])//分成人信息
  const [accDcorderInfo, setAccDcorderInfo] = useState({})//订单消耗信息
  const [useinfoVisible, setUseinfoVisible] = useState(false)
  const [useinfoLoading, setUseinfoLoading] = useState(false)
  const [adrId, setAdrId] = useState('')

  //操作权限
  const [auditAuth, setAuditAuth] = useState(false)//设置按钮组是否展示
  const [hasChargerPower, setHasChargerPower] = useState(false)//设置驳回和通过按钮是否展示
  const [replenish, setReplenish] = useState(false)//设置补充资料按钮是否展示
  const [returnUp, setReturnUp] = useState(false)//设置转上级按钮是否展示
  const [rejectMsgRequire, setRejectMsgRequire] = useState(false)//设置审批意见驳回必填
  const [showOrigina, setShowOrigina] = useState(0)
  const [reviewLoading, setReviewLoading] = useState(false) // 审批确定loading

	useEffect(() => {
    let plaintext = parseSearch(location.search).sign
    let plaintextTwo = CryptoJS.MD5(parseSearch(location.search).id + 'ad629fddf8b8756d2e72e96ae035a5a4' + parseSearch(location.search).time).toString()

    if (plaintext == plaintextTwo && +new Date() - +parseSearch(location.search).time <= 21600000) {
      //判断是直销还是代理商页面跳转
      if (location.pathname && location.pathname.indexOf('/orderAgent') > -1) {
        orderType = 1
      } else {
        orderType = 0
      }
      if(location.search){
        // console.log(parseSearch(location.search))
        if(!parseSearch(location.search).id){
          history.goBack()
          return
        }
        setPageId(parseSearch(location.search).id)
        getFortuneOrderDetail()
      }else{
        history.goBack()
      }
    } else {
      message.error('没有本页访问权限')
      let timer = setTimeout(() => {
        history.go(-2)
        clearTimeout(timer)
      }, 1000)
    }
	}, [])
  //获取详情
  const getFortuneOrderDetail = () => {
    api[`getFortuneOrder${orderType == 0 ? 'Direct': 'Agent'}Detail`]({id: parseSearch(location.search).id, type: orderType}).then(res => {
      if (parseSearch(location.search).msgId) {
				api.setMessageReaded({ ids: [parseSearch(location.search).msgId] })
      }
      if(JSON.stringify(res) !== "[]"){
        res.vendor && setVendorInfo(res.vendor)
        if(res.buttonAuthList && JSON.stringify(res.buttonAuthList) !== '[]'){
          if(res.auditProgress){
            let auditProgress = res.auditProgress
            auditProgress.forEach((item, index, self) => {
              if(item.persons && item.persons.length){
                self[index].persons = item.persons.join('，')
              }
            })
            setAuditProgress(auditProgress)
          }
          res.aduitLog && setAduitLog(res.aduitLog)
          setAuditAuth(true)
          setHasChargerPower(res.buttonAuthList.hasChargerPower)
          setReplenish(res.buttonAuthList.replenish)
					setReturnUp(res.buttonAuthList.return_up)
					setRejectMsgRequire(res.buttonAuthList.reject_msg_require)
        }else{
          setAuditAuth(false)
          setHasChargerPower(false)
          setReplenish(false)
					setReturnUp(false)
					setRejectMsgRequire(false)
        }
        if(res.row){
          setDetail(res.row)
          setRow(res.row)
          const {id , batchIds} = res.row
          let batchIdsVal = ''
          if (batchIds == '') {
            batchIdsVal = id + ''
          } else {
            batchIdsVal = batchIds
          }
          if (batchIdsVal) {
            let ids = batchIdsVal && batchIdsVal.split(',')
            if(ids.length > 1){
              setSingle(false)
              if(res.batchOrders && res.batchOrders.list){
                const list = res.batchOrders.list
                let on = list.map(item => ({...item, checked: false, taped: false}))
                on[0].checked = true
                on[0].taped = true
                setOrderNumber(on)//默认请求第0个
                setCurrenId(on[0].id)
                getSingleDetail(on[0].id)
              }
            }else{
              setSingle(true)
              let data = Object.assign({}, res)
              dealItemData(data)
            }
          }
        }
      }
      setLoading(false)
    }).catch(() => setLoading(false))
  }
  //获取单个订单详情
  const getSingleDetail = (id) => {
    setLoading(true)
    api[`getFortuneOrder${orderType == 0 ? 'Direct': 'Agent'}Detail`]({id: id, type: orderType}).then(res => {
      if(JSON.stringify(res) !== "[]"){
        dealItemData(Object.assign({}, res))
      }else{
        setBase({})
        setOrderInfo({})
        setClient({})
        setAttachdata([])
        setOrderExInfo({})
        setColInfo({})
        setPerformance({})
        setOriginal({})
        setOgperformance({})
        setVendorInfo({})
        setDiscountInfo({})
        setAccDcorderInfo({})
      }
			setLoading(false)
    }).catch(() => setLoading(false))
  }
  //处理单个订单数据
  const dealItemData = data => {
    data.base && setBase(data.base)
    data.orderInfo && setOrderInfo(data.orderInfo)
    data.client && setClient(data.client)
    if(data.orderExInfo){
      setOrderExInfo(data.orderExInfo)
      if(data.orderExInfo.attachdata && data.orderExInfo.attachdata.length){
        data.orderExInfo.attachdata.forEach((item, index, self) => {
          self[index] = {
            name: Object.keys(item)[0],
            value: Object.values(item)[0]
          }
        })
        setAttachdata(data.orderExInfo.attachdata)
      }
    }
    setShowOrigina(data.showOrigina)
    data.colInfo && setColInfo(data.colInfo)
    data.performance && setPerformance(data.performance)
    data.originaData && setOriginal(data.originaData)
    data.originaData && setOgperformance(data.originaData.performance)
    data.discountInfo && setDiscountInfo(data.discountInfo)
    data.accDcorderInfo && setAccDcorderInfo(data.accDcorderInfo)
  }
  const getCheckedId = (data) => {
    if(data.id == currentId)return//点击为当前订单，return
    setCurrenId(data.id)
    let d = [...orderNumber]
    for(let i = 0; i<d.length; i++){
      d[i].checked = false
      if(d[i].id == data.id){
        d[i].checked = true
        d[i].taped = true
      }
    }
    setOrderNumber(d)
    getSingleDetail(data.id)
  }
  //点击4个审批的按钮
	const onAudit = (no) => {
		areaTxt = ''
		result = no
		if (no == 0) {//eslint-disable-line
			setResPlaceholder('请输入审批意见')
			setModalVisible(true)
		} else if (no == 1) {//eslint-disable-line
			//审批意见是否为必填项改由审批流控制 2020年2月22日 17:19:14
		  setResPlaceholder('请输入审批意见')
			setModalVisible(true)
		} else {
			api.onFortuneOrderFlowAduit({
				id: pageId,
				type: result,
				remark: areaTxt
			}).then(() => {
				//todo 转上级或者补充资料 返回列表页
				let timer = setTimeout(() => {
					getFortuneOrderDetail()
					clearTimeout(timer)
				}, 1000)
			})
		}
	}
	const oprateHandleOk = () => {
		if (areaTxt.length < 1 && result == 1 && rejectMsgRequire) {//eslint-disable-line
			message.warning('请输入至少1个字符')
			return
		}
		let params = {
			id: pageId,
			type: result,
			remark: areaTxt
    }
    if(result == 0){
      if(orderNumber.some(item => !item.taped))return message.warning('请将所有带红色图标的订单依次浏览后，才能点击通过按钮！')
    }
    setReviewLoading(true)
		api.onFortuneOrderFlowAduit(params).then(res => {
			message.success('审批成功')
      setModalVisible(false)
      setReviewLoading(false)
      let timer = setTimeout(() => {
        getFortuneOrderDetail()
        clearTimeout(timer)
      }, 1000)
		}).catch(() => {
      setReviewLoading(false)
    })
	}
	//取消弹窗
	const oprateHandleCancel = () => {
		setModalVisible(false)
	}
	//同意的样式
	const agreeNode = () => {
		return result === 0 ? <>
			同意 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
		</> : <>
				不同意 <Icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96" />
			</>
	}
	//审批意见
	const getAreaTxt = (e) => {
		areaTxt = e.target.value.trim()
	}

  //退回列表页
  const onGoBack = () => {
    if (parseSearch(location.search).type == 'qu') {
      history.push({pathname:'/orderAgent/auditList',state: { goBack: 1}})
    }
    if (parseSearch(location.search).type == 'zhi') {
      history.push({pathname:'/orderDirect/auditList',state: { goBack: 1}})
    }
  }

  const onConsumptionList = () => {
    setUseinfoVisible(true)
    setAdrId(accDcorderInfo.adr_id)
  }

	return (
		<Spin spinning={loading}>
      {
        parseSearch(location.search).type && <Button onClick={onGoBack} type="primary" style={{
          position: 'absolute',
          right: '20px',
          top: '0px'
        }}>返回上一页</Button>
      }
			<div className="add-orderDirect-detail">
        <Descriptions title={`还款详情-${single ? '信用收款' : '批量信用收款'}`} layout="vertical" className="pro-detail-title" column={5} style={{borderBottom: "none"}}>
          <dItem label="客户名称">{client.name}</dItem> 
          <dItem label="OAID">{client.id}</dItem>
          {base.orderType && !Array.isArray(base.orderType) && <dItem label="类型">{base.orderType.name}</dItem>}
          <dItem label="厂商ID">{vendorInfo.vendor}</dItem>
          {orderType == 1 && <dItem label="代理商">{row.agent}</dItem>}
          {orderType == 1 && <dItem label="QID">{vendorInfo.agentId}</dItem>}
          {orderType == 0 && <dItem label="所属销售">{vendorInfo.relSalerName}</dItem>}
          {orderType == 0 && <dItem label="所属客服">{vendorInfo.relServicerName}</dItem>}
          {orderType == 1 && <dItem label="所属渠道开发">{vendorInfo.relAgentStaffName}</dItem>}
          {single && <dItem label="订单编号">{base.number}</dItem>}
        </Descriptions>
        <div className="blank-line"></div>
				<div className="step-wrap">
          <div className="record-list-title">当前审批进度</div>
          <Steps current={detail.current}>
          {
            auditProgress.map((item, index, self) => {
							let status = 'wait'
              if (item.time && item.time.length > 1) {
                status = 'process'
              }
              // if (item.nodeStatus == 2) {
              //   status = 'finish'
              // }
              return <Step status={status} title={item.nodeName} key={index} description={
                <>
                  {item.persons && <div title={item.persons}>{item.persons}</div>}
                  <div title={item.statusName}>{item.statusName}</div>
                  <div title={item.time}>{item.time}</div>
                </>
              } />
            })
          }
          </Steps>					
				</div>
        {single === false && <>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">还款订单</div>
          <Descriptions layout="horizontal" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
            {
              orderNumber.map(item => 
              <dItem label="订单编号" key={item.id}><CheckableTag color="blue" checked={item.checked} onChange={() => getCheckedId(item)}>
                {!item.taped ? <Tooltip title="该订单还未查看"><Icon type="exclamation-circle" style={{color: '#ff4d4f', fontSize: 14}} fill="currentColor" /> {item.number}</Tooltip> : item.number}</CheckableTag>
              </dItem>)
            }
          </Descriptions>
        </div>
        </>}
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单信息</div>
					{orderInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="项目">{orderInfo.projectName}</dItem>
						<dItem label="产品">{orderInfo.productName}</dItem>
						<dItem label="业务类型">{orderInfo.businessName}</dItem>
						<dItem label="业绩类型">{orderInfo.figtype}</dItem>
						{/* {orderType == 1 && <dItem label="是否撤资重开订单">{orderInfo.isodfdivest}</dItem>} */}
						{orderType == 0 && <dItem label="是否退转订单">{+row.isodftrun ? '是' : '否'}</dItem>}
						{orderType == 0 && <dItem label="是否框架订单">{row.isodfkjia ? '是' : '否'}</dItem>}
						{orderType == 0 && <dItem label="销售主项">{row.majorminor == 1 ? '是' : '否'}</dItem>}
					</Descriptions> : '-'}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单收款信息-{base.coltype}</div>
					{colInfo ? <><Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="市场价">{colInfo.colmarketprice}</dItem>
						<dItem label="实收">{colInfo.colactualprice}</dItem>
            {(row.coltype == 2 || row.coltype == 5) && <dItem label='预计收款'>{colInfo.colpredictprice}</dItem>}
						<dItem label="折扣">{+colInfo.colmarketprice === 0 ? '纯利': colInfo.colrebate}</dItem>
            {orderType == 1 && <dItem label="积分" span={3}>{colInfo.integral}</dItem>}
          </Descriptions>
          <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="成本">{colInfo.colcost}</dItem>
						<dItem label="调整项">{colInfo.colrefund}</dItem>
						<dItem label="个人利润">{colInfo.colprofit}</dItem>
          </Descriptions>
          <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="提单人">{colInfo.userName}</dItem>
						<dItem label="直接主管">{colInfo.userLeader}</dItem>
						<dItem label="部门">{colInfo.userDepart}</dItem>
            <dItem label="资金所属公司">{colInfo.fundCompanyName}</dItem>
					</Descriptions>
          {performance.length > 0 && <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none", marginBottom: "-30px"}}>
                <dItem label="分成人"></dItem>
                <dItem label="分成人部门"></dItem>
                <dItem label="分成的订单业绩"></dItem>
                <dItem label="已生成的消耗业绩"></dItem>
            </Descriptions>}
            {performance.map((item, index) => (<Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{borderBottom: "none", marginBottom: "-10px"}}>
              <dItem label="">{item.stfName}</dItem>
              <dItem label="">{item.department}</dItem>
              <dItem label="">{item.performance}</dItem>
              <dItem label="">{item.conPered}</dItem>
            </Descriptions>))}
          </> : '-'}
        </div>
        {
          showOrigina === 1 && <>
            <div className="blank-line"></div>
            <div className="ask-info">
              <div className="record-list-title">原订单流水与分成信息</div>
              {original ? <><Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none" }}>
                <dItem label="市场价">{original.colmarketprice}</dItem>
                <dItem label="实收">{original.colactualprice}</dItem>
                {(row.coltype == 2 || row.coltype == 5) && <dItem label='预计收款'>{original.colpredictprice}</dItem>}
                <dItem label="折扣">{+original.colmarketprice === 0 ? '纯利' : original.colrebate}</dItem>
                {orderType == 1 && <dItem label="积分" span={3}>{original.integral}</dItem>}
              </Descriptions>
                <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none" }}>
                  <dItem label="成本">{original.colcost}</dItem>
                  <dItem label="调整项">{original.colrefund}</dItem>
                  <dItem label="个人利润">{original.colprofit}</dItem>
                </Descriptions>
                <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none" }}>
                  <dItem label="提单人">{original.userName}</dItem>
                  <dItem label="直接主管">{original.userLeaderName}</dItem>
                  <dItem label="部门">{original.userDeptName}</dItem>
                  <dItem label="资金所属公司">{original.fundCompanyName}</dItem>
                </Descriptions></> : '-'}

              {ogPerformance && ogPerformance.length > 0 && <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none", marginBottom: "-30px" }}>
                <dItem label="分成人"></dItem>
                <dItem label="分成人部门"></dItem>
                <dItem label="分成的订单业绩"></dItem>
                <dItem label="已生成的消耗业绩"></dItem>
              </Descriptions>}
              {ogPerformance && ogPerformance.map((item, index) => (<Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{ borderBottom: "none", marginBottom: "-10px" }}>
                <dItem label="">{item.stfName}</dItem>
                <dItem label="">{item.department}</dItem>
                <dItem label="">{item.performance}</dItem>
                <dItem label="">{item.conPered}</dItem>
              </Descriptions>))}
            </div></>
        }
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单资料</div>
					{orderExInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="公司名称">{orderExInfo.companyName}</dItem>
						<dItem label="一级行业">{orderExInfo.industry1Name}</dItem>
						<dItem label="二级行业">{orderExInfo.industry2Name}</dItem>
						<dItem label="供应商">{orderExInfo.supplierName}</dItem>
            {
              attachdata.map(((item, index) => <dItem label={item.name !== '备注' ? item.name : <span className='font-striking'>备注</span>} key={+index+4}>{item.name !== '备注' ? item.value : <span className='font-striking-content'>{item.value}</span>}</dItem>))
            }
					</Descriptions> : '-'}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
					<Descriptions title={<span className="font-striking">备注</span>} layout="horizontal" className="pro-detail-smailtitle">
            <div className="content">
            {(orderInfo.remark && orderInfo.remark.length) ? <span className="font-striking-content">{orderInfo.remark}</span> : '-'}
            </div>
					</Descriptions> 
        </div>
       
        {/* <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">还款</div>
            {detail.batchData && <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
              <dItem className="radio-in-label" label="实收 ">
                {JSON.parse(detail.batchData).colactualprice}
              </dItem>
              <dItem className="radio-in-label" label="还款市场价 ">
                {JSON.parse(detail.batchData).payback_money}
              </dItem>
              <dItem className="radio-in-label" label="折扣 ">
                {JSON.parse(detail.batchData).discount}
              </dItem>
            </Descriptions>}
        </div> */}
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">折扣信息</div>
					{discountInfo ? <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
						<dItem label="客户名称">
              <div>{discountInfo.clientName}</div>
              <div>ID: {client.id}</div>
            </dItem>
            <dItem label="项目">{discountInfo.projectName}</dItem>
            <dItem label="产品">{discountInfo.productName}</dItem>
            <dItem label="一级行业">{discountInfo.industry1}</dItem>
            <dItem label="二级行业">{discountInfo.industry2}</dItem>
            <dItem label="折扣">{colInfo && +colInfo.colmarketprice === 0 ? '纯利' : discountInfo.colrebate}</dItem>
            <dItem label="供应商">
              <div>{discountInfo.supplier}</div>
              <div>ID: {orderExInfo.supplier}</div>
            </dItem>
            <dItem label="折扣类型">{discountInfo.discountType}</dItem>
					</Descriptions> : '-'}
        </div>
        <div className="blank-line"></div>
        <div className="ask-info">
          <div className="record-list-title">订单消耗信息</div>
					 <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none"}}>
            <dItem label="可用消耗">{accDcorderInfo.available}</dItem>
            <dItem label="可用余额">{accDcorderInfo.balance}</dItem>
            <dItem label="已用消耗"><a onClick={onConsumptionList}>{accDcorderInfo.usedConsumption}</a></dItem>
            <dItem label="已用余额">{accDcorderInfo.usedBalance}</dItem>
					</Descriptions>
        </div>
        <div className="blank-line"></div>
				<div className="record-list">
          <div className="record-list-title">审批记录</div>
          {aduitLog.length > 0 && <Descriptions layout="vertical" className="pro-detail-title" column={4} style={{borderBottom: "none", marginBottom: "-30px"}}>
						<dItem label="姓名"></dItem>
						<dItem label="时间"></dItem>
						<dItem label="状态"></dItem>
						<dItem label="审批意见"></dItem>
					</Descriptions>}
					{
						aduitLog.length > 0 ? aduitLog.map((item, index) => {
							return <Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{borderBottom: "none", marginBottom: "-10px"}}>
								<dItem label="">{item.userInfo.staffName}</dItem>
								<dItem label="">{item.addTime}</dItem>
								<dItem label="">{item.status}</dItem>
								<dItem label="">{item.chargeMsg}</dItem>
							</Descriptions>
						}) : '-'
					}
				</div>
        <div className="blank-line"></div>
        {
          auditAuth && <div className="btns">
            {replenish && <Button onClick={debounce(() => {onAudit(3)}, 3000)} className="orange-btn">补充资料</Button>}
            {returnUp && <Button onClick={debounce(() => {onAudit(2)}, 3000)} className="orange-btn">转上级</Button>}
            {hasChargerPower && <Button onClick={() => onAudit(1)}>驳回</Button>}
            {hasChargerPower && <Button onClick={() => onAudit(0)} type="primary">通过</Button>}
          </div>
        }
        <Modal
          title={agreeNode()}
          visible={modalVisible}
          onOk={debounce(() => {oprateHandleOk()}, 3000)}
          onCancel={oprateHandleCancel}
          destroyOnClose={true}
          footer={[
            <Button key="back" onClick={oprateHandleCancel}>取消</Button>,
            <Button key="submit" type="primary" loading={reviewLoading} onClick={debounce(() => {oprateHandleOk()}, 3000)}>确定</Button>
          ]}
        >
          <div className="officework-modal-content">
            <span className="label">意见：</span><Input.TextArea className="content-area" placeholder={resPlaceholder} onChange={getAreaTxt} rows={4} style={{ width: "100%" }} maxLength={100} />
          </div>
        </Modal>
        <Drawer
          title={`已用消耗: ${base.number}`}
          width={880}
          onClose={() => setUseinfoVisible(false)}
          visible={useinfoVisible}
          destroyOnClose={true}
        >
          <Spin spinning={useinfoLoading}>
            <ConsumptionList
              setUseinfoVisible={setUseinfoVisible}
              setUseinfoLoading={setUseinfoLoading}
              orderPageType={orderType == 0 ? 'Direct': 'Agent'}
              adrId={adrId}
            />
          </Spin>
        </Drawer>
			</div>
		</Spin>
	)
}

export default PaybackDetail