/**
 * 模块名称: 已完成订单 => 续费
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import {
  Table,
  Button,
  message,
} from 'antd'

const { Column } = Table

let filter = {
  name: '',
}

const RenewalList = (props) => {
  const { history, setVisibleRenewal, setRenewalLoading, type } = props
  const [vendorList, setVendorList] = useState([])
  const [relationId, setRelationId] = useState('')
  const [vendorId, setVendorId] = useState('')
  const [qId, setQid] = useState('')
  const [msgVal, setMsgVal] = useState('')

  useEffect(() => {
    filter = {
      name: '',
    }
    // getVendorXudan()
  }, [])


  const getVendorXudan = () => {
    setRenewalLoading(true)
    api.getVendorXudan({ vendor: filter.name, type })
      .then(data => {
        const {clientId,id,agentId,msg} = data.list[0]
        setRenewalLoading(false)
        setVendorList(data.list)
        setRelationId(clientId)
        setVendorId(id)
        setQid(agentId)
        setMsgVal(msg)
      })
      .catch(() => setRenewalLoading(false))
  }

  const departInp = useRef(null)
  const onSearch = () => {
    filter.name = departInp.current.value
    if (departInp.current.value == '') {
      message.info("请输入厂商ID")
      return
    }
    getVendorXudan()
  }

  const onKeyup = (e) => {
    if (e.keyCode === 13) {
      onSearch()
    }
  }

  const onChange = () => {
    setVendorList([])
    setRelationId('')
    setVendorId('')
    setQid('')
  }

  const onRenewaNextl = () => {
    if (msgVal !== '') {
      return message.error(msgVal)
    } else {
      api.onCheckVendorRelServicer({
        vendorId: vendorId
      }).then(() => {
        if (type == 1) {
          history.push('/orderAgent/renew', {
            relationId,
            vendorId,
            qId
          })
        } else {
          history.push('/orderDirect/renew', {
            relationId,
            vendorId
          })
        }
      })
    }
  }

  return (
    <>
      <div className="vendorstry">
        <div className="vendorwrap">
          <div className="vendor-item">
            <div style={{ width: 80 }}>厂商ID: </div>
            <input className="ant-input" ref={departInp} type="text" placeholder="请输入厂商ID" onChange={onChange} onKeyUp={onKeyup} />
          </div>
          <Button onClick={onSearch} type="primary">查询</Button>
        </div>
        <Table
          dataSource={vendorList}
          rowKey="id"
          className="table-page"
          pagination={false}
        >
          <Column title="项目" dataIndex="projectName" />
          <Column title="产品" dataIndex="productName" />
          <Column title="厂商ID" dataIndex="vendor" />
          <Column title="客户名称" dataIndex="client" />
          <Column title="OAID" dataIndex="clientId" />
          <Column title="关联代理商" dataIndex="agentName" />
          <Column title="代理商ID" dataIndex="agentId" render={(text, record) => <div>{text == 0 ? '' : text}</div>} />
          <Column title="所属销售" dataIndex="relSalerName" />
          <Column title="所属客服" dataIndex="relServicerName" />
          <Column title="代理商负责人" dataIndex="relAgentStaffName" />
        </Table>

        <div style={{ height: 60 }}></div>
        <div className="setVendorstryBtn">
          <Button onClick={() => { setVisibleRenewal(false) }} style={{ marginRight: 10 }}>取消</Button>
          <Button type="primary" disabled={vendorList && vendorList.length !== 1} onClick={onRenewaNextl}>下一步</Button>
        </div>
      </div>
    </>
  )
}

export default RenewalList